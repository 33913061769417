import { routes } from '../../router/router.tsx';
import { MomentRoute, buildMomentRoute, createPathType } from './types.ts';
import { FeedLayout } from '../layouts/FeedLayout.tsx';
import { LightboxRootObject } from '../net/softbox-types.ts';
import { fetchFeedQuery, generateFetchFeedQuery } from '../net/feed-fetch-query.ts';

export const FEED_ROUTE: MomentRoute<{ lang: string, hour: string }> = {
  path: createPathType<{lang: string, hour: string}>('feed/:lang/:hour'),
  title: 'Moment | Feed',
  description:
    "Moment",
  element: (<FeedLayout/>),
  prefetchQuery: generateFetchFeedQuery('dynamic-news'),
};

export const GAMES_ROUTE: MomentRoute<Record<string, string>> = {
  path: createPathType<Record<string, string>>('moment-games'),
  title: 'Moment | Games',
  description:
    "Moment",
  element: (<FeedLayout/>),
  prefetchQuery: generateFetchFeedQuery('html5games'),
};

export const GALLERIES_ROUTE: MomentRoute<Record<string, string>> = {
  path: createPathType<Record<string, string>>('moment-galleries'),
  title: 'Moment',
  description:
    "Moment",
  element: (<FeedLayout/>),
  prefetchQuery: generateFetchFeedQuery('standard-hero'),
};


export const momentRoutes = [
  buildMomentRoute(FEED_ROUTE),
  buildMomentRoute(GAMES_ROUTE),
  buildMomentRoute(GALLERIES_ROUTE),
  ...routes,
]
