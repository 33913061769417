import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { IInterest } from '../../services/api/interest/interfaces';
import interestAPIService from '../../services/api/interest';
import { InterestContext } from './InterestContext';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../../../server-side-logic/ServerDataContext.ts';

export const WithInterest: FC<PropsWithChildren> = ({ children }) => {
  const { commonData } = useContext<IServerDataContext>(ServerDataContext);

  const [interestMap, setInterestMap] = useState<Map<string, IInterest>>(
    commonData?.interests || new Map()
  );

  useEffect(() => {
    if (interestMap.size === 0) {
      interestAPIService
        .getInterestList()
        .then((data) => {
          const map = new Map<string, IInterest>();

          data.interests.forEach((item) => {
            map.set(item.uid, item);
          });

          setInterestMap(map);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [interestMap]);

  return (
    <InterestContext.Provider value={{ interestMap, setInterestMap }}>
      {children}
    </InterestContext.Provider>
  );
};
