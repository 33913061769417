import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FeatureConfigContext, IFeatureConfig } from './FeatureConfigContext';
import { UTM_SOURCE_QUERY } from '../../utils/constants';
import FeatureFlagsConfig from '../../../../FeatureFlagsConfig.json';

export const WithFeatureConfig: FC<PropsWithChildren> = ({ children }) => {
  const [search] = useSearchParams();
  const [config] = useState<IFeatureConfig>(
    FeatureFlagsConfig as IFeatureConfig
  );

  const [utmSource, setUtmSource] = useState<string>('');

  useEffect(() => {
    const queryUTMSource = search.get(UTM_SOURCE_QUERY);
    if (queryUTMSource) {
      setUtmSource(queryUTMSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeatureConfigContext.Provider value={{ config, utmSource }}>
      {children}
    </FeatureConfigContext.Provider>
  );
};
