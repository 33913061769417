import { API } from '../api';
import { CONFIG_CKEY } from '../constants';
import { IAdTagResponce } from './interfaces';
import { getDNSMI } from '../../../../../utils/gtm.ts';

export class AdsAPIService {
  constructor(private api: API) {}

  getAds(
    id: string,
    options?: Record<string, string | null>
  ): Promise<IAdTagResponce> {
    const isAdsDestricted = getDNSMI();

    if (isAdsDestricted) {
      throw new Error('Ads are not available');
    }

    const qs = [
      `ckey=${CONFIG_CKEY}`,
      `param=utm_source`,
      `value=${options?.utmSource || 'no source'}`,
    ];

    const result = window.location?.host.match(/^(.+?)\.(.+?)\.(.+?)$/) || [];

    if (result[1]) {
      qs.push(`subdomain=${result[1]}`);
    }

    const url = `${`wp/v1/brandgroup/${id}/adtags`}?${qs.join('&')}`;

    return this.api.get(url);
  }
}
