import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { LOOKBOOK_ROUTE_PATH } from '../Root/router/constants.ts';
import { getWithOutSpecialChars } from '../Root/utils';
import { useResize } from '../Root/hooks';
import InterestCarousel from '../Root/components/InterestCarousel/InterestCarousel';
import SingleCard from '../Root/pages/HomeRoot/components/SingleCard/SingleCard';
import { getTranslation } from '../../i18n';
import NotFoundTemplate from '../../components/NotFoundTemplate';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../server-side-logic/ServerDataContext.ts';
import { LATEST_SCHEDULE } from '../Root/pages/HomeRoot/constants.ts';
import { IDailyLookBook } from '../Root/services/api/lookBook/interfaces.ts';
import lookBookApiService from '../Root/services/api/lookBook';

import './index.scss';

const NotFoundPage: FC = () => {
  const { isMobile } = useResize();
  const { notFoundData } = useContext<IServerDataContext>(ServerDataContext);
  const [latestListData, setLatestListData] = useState<IDailyLookBook[]>([]);

  const fetchLatestDailyFotoscapeLookBookList = () => {
    lookBookApiService
      .getDailyLookbookList(LATEST_SCHEDULE)
      .then((data) => {
        if (data.items.length) {
          setLatestListData(data.items);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const latestList = useMemo(() => {
    return notFoundData?.latestSchedule || latestListData || [];
  }, [notFoundData, latestListData]);

  useEffect(() => {
    if (!latestList.length) {
      fetchLatestDailyFotoscapeLookBookList();
    }
  }, [latestList]);

  return (
    <div className="not-found-page">
      <NotFoundTemplate />
      <div className="not-found-page__carousel-wrapper">
        <h3 className="not-found-page__carousel-title">
          Take Time to Explore...
        </h3>

        <div className="not-found-page__interest-content">
          <InterestCarousel
            isSmallView={true}
            isMobile={isMobile}
            itemList={latestList}
            renderItem={({ images, title, brandLogo, uid }) => {
              return (
                <SingleCard
                  classList="interest-carousel__cover-look"
                  title={getTranslation(title)}
                  imgList={images}
                  logoURL={brandLogo}
                  link={LOOKBOOK_ROUTE_PATH?.replace(':id', uid).replace(
                    ':title?',
                    getWithOutSpecialChars(title?.en)
                  )}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
