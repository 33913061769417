import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from '../../pages/Root/utils/constants';
import {
  ABOUT_US_ROUTE_PATH,
  CATEGORY_ROUTE_PATH,
  CONTACT_US_ROUTE_PATH,
  DO_NOT_SELL_ROUTE_PATH,
  GAMES_ROUTE_PATH,
  INTEREST_ROUTE_PATH,
  MANAGE_PRIVACY_ROUTE_PATH,
} from '../../pages/Root/router/constants.ts';
import { FACEBOOK_LINK } from '../../utils/constants';
import { IconTypes } from '../Icon/constants.ts';

export const SECTION_LINK_LIST_LEGAL = [
  { text: 'digitalTurbineTermsOfUse', link: TERMS_OF_SERVICE_LINK },
  {
    text: 'noticeAtCollectionAndPrivacyPolicy',
    link: PRIVACY_POLICY_LINK,
  },
  { text: 'managePrivacy', link: MANAGE_PRIVACY_ROUTE_PATH },
  {
    text: 'doNotSellMyPersonalInformation',
    link: DO_NOT_SELL_ROUTE_PATH,
  },
];

export const SECTION_LINK_LIST_ABOUT = [
  { text: 'aboutUs', link: ABOUT_US_ROUTE_PATH },
  { text: 'contactUs', link: CONTACT_US_ROUTE_PATH },
];

export const SECTION_LINK_LIST_RECOMENDATION = [
  { text: 'galleries', link: INTEREST_ROUTE_PATH },
  {
    text: 'sneakers',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', 'sneakers'),
  },
  {
    text: 'travelGuides',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', 'travel'),
  },
  {
    text: 'celebrityHomes',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', 'celebrity-homes'),
  },
  { text: 'games', link: GAMES_ROUTE_PATH },
];

export const SECTION_LINK_LIST_SOCIAL = [
  { icon: IconTypes.facebook, link: FACEBOOK_LINK, text: 'facebook' },
  {
    icon: IconTypes.pinterest,
    link: 'https://www.pinterest.com/fotoscapes/',
    text: 'pinterest',
  },
];

export const FOMR_SUCCESS_TEXT = 'SUCCESS';
