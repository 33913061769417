export const GIVE_ME_MY_DATA = {
  emailTo: 'privacyact.fotoscapes@mobileposse.com',
  subject: 'Give Me My Data',
  body: `You have the right to request that we disclose certain information about our collection and use of your personal information for a period up to 12 months. Please note that we only retain information from a user for a period of 90 days subject to certain exceptions that we state in our California Privacy Statement located at :origin/manage-privacy. You can submit your request by filling out the information below and hitting the "send" button on your email service. Once we receive and confirm your verifiable consumer request, we will disclose to your the categories of information that we collect as stated in our California Privacy Statement. Please provide us with the following: %0D%0A %0D%0A Your Name  %0D%0A %0D%0A Your email address so that we can contact you  %0D%0A %0D%0A Your state of residence:  %0D%0A %0D%0A By submitting this request, you confirm (1) your information is true and accurate, (2) you understand your information will be handled in accordance with our California Privacy Statement, and (3) Mobile Posse, Inc. has the right to contact you to verify my identity and to process this request.  %0D%0A %0D%0A %0D%0A ------------------------------------- Device ID: :deviceId`,
};

export const DELETE_MY_DATA = {
  emailTo: 'privacyact.fotoscapes@mobileposse.com',
  subject: 'Delete My Data',
  body: `You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions that we state in our California Privacy Statement located at :origin/manage-privacy. You can submit your request by filling out the information below and hitting the "send" button on your email service. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
%0D%0A %0D%0A
Please provide us with the following:
%0D%0A
%0D%0A - Your Name
%0D%0A - Your email address so that we can contact you
%0D%0A - Your state of residence:
%0D%0A %0D%0A
By submitting this request, you confirm (1) your information is true and accurate, (2) you understand your information will be handled in accordance with our California Privacy Statement, and (3) Mobile Posse, Inc. has the right to contact me to verify my identity and to process this request.

%0D%0A %0D%0A %0D%0A
-------------------------------------
Device ID: :deviceId
`,
};
