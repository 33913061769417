import { FC, useMemo } from 'react';

import { ICardPlaceholderProps, cardSize } from './interfaces';

import './index.scss';

const CardSkeleton: FC<ICardPlaceholderProps> = ({
  size = cardSize.medium,
}) => {
  const cardClassList = useMemo(() => {
    const list: string[] = ['card-skeleton'];

    if (size === cardSize.small) {
      list.push('card-skeleton--small');
    }

    return list.join(' ');
  }, [size]);

  const imgClassList = useMemo(() => {
    const list: string[] = ['card-skeleton__img'];

    if (size === cardSize.large) {
      list.push('card-skeleton__img--large');
    } else if (size === cardSize.small) {
      list.push('card-skeleton__img--small');
    }

    return list.join(' ');
  }, [size]);

  const titleClassList = useMemo(() => {
    const list: string[] = ['card-skeleton__text card-skeleton__text--large'];

    if (size !== cardSize.small) {
      list.push('card-skeleton__text--wide');
    }

    return list.join(' ');
  }, [size]);

  return (
    <div className={cardClassList}>
      <div className={imgClassList}></div>

      <div className="card-skeleton__content">
        {size !== cardSize.small ? (
          <div className="card-skeleton__text"></div>
        ) : null}

        <div className={titleClassList}></div>

        <div className="card-skeleton__text card-skeleton__text--wide"></div>
        <div className="card-skeleton__text card-skeleton__text--wide"></div>

        {size === cardSize.large ? (
          <div className="card-skeleton__text"></div>
        ) : null}
      </div>
    </div>
  );
};

export default CardSkeleton;
