import React from 'react';
import { Route, RouteObject } from 'react-router-dom';

export const getRouter = (router: RouteObject) => {
  return (
    <Route
      key={router.path}
      path={router.path}
      element={router.element}
      action={router.action}
      loader={router.loader}
      handle={router.handle}
    >
      {router.children?.map((item: RouteObject) => {
        return getRouter(item);
      })}
    </Route>
  );
};
