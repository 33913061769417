import { FC, useMemo, useState } from 'react';

import { IImageProps } from './interfaces';
import Lazy from '../../pages/Root/components/Lazy/Lazy.tsx';

import './index.scss';

const Image: FC<IImageProps> = ({
  src,
  alt,
  className,
  style,
  imageList,
  setRef,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [imageRef, setImageRef] = useState<HTMLImageElement>();

  const onLoad = () => {
    setIsLoaded(true);
  };

  const classList = useMemo(() => {
    const list = ['image'];

    if (isLoaded) {
      list.push('image--visible');
    }

    if (className) {
      list.push(className);
    }

    return list.join(' ');
  }, [isLoaded, className]);

  const source = useMemo(() => {
    if (src) {
      return src;
    }

    if (imageList?.length && imageRef) {
      const { width, height } = imageRef.getBoundingClientRect();

      const proportion = width / height;
      const imgAspectSortedList = [...imageList].sort((item, nextItem) => {
        return Math.abs(proportion - item.width / item.height) >
          Math.abs(proportion - nextItem.width / nextItem.height)
          ? 1
          : -1;
      });

      const img = imgAspectSortedList
        .filter((item) => {
          return item.aspect === imgAspectSortedList[0].aspect;
        })
        .sort((item, nextItem) => {
          return Math.abs(width - item.width) > Math.abs(width - nextItem.width)
            ? 1
            : -1;
        })[0];

      return img?.url || img?.link;
    }

    return '';
  }, [imageList, src, imageRef]);

  return (
    <Lazy className="image__container">
      <img
        data-testid="image"
        className={classList}
        style={style}
        src={source}
        alt={alt}
        ref={(refData) => {
          if (refData) {
            if (setRef) {
              setRef(refData);
            }
            setImageRef(refData);
          }
        }}
        onLoad={onLoad}
        {...props}
      />
    </Lazy>
  );
};

export default Image;
