import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from '../../../../../../../../components/Image/Image';
import feedbackLogo from '../../../../../../../../assets/images/feedback-logo.svg';
import Dialog from '../../../../../../components/Dialog';
import FeedbackForm from '../FeedbackForm';
import talkbackApiService from '../../../../../../services/api/talkback';

import './index.scss';

const Feedback: FC = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onSubmit = async ({
    email,
    feedback,
  }: {
    email: string;
    feedback: string;
  }) => {
    try {
      await talkbackApiService.updateTalkback({ email, feedback });
    } catch (e: any) {
      console.error(e);
    }
    closeModal();
  };

  return (
    <div className="feedback">
      <Image className="feedback__logo" alt="logo" src={feedbackLogo} />
      <div className="feedback__main">
        <div className="feedback__title headline--xxs">{t('haveIdeasOn')}</div>
        <div className="feedback__sub-title paragraph--xs">
          {t('sendThemOurWay')} {t('ourTeamIsDedicated')}
        </div>
        <button className="button button--small" onClick={showModal}>
          {t('sendUsAMessage')}
        </button>
      </div>

      <Dialog isOpen={isModalOpen} onClose={closeModal}>
        <div className="feedback__form-wrapper">
          <div className="feedback__header">
            <Image className="feedback__logo" alt="logo" src={feedbackLogo} />

            <div className="feedback__main">
              <div className="feedback__title headline--xxs">
                {t('letUsKnow')}
              </div>

              <div className="feedback__sub-title paragraph--xs">
                {t('yourOpinionMatters')} {t('ourTeamIsDedicated')}
              </div>
            </div>
          </div>

          <FeedbackForm onSubmit={onSubmit} />
        </div>
      </Dialog>
    </div>
  );
};

export default Feedback;
