export enum IconTypes {
  arrowRight = 'arrowRight',
  facebook = 'facebook',
  hamburger = 'hamburger',
  close = 'close',
  pinterest = 'pinterest',
  back = 'back',
  doubleArrowUp = 'doubleArrowUp',
  doubleArrowLeft = 'doubleArrowLeft',
  doubleArrowRight = 'doubleArrowRight',
  play = 'play',
  pause = 'pause',
}

export enum IconSize {
  xs = 'xs',
  s = 's',
  xm = 'xm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
}
