import { FC } from 'react';
import { createPortal } from 'react-dom';

import { IPortalProps } from './interfaces';

const Portal: FC<IPortalProps> = ({ children, idSelector }) => {
  const el = document.getElementById(idSelector);

  return el
    ? createPortal(
        <div data-testid="portal-el" className="portal-container">
          {children}
        </div>,
        el
      )
    : null;
};

export default Portal;
