import React, {
  CSSProperties,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  MOBILE_SMALL_SLIDE_WIDTH,
  SETTINGS,
  SLIDE_WIDTH,
  SMALL_SLIDE_WIDTH,
} from './constants';
import { IInterestCarouselProps } from './interfaces';
import CarouselArrow from '../CarouselArrow/CarouselArrow';
import Icon from '../../../../components/Icon/Icon.tsx';
import { IconSize, IconTypes } from '../../../../components/Icon/constants.ts';

import './index.scss';

const InterestCarousel: FC<IInterestCarouselProps> = ({
  isSmallView,
  isMobile,
  itemList,
  renderItem,
}) => {
  const sliderRef = useRef<Slider>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [slideIndex, setSlideIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [listWidth, setListWidth] = useState<number>();
  const [listOffset, setListOffset] = useState(0);
  const [isDeclineClicKEvent, setIsDeclineClickEvent] = useState(false);

  const slideWidth = useMemo(() => {
    return isSmallView ? SMALL_SLIDE_WIDTH : SLIDE_WIDTH;
  }, [isSmallView]);

  const classList = useMemo(() => {
    const list = ['interest-carousel'];

    if (isSmallView) {
      list.push('interest-carousel--small');
    }

    return list.join(' ');
  }, [isSmallView]);

  const slidesToShow = useMemo(() => {
    if (listWidth) {
      return listWidth / slideWidth;
    }

    return 4.5;
  }, [listWidth, slideWidth]);

  const slidesToScroll = useMemo(() => {
    return Math.floor(slidesToShow);
  }, [slidesToShow]);

  const styleList = useMemo(() => {
    return {
      '--slider-width': `${SLIDE_WIDTH}px`,
      '--slider-width-small': `${
        isMobile ? MOBILE_SMALL_SLIDE_WIDTH : SMALL_SLIDE_WIDTH
      }px`,
      '--list-offset': `${listOffset}px`,

      pointerEvents: 'revert',
    } as CSSProperties;
  }, [isMobile, listOffset]);

  const isAriaDisabledOffset = useMemo(() => {
    return slidesToScroll + 1;
  }, [slidesToScroll]);

  const handleCarouselPosition = (slideIndex: number) => {
    if (wrapperRef.current && containerRef.current) {
      const leftOffset = -containerRef.current.getBoundingClientRect().left;
      const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;
      const itemsInView = wrapperWidth / slideWidth;
      const diff = Math.ceil(itemsInView) - itemsInView;

      if (slideIndex > 0) {
        setListOffset(leftOffset);
      } else {
        setListOffset(0);
      }

      if (
        slideIndex + slidesToScroll >=
        (sliderRef.current?.innerSlider as any)?.state.slideCount - 1
      ) {
        setListOffset(leftOffset - slideWidth * diff);
      }
    }
  };

  const onBeforeChange = (
    currentSlideIndex: number,
    nextSlideIndex: number
  ) => {
    setSlideIndex(nextSlideIndex);

    if (!isMobile) {
      handleCarouselPosition(nextSlideIndex);
    }
  };

  const onInit = () => {
    setIsActive(true);
  };

  const onReInit = () => {
    if (wrapperRef.current) {
      setListWidth(wrapperRef.current.getBoundingClientRect().width);
    }
  };

  const onPrevClick = () => {
    const nextSlide = slideIndex - slidesToScroll;
    const index = nextSlide > 0 ? nextSlide : 0;
    sliderRef?.current?.slickGoTo(index);
  };

  const onNextClick = () => {
    const nextSlide = slideIndex + slidesToScroll;
    const slideCount = (sliderRef.current?.innerSlider as any)?.state
      .slideCount;
    const nextIndex =
      nextSlide + slidesToShow > slideCount
        ? Math.floor(slideCount - slidesToShow)
        : nextSlide;

    sliderRef?.current?.slickGoTo(nextIndex);
  };

  const handleMouseMove = useCallback(() => {
    setIsDeclineClickEvent(true);
  }, [setIsDeclineClickEvent]);

  const onMouseDown = () => {
    setIsDeclineClickEvent(false);

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('mousemove', handleMouseMove);
    }
  };

  const onMouseUp = () => {
    wrapperRef.current?.removeEventListener('mousemove', handleMouseMove);
  };

  const onClickItem = (e: MouseEvent<HTMLDivElement>) => {
    wrapperRef.current?.removeEventListener('mousemove', handleMouseMove);

    if (isDeclineClicKEvent) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const sliderState = (sliderRef.current?.innerSlider as any)?.state;

    if (isActive && sliderState) {
      for (
        let index = 0;
        index < slidesToScroll + slideIndex + slidesToShow + 1;
        index++
      ) {
        const pushIndex = Math.floor(index);
        sliderState.lazyLoadedList.push(pushIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderRef, sliderRef.current, slideIndex, isActive, slidesToScroll]);

  useEffect(() => {
    const refEl = wrapperRef;
    return () => {
      refEl.current?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [wrapperRef, handleMouseMove]);

  return (
    <div
      className="interest-carousel__wrapper"
      style={styleList}
      ref={wrapperRef}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <div className="interest-carousel__container" ref={containerRef}>
        <Slider
          swipeToSlide
          className={classList}
          {...SETTINGS}
          beforeChange={onBeforeChange}
          prevArrow={
            <CarouselArrow
              arrowIcon={
                <Icon type={IconTypes.doubleArrowLeft} size={IconSize.l} />
              }
              sliderRef={sliderRef}
              onArrowClick={onPrevClick}
            />
          }
          nextArrow={
            <CarouselArrow
              arrowIcon={
                <Icon type={IconTypes.doubleArrowRight} size={IconSize.l} />
              }
              isAriaDisabledOffset={isAriaDisabledOffset}
              sliderRef={sliderRef}
              onArrowClick={onNextClick}
            />
          }
          ref={sliderRef}
          onInit={onInit}
          onReInit={onReInit}
        >
          {itemList.map((item) => {
            return (
              <div
                style={{ pointerEvents: 'revert' }}
                key={item.uid}
                onClickCapture={onClickItem}
              >
                {renderItem(item)}
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default InterestCarousel;
