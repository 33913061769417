import { IImageData } from '../services/api/interfaces';
import { INTEREST_URL } from '../router/constants';
import { IInterest } from '../services/api/interest/interfaces';

export function hexToRgb(hexColor: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const findImage = (
  images: IImageData[],
  width: number,
  height: number
): IImageData => {
  let image = null;
  let smallest2 = Number.MAX_VALUE;
  const len = images.length;

  for (let i = 0; i < len; i++) {
    const imageData = images[i];
    if (imageData.width >= width && imageData.height >= height) {
      const size2 =
        imageData.width * imageData.width + imageData.height * imageData.height;
      if (size2 < smallest2) {
        image = imageData;
        smallest2 = size2;
      }
    }
  }
  if (!image) {
    image = images[0];
  }
  return image;
};

export const hexToHSL = (hexColor: string) => {
  // Convert hex to RGB first
  const rgbColor = hexToRgb(hexColor);

  if (rgbColor) {
    let { r, g, b } = rgbColor;
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin;

    let h = 0;
    let s = 0;
    let l = 0;

    if (delta === 0) {
      h = 0;
    } else if (cmax === r) {
      h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
      h += 360;
    }

    const medianaL = (cmax + cmin) / 2;
    const deltaS = delta === 0 ? 0 : delta / (1 - Math.abs(2 * medianaL - 1));
    s = +(deltaS * 100).toFixed(1);
    l = +(medianaL * 100).toFixed(1);

    return {
      h,
      s,
      l,
    };
  }
  return null;
};

export const formattedAverageColor = (averageColor: string) => {
  const hexColor = hexToHSL(averageColor);
  if (hexColor) {
    const { h, s } = hexColor;
    let { l } = hexColor;

    if (h < 210 && l > 24) {
      l = 24;
    } else if (h >= 210 && l > 41) {
      l = 41;
    }

    return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  }
  return averageColor;
};

export const extractRecircId = (link: string) => {
  const matches = link.match(/\/lookbook\/([^/]+)\/?/);

  if (!matches?.[1]) {
    throw new Error('extractRecircId: could not parse lookbook id');
  }

  return matches[1];
};

export const getWithOutSpecialChars = (text: string) => {
  return text
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(' ')
    .filter((item) => {
      return item.length;
    })
    .join('-')
    .toLowerCase();
};

export const getInterestUIDByURLName = (interestURLName?: string) => {
  if (!interestURLName) {
    return '';
  }
  return Object.keys(INTEREST_URL).find((key) => {
    return INTEREST_URL[key] === interestURLName;
  });
};

export const getInterestBackgroundImg = (interest: IInterest) => {
  if (interest?.previews?.length) {
    const img = interest?.previews[0].images.sort((first, second) => {
      return first.width > second.width ? 1 : -1;
    })[0];
    return img.url || '';
  }

  return '';
};

export const getBackgroundImageList = (interest: IInterest) => {
  if (interest?.previews?.length) {
    return interest?.previews[0].thumbnails;
  }

  return [];
};
