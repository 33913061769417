import { FC, useEffect } from 'react';

import {
  DEFAULT_VIDEO_CONFIG,
  IMA_OPTIONS,
  SOURCE_LIST,
  TRACK_DEFAULT_CONFIG,
  VIDEO_SELECTOR,
} from './constants';

import './index.scss';

const Video: FC = () => {
  useEffect(() => {
    let player: any = null;
    player = window.videojs(VIDEO_SELECTOR, DEFAULT_VIDEO_CONFIG);

    const trackIndex = Math.floor(Math.random() * SOURCE_LIST.length);

    player.src(SOURCE_LIST[trackIndex].videoScr);

    SOURCE_LIST[trackIndex].captions.forEach((trackData) => {
      player.addRemoteTextTrack(
        {
          ...TRACK_DEFAULT_CONFIG,
          label: trackData.lang,
          src: trackData.src,
        },
        false
      );
    });

    try {
      player.ima(IMA_OPTIONS);
    } catch (e) {
      console.warn(e);
    }

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, []);

  return (
    <div className="video">
      <video
        autoPlay
        muted
        controls
        id="video-js"
        width="600"
        height="300"
        preload="auto"
        className="video-js vjs-default-skin"
        data-setup="{}"
      ></video>
    </div>
  );
};

export default Video;
