import { signups } from './interface';

export const FORM_DATA_LIST = [
  {
    title: 'lifeHacks',
    value: signups.lifehacks,
  },
  {
    title: 'styleTips',
    value: signups.styletips,
  },
  {
    title: 'travelGuides',
    value: signups.travelGuides,
  },
];
