export const IS_HOME_PAGE_EXPERIENCE_QUERY = 'isHomePageExperience';
export const UTM_SOURCE_QUERY = 'utm_source';
export const COME_FROM_INTEREST_UID_QUERY = 'comeFromInterestQuery';
export const INTEREST_SECTION_UID_QUERY = 'interestSectionUidQuery';
export const MOBILE_BREAK_POINT = 684;
export const TABLED_BREAK_POINT = 1193;
export const PRIVACY_POLICY_LINK =
  'https://www.digitalturbine.com/notice-at-collection-and-privacy-policy-cm/';
export const TERMS_OF_SERVICE_LINK =
  'https://www.digitalturbine.com/terms-of-use/';
export const UTM_SOURCE_SKFOTOSCAPE = 'mp-ckfotoscapes';
