const getCookie = (cname: string) => {
  console.log('getCookie(' + cname + ')');
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const decodedCookieItemList = decodedCookie.split(';');

  for (let i = 0; i < decodedCookieItemList.length; i++) {
    let decodedCookieItem = decodedCookieItemList[i];
    while (decodedCookieItem.charAt(0) === ' ') {
      decodedCookieItem = decodedCookieItem.substring(1);
    }
    if (decodedCookieItem.indexOf(name) === 0) {
      console.log('getCookie: match: ' + decodedCookieItem);
      return decodedCookieItem.substring(name.length, decodedCookieItem.length);
    }
  }
  return '';
};

export const getCookieByName = (name: string) => {
  const cookie = getCookie(name);
  const value = cookie.split(',');
  return value[0];
};
