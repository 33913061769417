import { IPageData } from './interfaces';
import localstorage from '../pages/Root/services/storage/localstorage';
import { STORE_LAST_MONTH_USER_VISITS } from './constants';
import { ONE_DAY_TIME } from '../pages/Root/pages/Lookbook/contants';
import metrics from '../tracing/metrics.ts';

export const updatePageData = ({ title, description }: IPageData) => {
  if (title) {
    document.title = title;
  }

  if (description) {
    const descriptionEl = document.querySelector("meta[name='description']");
    descriptionEl?.setAttribute('content', description);
  }
};

export const updateUserVisitsData = () => {
  const visitList = localstorage?.get(STORE_LAST_MONTH_USER_VISITS) || [];
  const today = new Date();
  const todayString = today.toUTCString();
  const todayTime = today.getTime();
  const monthTime = ONE_DAY_TIME * 30;
  const oneMonthBeforeTime = new Date(todayTime - monthTime).getTime();

  const filterList = visitList.filter((date: string) => {
    const time = new Date(date).getTime();
    return time > oneMonthBeforeTime;
  });

  localstorage?.set(STORE_LAST_MONTH_USER_VISITS, [...filterList, todayString]);
};

export const collectWebVitalMetrics = () => {
  const start = window.pageLoadStart || new Date().getTime();
  window.addEventListener('load', () => {
    const diff = new Date().getTime() - start;
    const labels = {};
    metrics.histogram(`page_load_ms`, diff, labels);
  });

  window.addEventListener('DOMContentLoaded', () => {
    const diff = new Date().getTime() - start;
    const labels = {};
    metrics.histogram(`page_dom_loaded_ms`, diff, labels);
  });
};
