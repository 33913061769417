import lookBookApiService from '../pages/Root/services/api/lookBook';
import i18next from 'i18next';

export const fetchLookBook = async (uid: string) => {
  try {
    const lookbookData = await lookBookApiService.getLookBook(uid, {
      language: i18next.language,
    });

    return lookbookData.lookbook;
  } catch (e: any) {
    console.warn(e);
    return null;
  }
};
