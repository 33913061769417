import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FotoscapeLink from '../FotoscapeLink/FotoscapeLink';
import { useResize } from '../../pages/Root/hooks';
import { FIRST_SECTION_LINK_LIST, SECOND_SECTION_LINK_LIST } from './constants';
import { INavMenuProps } from './interfaces';
import Icon from '../Icon/Icon.tsx';
import { IconSize, IconTypes } from '../Icon/constants.ts';

import './index.scss';

const NavMenu: FC<INavMenuProps> = ({ isOpen, locationPathname, onClose }) => {
  const { isMobile } = useResize();
  const { t } = useTranslation();

  const classList = useMemo(() => {
    const list = ['nav-menu'];

    if (isOpen) {
      list.push('nav-menu--active');
    }

    return list.join(' ');
  }, [isOpen]);

  const onClick = () => {
    window.scrollTo({ top: 0 });
    onClose();
  };

  const getMainLinkClassList = () => {
    const list = ['nav-menu__list-link'];

    if (isMobile) {
      list.push('label--m');
    } else {
      list.push('label--l');
    }

    return list.join(' ');
  };

  const getLinkClassList = (link: string) => {
    const list = [getMainLinkClassList()];
    const linkRoot = link.split('/')[1];
    const locationPathnameRoot = locationPathname.split('/')[1];

    if (linkRoot === locationPathnameRoot && !link.startsWith('https')) {
      list.push('nav-menu__list-link--active');
    }

    return list.join(' ');
  };

  return (
    <div className={classList} tabIndex={1}>
      <div className="nav-menu__list-wrapper">
        <ul className="nav-menu__list">
          {FIRST_SECTION_LINK_LIST.map(({ text, link }) => {
            return (
              link && (
                <li
                  key={link}
                  className="nav-menu__list-item"
                  onClick={onClick}
                >
                  <FotoscapeLink className={getLinkClassList(link)} to={link}>
                    {t(text)}
                  </FotoscapeLink>
                </li>
              )
            );
          })}
        </ul>

        <ul className="nav-menu__list nav-menu__list--popular">
          <li className="nav-menu__list-item nav-menu__list-title headline--s">
            {t('popularTopics')}
          </li>

          {SECOND_SECTION_LINK_LIST.map(({ text, link }) => {
            return (
              link && (
                <li
                  key={link}
                  className="nav-menu__list-item"
                  onClick={onClick}
                >
                  <FotoscapeLink className={getMainLinkClassList()} to={link}>
                    {t(text)}
                  </FotoscapeLink>
                </li>
              )
            );
          })}
        </ul>
      </div>

      <button className="nav-menu__close-button label--m" onClick={onClose}>
        {t('close')}
        <Icon type={IconTypes.close} size={IconSize.xs} />
      </button>
    </div>
  );
};

export default NavMenu;
