import { IImageData } from '../../pages/Root/services/api/interfaces.ts';

export const getBestImage = (images: IImageData[], sizeHint: "large" | "small"): string => {
  let output = ""

  if (sizeHint === "large") {
    // **Educated Guess for Large Image** → Full-width (360-480px) & ~half viewport height (~400px)
    const preferredLarge = images.find(img => img.width >= 360 && img.width <= 480 && img.height >= 400);
    if (preferredLarge) {
      output = preferredLarge.url || preferredLarge.link || "";
    }
  } else if (sizeHint === "small") {
    // **Educated Guess for Small Image** → ~90x90px
    const preferredSmall = images.find(img => img.width >= 80 && img.width <= 100 && img.height >= 80 && img.height <= 100);

    if (preferredSmall) {
      output = preferredSmall.url || preferredSmall.link || "";
    }
  }

  // **Fallback** → Return the smallest available image to optimize bandwidth
  if (output === "") {
    const optimal = output = images.sort((a, b) => a.width - b.width)[0]
    if (optimal) {
      output = optimal.url || optimal.link || "";
    }
  }

  return output;
};
