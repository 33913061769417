import { IAdMapData } from '../../../../interfaces';

export const STORY_DESKTOP_AD_MAP_DATA: IAdMapData = {
  screenSize: [1193, 0],
  adSizeList: [[300, 250]],
};

export const STORY_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [970, 0],
  adSizeList: [
    [728, 90],
    [300, 50],
    [320, 50],
  ],
};

export const STORY_TABLED_SMALL_AD_MAP_DATA: IAdMapData = {
  screenSize: [684, 0],
  adSizeList: [
    [300, 50],
    [320, 50],
  ],
};

export const STORY_MOBILE_AD_MAP_DATA: IAdMapData = {
  screenSize: [320, 0],
  adSizeList: [
    [300, 250],
    [300, 50],
    [320, 50],
  ],
};

export const STORY_AD_SLOT = 'story';
