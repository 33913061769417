import {
  CATEGORY_ROUTE_PATH,
  INTEREST_URL,
} from '../../pages/Root/router/constants';

export const SUB_NAV_LINK_LIST = [
  {
    text: 'lifestyle',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', INTEREST_URL.g6fYliB),
  },
  {
    text: 'culture',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', INTEREST_URL.ZofNtA),
  },
  {
    text: 'travel',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', INTEREST_URL.NZf7cr),
  },
  {
    text: 'entertainment',
    link: CATEGORY_ROUTE_PATH.replace(
      ':interestTitle',
      INTEREST_URL['6JfV7FW']
    ),
  },
  {
    text: 'latest',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', 'latest'),
  },
];
