import React, { FC, useMemo } from 'react';

import { ICarouselItemProps } from './interfaces';

import './index.scss';

const CarouselItem: FC<ICarouselItemProps> = ({
  children,
  classList,
  isActive,
  onClick,
}) => {
  const itemClassList = useMemo(() => {
    const list = ['carousel-item'];

    if (isActive) {
      list.push('carousel-item--active');
    }

    if (classList) {
      list.push(classList);
    }

    return list.join(' ');
  }, [classList, isActive]);

  const onItemClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={itemClassList} onClick={onItemClick}>
      {children}
    </div>
  );
};

export default CarouselItem;
