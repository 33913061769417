import i18next from 'i18next';

import { ILook, ILookBook } from '../../services/api/lookBook/interfaces';
import { getWithOutSpecialChars } from '../../utils';
import { PRIMARY_LANG_LIST, SUPPORTED_LANGS } from '../../../../i18n';
import { LARGE_AD_TEXT_LENGTH } from './contants';
import { AD_SIZE } from '../../utils/ads';

export const getCoverLook = (lookbook: ILookBook) => {
  return (
    lookbook.looks.find((item) => {
      return lookbook.coverUID === item.uid;
    }) || lookbook.looks[0]
  );
};

enum TRANSLATION_KEY_LIST {
  title = 'title',
  shortTitle = 'shortTitle',
  summary = 'summary',
  credit = 'credit',
  description = 'description',
}

export const getIsTranslationLangValid = (lookbook: ILookBook, lang = 'en') => {
  let isValid = true;

  if (
    (lookbook.title && !lookbook.title[lang]) ||
    (lookbook.summary && !lookbook.summary[lang]) ||
    (lookbook.credit &&
      Object.keys(lookbook.credit).length &&
      !lookbook.credit[lang])
  ) {
    isValid = false;
  }

  lookbook.plan.views.forEach((view) => {
    const [firstPlane, secondPlane] = view.panes;
    const translation =
      firstPlane.translations?.[lang] || secondPlane?.translations?.[lang];

    if (
      (firstPlane.translations || secondPlane?.translations) &&
      !translation
    ) {
      isValid = false;
    }
  });

  return isValid;
};

export const getValidTranslationLang = (lookbook: ILookBook, lang = 'en') => {
  if (getIsTranslationLangValid(lookbook, lang)) {
    return lang;
  }

  const newLang = PRIMARY_LANG_LIST.filter((whiteLang) => {
    return whiteLang !== lang;
  }).find((avalibleLang) => {
    return getIsTranslationLangValid(lookbook, avalibleLang);
  });

  return newLang || Object.keys(lookbook.summary)[0];
};

export const addLookBookI18NextResources = (lookbook: ILookBook) => {
  SUPPORTED_LANGS.forEach((lang) => {
    const translations: Record<string, string> = {};

    if (lookbook.title[lang]) {
      translations[`${lookbook.uid}.${TRANSLATION_KEY_LIST.title}`] =
        lookbook.title[lang];
    }

    if (lookbook?.shortTitle?.[lang]) {
      translations[`${lookbook.uid}.${TRANSLATION_KEY_LIST.shortTitle}`] =
        lookbook.shortTitle[lang];
    }

    if (lookbook.summary[lang]) {
      translations[`${lookbook.uid}.${TRANSLATION_KEY_LIST.summary}`] =
        lookbook.summary[lang];
    }

    if (lookbook.credit[lang]) {
      translations[`${lookbook.uid}.${TRANSLATION_KEY_LIST.credit}`] =
        lookbook.credit[lang];
    }

    lookbook.plan.views.forEach((view) => {
      const [firstPlane, secondPlane] = view.panes;
      const uid = firstPlane.lookUid || secondPlane.lookUid;
      const translation =
        firstPlane.translations?.[lang] || secondPlane?.translations?.[lang];

      if (translation) {
        translations[`${uid}.${TRANSLATION_KEY_LIST.description}`] =
          translation;
      }
    });

    i18next.addResources(lang, 'translation', translations);
  });
};

export const getSlugForLookbook = (loobBook: ILookBook) => {
  return loobBook.link
    ? loobBook.link.split('/').slice(-1)[0]
    : getWithOutSpecialChars(loobBook.title.en);
};

export const fixBrokenViews = (lookbook: ILookBook) => {
  const article = { ...lookbook };
  article.plan.views = article.plan.views.filter((view) => {
    const [firstPlan, secondPlan] = view.panes;

    const look: ILook = lookbook.looks.find((lookItem) => {
      return (
        lookItem.uid === firstPlan?.lookUid ||
        lookItem.uid === secondPlan?.lookUid
      );
    }) as ILook;

    return !!look;
  });

  return article;
};

export const getAdSize = (description: string) => {
  return description.split(' ').length <= LARGE_AD_TEXT_LENGTH
    ? AD_SIZE.middle
    : AD_SIZE.small;
};

export const getSliderIdFromHash = (hash: string) => {
  return Number(hash.split('#')[1]);
};

export const getIsHashEmpty = () => {
  return !window.location.hash;
};
