import { FC } from 'react';

import StorySkeletonMainLook from '../StorySkeletonMainLook/StorySkeletonMainLook';
import StorySkeletonMayLike from '../StorySkeletonMayLike/StorySkeletonMayLike';
import SkeletonImage from '../../../../../../components/skeletons/SkeletonImage/SkeletonImage';
import SkeletonText from '../../../../../../components/skeletons/SkeletonText/SkeletonText';

import './index.scss';

const StorySkeletonMain: FC = () => {
  return (
    <div className="story-skeleton-main">
      <SkeletonImage />
      <div className="story-skeleton-main__main">
        <div className="story-skeleton-main__content">
          <SkeletonText />
          <SkeletonText isWide isLarge />
          <SkeletonText isWide />
          <SkeletonText isWide />
          <SkeletonText isWide />

          <div className="story-skeleton-main__look-list">
            <StorySkeletonMainLook />
          </div>
        </div>
        <div className="story-skeleton-main__recomendation">
          <StorySkeletonMayLike />
        </div>
      </div>
    </div>
  );
};

export default StorySkeletonMain;
