import { FC, useMemo } from 'react';

import { IIcon } from './interface.ts';
import { IconTypes } from './constants.ts';
import Image from '../Image/Image.tsx';
import ArrowRight from '../../../src/assets/icons/arrow-right.svg';
import Facebook from '../../../src/assets/icons/Facebook.svg';
import Hamburger from '../../../src/assets/icons/hamburger.svg';
import Close from '../../../src/assets/icons/close.svg';
import Pinterest from '../../../src/assets/icons/Pinterest.svg';
import Back from '../../../src/assets/icons/back.svg';
import DoubleArrowUp from '../../../src/assets/icons/double-arrow-up.svg';
import Play from '../../../src/assets/icons/play.svg';
import Pause from '../../../src/assets/icons/pause.svg';

const Icon: FC<IIcon> = ({ type, size }) => {
  const icon = useMemo(() => {
    switch (type) {
      case IconTypes.arrowRight:
        return ArrowRight;
      case IconTypes.facebook:
        return Facebook;
      case IconTypes.hamburger:
        return Hamburger;
      case IconTypes.close:
        return Close;
      case IconTypes.pinterest:
        return Pinterest;
      case IconTypes.back:
        return Back;
      case IconTypes.play:
        return Play;
      case IconTypes.pause:
        return Pause;
      case IconTypes.doubleArrowUp:
      case IconTypes.doubleArrowLeft:
      case IconTypes.doubleArrowRight:
        return DoubleArrowUp;
      default: {
        return null;
      }
    }
  }, [type]);

  const classList = useMemo(() => {
    const list = ['icon'];

    if (size) {
      list.push(`icon--${size}`);
    }

    switch (type) {
      case IconTypes.arrowRight:
        list.push(`icon--arrow-right`);
        break;
      case IconTypes.facebook:
        list.push(`icon--facebook`);
        break;
      case IconTypes.hamburger:
        list.push(`icon--hamburger`);
        break;
      case IconTypes.close:
        list.push(`icon--close`);
        break;
      case IconTypes.pinterest:
        list.push(`icon--pinterest`);
        break;
      case IconTypes.back:
        list.push(`icon--back`);
        break;
      case IconTypes.doubleArrowUp:
        list.push(`icon--double-arrow-up`);
        break;
      case IconTypes.doubleArrowLeft:
        list.push(`icon--double-arrow-left`);
        break;
      case IconTypes.doubleArrowRight:
        list.push(`icon--double-arrow-right`);
        break;
    }

    return list.join(' ');
  }, [size, type]);

  return icon ? <Image alt="icon" className={classList} src={icon} /> : null;
};

export default Icon;
