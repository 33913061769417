import { FC, useMemo } from 'react';

import './index.scss';

// Props type is not described in React Slick
const CarouselArrow: FC<any> = (props) => {
  const {
    currentSlide,
    slideCount,
    sliderRef,
    arrowIcon,
    onClick,
    onArrowClick,
    style,
    isAriaDisabledOffset = 1,
    ...propList
  } = props;

  const onButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (onArrowClick) {
      onArrowClick();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  const isAriaDisabled = useMemo(() => {
    return currentSlide === slideCount - isAriaDisabledOffset;
  }, [currentSlide, slideCount, isAriaDisabledOffset]);

  const proxyEventToList = (e: TouchEvent) => {
    const targetTrack = sliderRef.current.innerSlider.list;
    const event = new TouchEvent(e.type, {
      bubbles: true,
      touches: e.touches as unknown as Touch[],
    });
    targetTrack.dispatchEvent(event);
  };

  const styleList = useMemo(() => {
    return { ...style, display: 'flex' };
  }, [style]);

  return (
    <button
      {...propList}
      style={styleList}
      onTouchStart={proxyEventToList}
      onTouchEnd={proxyEventToList}
      onTouchMove={proxyEventToList}
      onClick={onButtonClick}
      aria-disabled={isAriaDisabled}
      disabled={isAriaDisabled}
    >
      {arrowIcon}
    </button>
  );
};

export default CarouselArrow;
