import { FC } from 'react';

import CardSkeleton from '../../../Home/components/CardSkeleton/CardSkeleton';
import { cardSize } from '../../../Home/components/CardSkeleton/interfaces';

import './index.scss';

const StorySkeletonMayLikeList: FC = () => {
  return (
    <div className="story-skeleton-may-like-list">
      <CardSkeleton size={cardSize.small} />
      <CardSkeleton size={cardSize.small} />
      <CardSkeleton size={cardSize.small} />
      <CardSkeleton size={cardSize.small} />
    </div>
  );
};

export default StorySkeletonMayLikeList;
