import React, {
  CSSProperties,
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { AUTOPLAY_TIMEOUT, SETTINGS } from './constants';
import { ICarousel } from './interfaces';
import { formattedAverageColor } from '../../../../utils';
import { AnimationDirection } from '../../../../utils/interfaces';
import { IFormattedLook, IFormattedLookBook } from '../../interfaces';
import { getAdSize, getCoverLook } from '../../utils';
import CarouselArrow from '../../../../components/CarouselArrow/CarouselArrow';
import CarouselItem from './CarouselItem/CarouselItem';
import SingleLook from '../../../../components/SingleLook/SingleLook';
import CoverLook from '../../../../components/CoverLook/CoverLook';
import { useResize } from '../../../../hooks';
import { AD_SIZE } from '../../../../utils/ads';
import Recirc from '../Recirc/Recirc';
import AdSlide from './AdSlide/AdSlide';
import NotFoundTemplate from '../../../../../../components/NotFoundTemplate';
import { useFeature } from '../../../../ContextWrapper/WithFeatureConfig/hooks';
import Timer from '../../timer';
import gtm, {
  IEventData,
  getRequiredLookEventParams,
} from '../../../../../../utils/gtm';
import { eventTypes } from '../../../../../../utils/constants';
import Icon from '../../../../../../components/Icon/Icon.tsx';
import {
  IconSize,
  IconTypes,
} from '../../../../../../components/Icon/constants.ts';

import './index.scss';

const Carousel: FC<ICarousel> = ({
  slideId,
  isRecircNotFound,
  isDotsWithTitle,
  isDotsWithBackground,
  backgroundImage,
  backgroundType,
  lookBook,
  height,
  currentLang,
  beforeChange,
  formattedLooks,
  goToNextSlide,
  adData,
  doNotTrack,
  isInitAdConfigSetUp,
  recircList,
  isRecircSlideActive,
  onSwipeOut,
  onSlideScrolled,
  isShowAdSlide,
  isAutoplay,
  adSlideFirstPosition,
  afterChange,
  interestMap,
  onRecircSelected,
}) => {
  const { isCoverSlideHasLargeAd } = useFeature();
  const sliderRef = useRef<Slider>(null);
  const { t } = useTranslation();
  const { isMobile, isTabled, isDesktop } = useResize();
  const [isActiveElementsVisible, setIsActiveElementsVisible] = useState(true);
  const [animationDirection, setAnimationDirection] =
    useState<AnimationDirection>(AnimationDirection.left);
  const [isDotsNextTitleAnimationEnded, setIsDotsNextTitleAnimationEnded] =
    useState(false);
  const [isAutoplayPaused, setIsAutoplayPaused] = useState(false);
  const [timerProgress, setTimerProgress] = useState(0);
  const [isDesktopAutoSlideAvailable, setIsDesktopAutoSlideAvailable] =
    useState(isAutoplay);
  const [isCurrentSlideScrolled, setIsCurrentSlideScrolled] = useState(false);
  const [cookies] = useCookies();
  const [searchParams] = useSearchParams();

  const onTrack = (msToEnd: number, progress: number) => {
    setTimerProgress(progress);

    if (progress === 100) {
      setTimerProgress(0);
      sliderRef?.current?.slickNext();
    }
  };

  const [timer, setTimer] = useState(
    isAutoplay ? new Timer({ onTrack, onPause: setIsAutoplayPaused }) : null
  );

  useEffect(() => {
    if (isAutoplay && !timer) {
      setTimer(new Timer({ onTrack, onPause: setIsAutoplayPaused }));
    }
  }, [isAutoplay]);

  const emitGMTTapEvent = useCallback(
    (index: number, action: string) => {
      const look = formattedLooks?.[index];
      const activeLookbook = lookBook.itemList[look?.lookbookUid];

      if (activeLookbook) {
        const { ownedBy, uid, interests, lbtype } = activeLookbook;
        const { mp_dnsmi } = cookies;

        const eventData: IEventData = {
          event: eventTypes.dtTab,
          action,
          title: t(`${uid}.title`, {
            lng: look.language,
            defaultValue: '',
          }),
          content_owner: ownedBy.name,
          id: uid,
          language: look.language,
          page_number: window.location.hash,
          primary_interest: interests[0]?.name + ':' + interests[0].uid,
          type: lbtype,
          dnsmi: mp_dnsmi === '1',
          ...getRequiredLookEventParams(searchParams),
        };

        gtm.emit(eventData);
      }
    },
    [lookBook.itemList, cookies, t, searchParams, formattedLooks]
  );

  const onPauseClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (isAutoplayPaused) {
        timer?.unpauseTimer();
        emitGMTTapEvent(slideId, eventTypes.play);
      } else {
        timer?.pause();
        emitGMTTapEvent(slideId, eventTypes.pause);
      }
    },
    [emitGMTTapEvent, isAutoplayPaused, slideId, timer]
  );

  const handleScrollContent = useCallback(
    (isScrolledDown: boolean) => {
      if (isScrolledDown) {
        setIsCurrentSlideScrolled(true);
        timer?.pause();
      } else {
        timer?.unpauseTimer();
      }
    },
    [timer]
  );

  const isAutoplayActive = useMemo(() => {
    if (isDesktop || isTabled) {
      return isAutoplay && slideId === 0 && isDesktopAutoSlideAvailable;
    }

    return isMobile && isAutoplay;
  }, [
    isAutoplay,
    slideId,
    isDesktop,
    isTabled,
    isMobile,
    isDesktopAutoSlideAvailable,
  ]);

  const autoplayIconType = useMemo(() => {
    return isAutoplayPaused ? IconTypes.play : IconTypes.pause;
  }, [isAutoplayPaused]);

  useEffect(() => {
    if (isAutoplayActive) {
      timer?.start(AUTOPLAY_TIMEOUT);
    } else {
      timer?.stop();
    }

    return () => {
      timer?.stop();
    };
  }, [isAutoplayActive, timer]);

  const currentFormattedLook = useMemo(() => {
    return formattedLooks[slideId];
  }, [formattedLooks, slideId]);

  const nextLookBook = useMemo(() => {
    return formattedLooks[slideId + 1];
  }, [formattedLooks, slideId]);

  const isStopKenBurnAnimation = useMemo(() => {
    return isAutoplayPaused && !isTabled && !isDesktop;
  }, [isAutoplayPaused, isTabled, isDesktop]);

  const carouselTitle = useMemo(() => {
    if (currentFormattedLook && nextLookBook) {
      const { lookbookUid, uid, language } =
        currentFormattedLook as IFormattedLookBook;

      if (lookbookUid !== uid && !isDotsNextTitleAnimationEnded) {
        return t(`${lookbookUid}.title`, {
          lng: language,
          defaultValue: '',
        });
      }
    }

    return '';
  }, [currentFormattedLook, nextLookBook, t, isDotsNextTitleAnimationEnded]);

  const getIsLookAnimationActive = useCallback(
    (slideIndex: number, slideId: number) => {
      return slideIndex === slideId;
    },
    []
  );

  const getAdData = useCallback(
    (look: IFormattedLook | IFormattedLookBook) => {
      const { itemList, lookBookId } = lookBook;
      const isCover = look.lookbookUid === look.uid;

      let adUnitSize = getAdSize(look.description);

      if (isCover) {
        if (isCoverSlideHasLargeAd) {
          adUnitSize = AD_SIZE.middle;
        } else {
          adUnitSize = AD_SIZE.small;
        }
      }

      return {
        adUnitSize,
        adUnitPath: adData?.ad?.unitPath,
        adUnitId: look.uid,
        isInitAdConfigSetUp: isInitAdConfigSetUp,
        iabCategoryList: itemList[lookBookId]?.iabCategories,
      };
    },
    [
      adData?.ad?.unitPath,
      isCoverSlideHasLargeAd,
      isInitAdConfigSetUp,
      lookBook,
    ]
  );

  const onItemClick = useCallback((nexSlideId: number) => {
    sliderRef?.current?.slickGoTo(nexSlideId);
  }, []);

  const getSingleTemplate = useCallback(
    (look: IFormattedLook, index: number) => {
      return (
        <CarouselItem
          classList="carousel-item--with-top-space"
          isActive={getIsLookAnimationActive(index, slideId)}
          key={index + look.uid}
          onClick={() => {
            return onItemClick(index);
          }}
        >
          <SingleLook
            pageIndex={look.pageIndex}
            description={look.description}
            averageColor={formattedAverageColor(look.averageColor)}
            images={look.images}
            kenBurnsParams={look.kenBurnsParams}
            ownedBy={look.ownedBy}
            animationDirection={animationDirection}
            isActive={getIsLookAnimationActive(index, slideId)}
            ad={getAdData(look)}
            doNotTrack={doNotTrack}
            onScroll={handleScrollContent}
            isStopAnimation={isStopKenBurnAnimation}
          />
        </CarouselItem>
      );
    },
    [
      onItemClick,
      getAdData,
      animationDirection,
      doNotTrack,
      getIsLookAnimationActive,
      slideId,
      handleScrollContent,
      isStopKenBurnAnimation,
    ]
  );

  const getCoverTeamplate = useCallback(
    (look: IFormattedLookBook, index: number) => {
      const currentLook = getCoverLook(look);

      return (
        <CarouselItem
          key={index + look.uid}
          isActive={getIsLookAnimationActive(index, slideId)}
          onClick={() => {
            return onItemClick(index);
          }}
        >
          <CoverLook
            hasReadMe
            title={t(`${look.uid}.title`, {
              lng: look.language,
              defaultValue: '',
            })}
            description={look.description}
            credit={t(`${look.uid}.credit`, {
              lng: look.language,
              defaultValue: '',
            })}
            language={look.language}
            logoURL={look.ownedBy.logoLarge}
            averageColor={currentLook.averageColor}
            images={currentLook.images}
            kenBurnsParams={currentLook.kenBurnsParams}
            ownedBy={look.ownedBy}
            animationDirection={animationDirection}
            isActive={getIsLookAnimationActive(index, slideId)}
            isStopAnimation={isStopKenBurnAnimation}
            ad={getAdData(look)}
            doNotTrack={doNotTrack}
            onReadmeClick={(e: MouseEvent<HTMLElement>) => {
              e.preventDefault();
              e.stopPropagation();
              return goToNextSlide();
            }}
            onScroll={handleScrollContent}
          />
        </CarouselItem>
      );
    },
    [
      onItemClick,
      animationDirection,
      doNotTrack,
      getIsLookAnimationActive,
      getAdData,
      goToNextSlide,
      t,
      slideId,
      handleScrollContent,
      isStopKenBurnAnimation,
    ]
  );

  const getAdTemplate = useCallback(
    (index: number) => {
      return (
        <CarouselItem
          classList="carousel-item--with-top-space"
          isActive={getIsLookAnimationActive(index, slideId)}
          key={index}
          onClick={() => {
            return onItemClick(index);
          }}
        >
          <AdSlide
            id={index}
            lookbookUid={lookBook.lookBookId}
            isActive={getIsLookAnimationActive(index, slideId)}
          />
        </CarouselItem>
      );
    },
    [getIsLookAnimationActive, onItemClick, lookBook, slideId]
  );

  const onDotsTitleAnimationEnd = () => {
    setIsDotsNextTitleAnimationEnded(true);
  };

  const onArrowClick = useCallback(() => {
    if (isRecircSlideActive) {
      sliderRef?.current?.slickGoTo(formattedLooks.length);
    }
  }, [sliderRef, formattedLooks, isRecircSlideActive]);

  const carouselItems = useMemo(() => {
    const list: JSX.Element[] = [];
    let indexOffset = 0;

    formattedLooks.forEach((item, index) => {
      if (
        isShowAdSlide &&
        index - adSlideFirstPosition >= 0 &&
        !((index - adSlideFirstPosition) % 2)
      ) {
        list.push(getAdTemplate(index + indexOffset));
        indexOffset++;
      }

      if (item.pageIndex === 0) {
        list.push(
          getCoverTeamplate(item as IFormattedLookBook, index + indexOffset)
        );
      } else {
        list.push(
          getSingleTemplate(item as IFormattedLook, index + indexOffset)
        );
      }
    });

    return list;
  }, [
    formattedLooks,
    getCoverTeamplate,
    getSingleTemplate,
    getAdTemplate,
    isShowAdSlide,
    adSlideFirstPosition,
  ]);

  const carouselDots = useCallback(
    (dots: ReactNode[]) => {
      const { lookBookId } = lookBook;

      const nextActiveArticle = formattedLooks[
        slideId + 1
      ] as IFormattedLookBook;

      const titleEl = (
        <span
          className="slick-dots__next-title paragraph--xs"
          onAnimationEnd={onDotsTitleAnimationEnd}
          onClick={goToNextSlide}
        >
          {t('nextUp', {
            lng: currentLang,
            defaultValue: '',
          })}
          :&nbsp;
          {nextActiveArticle?.shortTitle
            ? t(`${nextActiveArticle?.uid}.shortTitle`, {
                lng: currentLang,
                defaultValue: '',
              })
            : t(`${nextActiveArticle?.uid}.title`, {
                lng: currentLang,
                defaultValue: '',
              })}
        </span>
      );

      const startedFrom = formattedLooks.findIndex((item) => {
        return item.uid === lookBookId;
      });

      const endPointIndex = formattedLooks
        .slice(startedFrom)
        .findIndex((item) => {
          return item.lookbookUid !== lookBookId;
        });

      const endPoint =
        endPointIndex === -1
          ? carouselItems.length
          : startedFrom + endPointIndex;

      const isShowTitle = slideId === endPoint - 1 && !isRecircSlideActive;

      const nextArrowClassList = ['slick-dots__next-arrow'];

      if (slideId === formattedLooks.length) {
        nextArrowClassList.push('slick-dots__next-arrow--active');
      }

      return (
        <ul>
          {dots.slice(startedFrom, endPoint)}

          <li
            className={nextArrowClassList.join(' ')}
            onClick={onArrowClick}
          ></li>
          {isShowTitle ? titleEl : null}
        </ul>
      );
    },
    [
      carouselItems,
      formattedLooks,
      slideId,
      t,
      currentLang,
      lookBook,
      goToNextSlide,
      isRecircSlideActive,
      onArrowClick,
    ]
  );

  const isShowArrows = useMemo(() => {
    return formattedLooks[slideId]?.pageIndex !== 0;
  }, [formattedLooks, slideId]);

  const preloadNeiborthsSlides = (currentSlideIndex: number) => {
    // The innerSlider type is not defined
    const sliderState = (sliderRef.current?.innerSlider as any)?.state;
    if (sliderState) {
      sliderState.lazyLoadedList.push(currentSlideIndex + 1);
      sliderState.lazyLoadedList.push(currentSlideIndex - 1);
    }
  };

  const carouselClassList = useMemo(() => {
    const classList = ['carousel__wrapper'];

    if (backgroundType) {
      classList.push(
        `interest-background interest-background--with-img interest-background--${backgroundType}`
      );
    }

    if (!isActiveElementsVisible) {
      classList.push('carousel__title--hide');
    }

    if (!isShowArrows) {
      classList.push('carousel__arrow--hide');
    }

    return classList.join(' ');
  }, [backgroundType, isActiveElementsVisible, isShowArrows]);

  const wrapperStyleList = useMemo(() => {
    const styleList: any = {};

    if (height) {
      styleList.height = height;
    }

    if (backgroundImage) {
      styleList['--background-image'] = `url(${backgroundImage})`;
    }

    if (isAutoplayActive) {
      styleList['--progress'] = `${timerProgress}%`;
    }

    return styleList;
  }, [height, backgroundImage, isAutoplayActive, timerProgress]);

  const dotsClassList = useMemo(() => {
    const list = ['slick-dots'];

    if (isDotsWithTitle) {
      list.push('slick-dots--with-title');
    }

    if (isAutoplayActive) {
      list.push('slick-dots--with-timer');
    }

    if (isDotsWithBackground) {
      list.push('slick-dots--with-background');
    }

    return list.join(' ');
  }, [isDotsWithTitle, isDotsWithBackground, isAutoplayActive]);

  const onSwipe = (direction: AnimationDirection) => {
    if (direction === AnimationDirection.right && slideId === 0 && onSwipeOut) {
      onSwipeOut();
    }
    setAnimationDirection(direction);

    if (isMobile) {
      setIsAutoplayPaused(false);
    }
  };

  const beforeSlideChange = (
    currentSlideIndex: number,
    nextSlideIndex: number
  ) => {
    setIsDotsNextTitleAnimationEnded(false);
    beforeChange(currentSlideIndex, nextSlideIndex);
  };

  const recircStyleList = useMemo(() => {
    return {
      opacity: slideId >= carouselItems.length ? 1 : 0.1,
    } as CSSProperties;
  }, [slideId, carouselItems]);

  const isShowBackArrow = useMemo(() => {
    return !(slideId === carouselItems.length && isShowArrows && isMobile);
  }, [isShowArrows, slideId, carouselItems, isMobile]);

  const centerPaddingOverride = useMemo(() => {
    return isRecircSlideActive && slideId === carouselItems.length
      ? '0'
      : SETTINGS.centerPadding;
  }, [isRecircSlideActive, slideId, carouselItems.length]);

  const variableWidthOverride = useMemo(() => {
    return isRecircSlideActive && slideId === carouselItems.length
      ? false
      : SETTINGS.variableWidth;
  }, [isRecircSlideActive, slideId, carouselItems.length]);

  const onInit = () => {
    afterChange(slideId);
  };

  const onContainerClick = useCallback(
    (e: React.MouseEvent) => {
      if (isMobile && e.screenY > 160 && slideId < carouselItems.length) {
        if (slideId === 0) {
          sliderRef?.current?.slickGoTo(slideId + 1);
          return;
        }

        if (
          (sliderRef?.current?.innerSlider as any).state.slideWidth * 0.25 <=
          e.pageX
        ) {
          sliderRef?.current?.slickGoTo(slideId + 1);
        } else {
          sliderRef?.current?.slickGoTo(slideId - 1);
        }
      }
    },
    [isMobile, slideId, sliderRef, carouselItems]
  );

  useEffect(() => {
    if ((isTabled || isDesktop) && slideId > 0) {
      setIsAutoplayPaused(true);
    }
  }, [slideId, isTabled, isDesktop]);

  useEffect(() => {
    if (
      timer?.isReady &&
      !isDesktopAutoSlideAvailable &&
      (isDesktop || isTabled)
    ) {
      timer.stop();
    }
  }, [isDesktopAutoSlideAvailable, timer, isDesktop, isTabled]);

  useEffect(() => {
    sliderRef?.current?.slickGoTo(slideId);
    preloadNeiborthsSlides(slideId);
  }, [sliderRef, slideId]);

  useEffect(() => {
    setTimerProgress(0);
    if (timer?.isReady) {
      timer?.reset();
      timer?.unpauseTimer();
    }

    if (slideId >= 1) {
      setIsDesktopAutoSlideAvailable(false);
      setTimeout(() => {
        setIsActiveElementsVisible(false);
      }, 3000);
    } else {
      setIsActiveElementsVisible(true);
    }
  }, [slideId, timer]);

  useEffect(() => {
    setIsCurrentSlideScrolled(false);
  }, [slideId]);

  useEffect(() => {
    if (isCurrentSlideScrolled) {
      onSlideScrolled();
    }
  }, [isCurrentSlideScrolled, onSlideScrolled]);

  return (
    <div
      className={carouselClassList}
      style={wrapperStyleList}
      onClick={onContainerClick}
    >
      <h2 className="carousel__title">{carouselTitle}</h2>

      {isAutoplay ? (
        <button className="carousel__play-button" onClick={onPauseClick}>
          <Icon size={IconSize.xm} type={autoplayIconType}></Icon>
        </button>
      ) : null}

      {carouselItems.length ? (
        <Slider
          className="carousel interest-background__item"
          ref={sliderRef}
          onInit={onInit}
          prevArrow={
            isShowBackArrow && !isMobile ? (
              <CarouselArrow
                isAriaDisabledOffset={0}
                arrowIcon={
                  <Icon type={IconTypes.doubleArrowLeft} size={IconSize.l} />
                }
                sliderRef={sliderRef}
              />
            ) : (
              <></>
            )
          }
          nextArrow={
            isMobile ? (
              <></>
            ) : (
              <CarouselArrow
                arrowIcon={
                  <Icon type={IconTypes.doubleArrowRight} size={IconSize.l} />
                }
                sliderRef={sliderRef}
              />
            )
          }
          {...SETTINGS}
          autoplay={false}
          centerPadding={centerPaddingOverride}
          variableWidth={variableWidthOverride}
          beforeChange={beforeSlideChange}
          onSwipe={onSwipe}
          dotsClass={dotsClassList}
          appendDots={carouselDots}
          afterChange={afterChange}
          lazyLoad={false}
        >
          {carouselItems}

          {isRecircSlideActive ? (
            <div>
              <div
                className="carousel__recirc-wrapper"
                style={recircStyleList}
                onClick={() => {
                  return onItemClick(carouselItems.length);
                }}
              >
                {isRecircNotFound ? (
                  <NotFoundTemplate />
                ) : (
                  <Recirc
                    recircList={recircList}
                    interestMap={interestMap}
                    onRecircSelected={onRecircSelected}
                  />
                )}
              </div>
            </div>
          ) : null}
        </Slider>
      ) : null}
    </div>
  );
};

export default Carousel;
