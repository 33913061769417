import lookBookApiService from '../pages/Root/services/api/lookBook';
import {
  HERO_SCHEDULE,
  INTEREST_SCHEDULE,
  LATEST_SCHEDULE,
  POPULAR_SCHEDULE,
} from '../pages/Root/pages/HomeRoot/constants.ts';
import interestAPIService from '../pages/Root/services/api/interest';
import { IInterest } from '../pages/Root/services/api/interest/interfaces.ts';
import { IDailyLookBook } from '../pages/Root/services/api/lookBook/interfaces.ts';
import { IHomePageData } from './ServerDataContext.ts';

export const getInterest = async () => {
  try {
    const res = await interestAPIService.getInterestList();
    return res.interests.reduce(
      (acc: Map<string, IInterest>, item: IInterest) => {
        acc.set(item.uid, item);
        return acc;
      },
      new Map()
    );
  } catch (e: any) {
    console.warn(e);
    return new Map();
  }
};

export const getRecirc = async (id: string) => {
  try {
    return (await lookBookApiService.getRecirc(id)).lookbooks;
  } catch {
    return [];
  }
};

export const FetchHomeData = async () => {
  return {
    popularSchedule: await lookBookApiService.getDailyLookbookList(
      POPULAR_SCHEDULE,
      'all'
    ),
    latestSchedule: await lookBookApiService.getDailyLookbookList(
      LATEST_SCHEDULE,
      'all'
    ),
    heroSchedule: await lookBookApiService.getDailyLookbookList(
      HERO_SCHEDULE,
      'all'
    ),
    interestSchedule: await lookBookApiService.getDailyLookbookList(
      INTEREST_SCHEDULE.NZf7cr,
      'all'
    ),
  } as IHomePageData;
};

export const fetchDailyListData = async (schedule: string) => {
  const storyList: IDailyLookBook[] = [];

  await lookBookApiService
    .getDailyLookbookList(schedule, 'all')
    .then((data) => {
      if (data.items.length) {
        storyList.push(...data.items);
      }
    })
    .catch((e) => {
      console.error(e);
    });

  return storyList;
};
