import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const RESOURCE = {
  en: {
    translation: {
      nextUp: 'Next Up',
      readTheStory: 'Read the story',
      swipeLeftForMore: 'Swipe left for more',
      interestTitle: 'Explore the world in galleries',
      interestspageSubTitle:
        'From top stories to stay informed,<1> to beautiful photo galleries to inspire you.<1>',
      ourLatestPicks: 'Our Latest Picks',
      seeMore: 'See more',
      popularGalleries: 'Popular Galleries',
      discoverTopicsThatInterestYou: 'Discover Topics That Interest You',
      more: 'More',
      thanksForSigningUp: 'Thanks for Signing Up!',
      moreInspiringPhotos:
        'More inspiring photos and stories from FotoScapes are coming to your inbox soon.',
      curatedCollectionsDeliveredtoYourInbox:
        'Curated Collections Delivered to Your Inbox',
      discoverMoreInspiringPhotos:
        'Discover more inspiring photos and stories from FotoScapes.',
      signUp: 'Sign Up',
      footerPPAndTS:
        'I would like to receive top content and other updates from FotoScapes. By signing up, you agree to our <1>Terms of Use</1> and acknowledge that you have read our <3>Privacy Policy</3>.',
      everydayLifestyle: 'Everyday lifestyle for curious people.',
      followUs: 'Follow Us',
      galleries: 'Galleries',
      sneakers: 'Sneakers',
      celebrityHomes: 'Celebrity Homes',
      games: 'Games',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      managePrivacy: 'Manage Privacy',
      doNotSellMyPersonalInformation: 'Do Not Sell My Personal Information',
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      popularTopics: 'Popular Topics',
      home: 'Home',
      'fashion&Sneakers': 'Fashion & Sneakers',
      travelDestinations: 'Travel Destinations',
      close: 'Close',
      gameTitle: 'Playable Games',
      gameSubtitle:
        'Kick back and enjoy some of our favorite playable games. Each one only takes a few minutes to explore. We add games weekly, so check back regularly to improve your high scores and try out new games.',
      footerTerms:
        'Yes! I’d like to receive occasional communications from Fotoscapes. By submitting this form, I agree to this <1>privacy policy</1>',
      stories: 'Stories',
      fotoSCAPESSExclusive: 'FotoSCAPES Exclusive Stories',
      diveDeep: 'Dive deep into our most noteworthy topics',
      youMayLike: 'You May Like',
      relatedStories: 'Related Stories',
      lifestyle: 'lifestyle',
      culture: 'culture',
      travel: 'travel',
      entertainment: 'entertainment',
      latest: 'latest',
      digitalTurbineTermsOfUse: 'Digital Turbine Terms of Use',
      noticeAtCollectionAndPrivacyPolicy:
        'Notice at Collection and Privacy Policy',
      readyToLevelUp: 'Ready to level up?',
      signUpForOur:
        'Sign up for our newsletter and unlock exclusive, life-changing guides!',
      chooseYourFavorite:
        'Choose your favorite—or select all three—and start transforming your life today:',
      lifeHacks: 'Life Hacks',
      styleTips: 'Style Tips',
      travelGuides: 'Travel Guides',
      emailAddress: 'Email Address',
      enterYourEmail: 'Enter your email',
      bySubscriber:
        'By subscribing, you agree to our <1>Terms of Use</1> and <3>Privacy Policy</3>.',
      submit: 'Submit',
      notNow: 'Not now',
      haveIdeasOn: 'Have ideas on how we can improve?',
      sendThemOurWay: 'Send them our way!',
      ourTeamIsDedicated: 'Our team is dedicated to improving your experience.',
      sendUsAMessage: 'Send us a message',
      letUsKnow: 'Let us know how we’re doing',
      yourOpinionMatters: 'Your opinion matters!',
      feedback: 'Feedback',
      recommendedForYou: 'Recommended For You.',
    },
  },
  es: {
    translation: {
      nextUp: 'Siguiente',
      readTheStory: 'Leer Más',
      swipeLeftForMore: 'Desliza a la izquierda',
      interestTitle: 'Explora el mundo en galerías',
      interestspageSubTitle:
        'Desde noticias destacadas para mantenerte <1>informado hasta hermosas galerías de fotos para inspirarte</1>',
      ourLatestPicks: 'Nuestras últimas selecciones',
      seeMore: 'Ver más',
      popularGalleries: 'Galerías populares',
      discoverTopicsThatInterestYou: '',
      more: 'Más',
      thanksForSigningUp: '¡Gracias por registrarte!',
      moreInspiringPhotos:
        'Pronto llegarán a su bandeja de entrada más fotos e historias inspiradoras de FotoScapes.',
      curatedCollectionsDeliveredtoYourInbox:
        'Colecciones seleccionadas enviadas a su bandeja de entrada.',
      discoverMoreInspiringPhotos:
        'Descubra más fotografías e historias inspiradoras de Fotoscapes',
      signUp: 'Inscribirse',
      footerPPAndTS:
        'Me gustaría recibir el mejor contenido y otras actualizaciones de FotoScapes. Al registrarse, acepta nuestros <1>Términos</1> de uso y reconoce que ha leído nuestra <3>Política</3> de privacidad.',
      everydayLifestyle: 'Estilo de vida cotidiano para gente curiosa.',
      followUs: 'Síganos',
      galleries: 'Galerías',
      sneakers: 'Zapatillas',
      celebrityHomes: 'Casas de celebridades',
      games: 'Juegos',
      termsOfService: 'Términos de servicio',
      privacyPolicy: 'Política de privacidad',
      managePrivacy: 'Administrar privacidad',
      doNotSellMyPersonalInformation: 'No vendas mi información personal',
      aboutUs: 'Sobre nosotros',
      contactUs: 'Contacta con nosotros',
      popularTopics: 'Temas populares',
      home: 'Hogar',
      'fashion&Sneakers': 'Moda y zapatillas',
      travelDestinations: 'Destinos de viaje',
      close: 'Cerca',
      gameSubtitle:
        'Relájate y disfruta de algunos de nuestros juegos jugables favoritos. Cada uno solo toma unos minutos para explorar. Agregamos juegos semanalmente, así que visítenos periódicamente para mejorar sus puntuaciones más altas y probar juegos nuevos.',
      footerTerms:
        '¡Sí! Me gustaría recibir comunicaciones ocasionales de Fotoscapes. Al enviar este formulario, acepto esta <1>política de privacidad</1>',
      stories: 'Cuentos',
      fotoSCAPESSExclusive: 'Historias exclusivas de FotoSCAPES',
      diveDeep: 'Profundice en nuestros temas más destacados.',
      youMayLike: 'Te podría gustar',
      relatedStories: 'Historias similares',
      lifestyle: 'estilo de vida',
      culture: 'cultura',
      travel: 'viaje',
      entertainment: 'entretenimiento',
      latest: 'novedades',
      digitalTurbineTermsOfUse: 'Términos de Uso de Digital Turbine',
      noticeAtCollectionAndPrivacyPolicy:
        'Aviso de Recopilación y Política de Privacidad',
      readyToLevelUp: '¿Listo para subir de nivel',
      signUpForOur:
        'Suscríbete a nuestro boletín y desbloquea guías exclusivas que cambiarán tu vida!',
      chooseYourFavorite:
        'Elige tu favorito, o selecciona los tres, y comienza a transformar tu vida hoy mismo:',
      lifeHacks: 'Trucos de Vida',
      styleTips: 'Consejos de Estilo',
      travelGuides: 'Guías de viaje',
      emailAddress: 'Dirección de correo electrónico',
      enterYourEmail: 'Introduce tu correo electrónico',
      bySubscriber:
        'Al suscribirte, aceptas nuestros <1>Términos de Uso</1> y <3>Política de Privacidad</3>.',
      submit: 'Enviar',
      notNow: 'No ahora',
      haveIdeasOn: '¿Tienes ideas sobre cómo podemos mejorar',
      sendThemOurWay: '¡Envíanos tus ideas',
      ourTeamIsDedicated:
        'Nuestro equipo está dedicado a mejorar tu experiencia.',
      sendUsAMessage: 'Envíanos un mensaje',
      letUsKnow: 'Haznos saber cómo lo estamos haciendo',
      yourOpinionMatters: '¡Tu opinión importa',
      feedback: 'Comentarios',
      recommendedForYou: 'Recomendado para ti.',
    },
  },
  pt: {
    translation: {
      nextUp: 'Seguinte',
      readTheStory: 'Leia Mais',
      swipeLeftForMore: 'Deslize para a esquerda',
      interestTitle: 'Explore o mundo nas galerias',
      interestspageSubTitle:
        'De histórias em alta para manter-se informado à lindas galerias de fotos para lhe inspirar',
      ourLatestPicks: 'Nossas últimas escolhas',
      seeMore: 'Veja mais',
      popularGalleries: 'Galerias Populares',
      discoverTopicsThatInterestYou: 'Descubra Tópicos que lhe Interessam',
      more: 'Mais',
      thanksForSigningUp: 'Obrigado por se Registrar',
      moreInspiringPhotos:
        'Mais fotos inspiradores e histórias do FotoScapes vão ser enviadas ao seu email',
      curatedCollectionsDeliveredtoYourInbox:
        'Coleções selecionadas, enviadas diretamente ao seu email',
      discoverMoreInspiringPhotos:
        'Descubra mais fotos inspiradoras e histórias do FotoScapes',
      signUp: 'Registre-se',
      footerPPAndTS:
        'Eu gostaria de receber conteúdos em alta e outras atualizações do FotoScapes. Ao se registrar, você aceita os nossos <1>Termos de Uso</1> e confirma que leu nossas <3>Políticas de Privacidade</3>',
      everydayLifestyle: 'Estilo de vida diário para pessoas curiosas',
      followUs: 'Nos siga',
      galleries: 'Galerias',
      sneakers: 'Tênis',
      celebrityHomes: 'Casas das Celebridades',
      games: 'Jogos',
      termsOfService: 'Termos de Uso',
      privacyPolicy: 'Política de Privacidade',
      managePrivacy: 'Configure a sua Privacidade',
      doNotSellMyPersonalInformation: 'Não venda meus dados pessoais',
      aboutUs: 'Sobre nós',
      contactUs: 'Contate-nos',
      popularTopics: 'Tópicos Populares',
      home: 'Casa',
      'fashion&Sneakers': 'Moda & Tênis',
      travelDestinations: 'Destinos de Viagens',
      close: 'Fechar',
      gameTitle: 'Jogos',
      gameSubtitle:
        'Descanse e aproveite alguns dos nossos jogos favoritos. Cada um leva apenas alguns minutos para explorar. Nós adicionamos jogos semanalmente, então confira regularmente para bater seu recorde e experimentar novos jogos',
      footerTerms:
        'Sim! Eu gostaria de receber ocasionalmente comunicações do FotoScapes. Ao enviar esse formulário, eu aceito a <1>Política de Privacidade</1>',
      stories: 'Histórias',
      fotoSCAPESSExclusive: 'Histórias Exclusivas do FotoSCAPES',
      diveDeep: 'Se aprofunde nos nossos tópicos mais importantes',
      youMayLike: 'Você vai gostar',
      relatedStories: 'Histórias Relacionadas',
      lifestyle: 'estilo de vida',
      culture: 'cultura',
      travel: 'viagem',
      entertainment: 'entretenimento',
      latest: 'novidades',
      digitalTurbineTermsOfUse: 'Termos de Uso da Digital Turbine',
      noticeAtCollectionAndPrivacyPolicy:
        'Aviso de Coleta e Política de Privacidade',
      readyToLevelUp: 'Pronto para subir de nível?',
      signUpForOur:
        'Inscreva-se na nossa newsletter e desbloqueie guias exclusivos que mudam vidas!',
      chooseYourFavorite:
        'Escolha seu favorito — ou selecione os três — e comece a transformar sua vida hoje:',
      lifeHacks: 'Dicas de Vida',
      styleTips: 'Dicas de Estilo',
      travelGuides: 'Guias de Viagem',
      emailAddress: 'Endereço de E-mail',
      enterYourEmail: 'Digite seu e-mail',
      bySubscriber:
        'Ao se inscrever, você concorda com nossos <1>Termos de Uso</1> e <3>Política de Privacidade</3>.',
      submit: 'Enviar',
      notNow: 'Agora não',
      haveIdeasOn: 'Tem ideias de como podemos melhorar?',
      sendThemOurWay: 'Envie para nós!',
      ourTeamIsDedicated:
        'Nossa equipe está dedicada a melhorar sua experiência.',
      sendUsAMessage: 'Envie-nos uma mensagem',
      letUsKnow: 'Conte-nos como estamos indo',
      yourOpinionMatters: 'Sua opinião é importante!',
      feedback: 'Feedback',
      recommendedForYou: 'Recomendado para você.',
    },
  },
  de: {
    translation: {
      nextUp: 'Als Nächstes',
      readTheStory: 'Mehr Lesen',
      swipeLeftForMore: 'Wischen Sie nach links',
    },
  },
  fr: {
    translation: {
      nextUp: 'Prochain',
      readTheStory: 'Lire La Suite',
      swipeLeftForMore: 'Balayez vers la gauche',
    },
  },
  hi: {
    translation: {
      nextUp: 'अगला',
      readTheStory: 'और पढ़ें',
      swipeLeftForMore: ' बाएँ स्वाइप करें। (Baayein swipe karein)',
    },
  },
  it: {
    translation: {
      nextUp: 'Prossimo',
      readTheStory: 'Leggi Di Più',
      swipeLeftForMore: 'Scorri a sinistra.',
    },
  },
  tl: {
    translation: {
      nextUp: 'Susunod',
      readTheStory: 'Magbasa Pa',
      swipeLeftForMore: 'I-swipe sa kaliwa',
    },
  },
};

export const PRIMARY_LANG_LIST = ['en', 'es', 'pt'];

export const SUPPORTED_LANGS = [
  ...PRIMARY_LANG_LIST,
  'de',
  'fr',
  'hi',
  'it',
  'pt',
  'tl',
];

export const initLocale = (lang: string) => {
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: RESOURCE,
      lng: lang.split('-')[0],
      supportedLngs: SUPPORTED_LANGS,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export const getTranslation = (value: Record<string, string>) => {
  const lang = resolvedLanguage();
  return value[lang] || value.en || '';
};

export const resolvedLanguage = (langFallback?: string) => {
  return i18n.resolvedLanguage || i18n.language || langFallback || 'en';
}
