import { FC } from 'react';

import Image from '../../../../../../components/Image/Image';
import { IPressCardProps } from './interfaces';
import FotoscapeLink from '../../../../../../components/FotoscapeLink/FotoscapeLink';

import './index.scss';

const PressCard: FC<IPressCardProps> = ({
  link,
  title,
  subtitle,
  imageList,
}) => {
  return (
    <FotoscapeLink className="press-card card-animation" to={link}>
      <div className="press-card__image-wrapper card-animation__image-wapper">
        <Image
          className="press-card__image card-animation__image"
          alt="card"
          imageList={imageList}
        />
      </div>

      <div className="press-card__content">
        <div className="press-card__subtitle label--m">{subtitle}</div>
        <div className="press-card__title card-animation__text f-headline--xm">
          {title}
        </div>
      </div>
    </FotoscapeLink>
  );
};

export default PressCard;
