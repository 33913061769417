export const ROUTER_ANIMATION_TYPE = {
  routerAnimationFadeIn: 'router-animation-fade-in',
  routerAnimationFadeOut: 'router-animation-fade-out',
  routerAnimationInterestIn: 'router-animation-interest-in',
  routerAnimationFullScreen: 'route-animation-full-screen',
};

export const HOMEPAGE_ROUTE_PATH = '/';
export const LOOKBOOK_ROUTE_PATH = '/lookbook/:id/:title?';
export const CATEGORY_ROUTE_PATH = '/interests/:interestTitle';
export const INTEREST_ROUTE_PATH = '/interests';
export const GAMES_ROUTE_PATH = '/games';
export const NOT_FOUND_PAGE_ROUTE_PATH = '*';
export const STORIES_ROUTE_PATH = '/stories';
export const DO_NOT_SELL_ROUTE_PATH = '/do-not-sell-my-personal-information';
export const MANAGE_PRIVACY_ROUTE_PATH = '/manage-privacy';
export const ABOUT_US_ROUTE_PATH = '/about-us';
export const CONTACT_US_ROUTE_PATH = '/contact';

export const INTEREST_URL: Record<string, string> = {
  x6fpXFR: 'adventure',
  '3xfBzCl': 'architecture',
  jxfZUD: 'art-and-design',
  '01f2PHo': 'astrology',
  dofxEcL: 'business',
  '97fvsR': 'cars-and-rides',
  LRfPcJ: 'celebrities',
  '26fgYum': 'celebrity-homes',
  e1frGSP: 'celebrity-style',
  ZofNtA: 'culture',
  oNfDHQ: 'designer-fashion',
  '4xfdS3': 'editorial-photography',
  '6JfV7FW': 'entertainment',
  Rqfo2tg: 'entertainment-news',
  Rqf2Tg: 'fashion',
  q6fqjSw: 'food-and-drink',
  v4fYViL: 'gear-and-gadgets',
  LRfbPUJ: 'general-news',
  ZofgNIA: 'health',
  v4fVCL: 'home',
  '1Df64CD': 'influencers',
  jxfQZuD: 'international-news',
  g6fYliB: 'lifestyle',
  e1fGFP: 'mens-style',
  lAfyfy: 'music',
  '4xf2df3': 'news',
  '3xfzul': 'noteworthy',
  dofEhL: 'outdoor-photography',
  '7mfnQIy': 'personal-finance',
  '01fPho': 'photography',
  lAfvyuy: 'politics',
  '97fvvHR': 'science',
  aYfZ9HP: 'science-news',
  ANfkmhe: 'shopping',
  OnfyOho: 'sneakers',
  '01f7Pco': 'sports',
  '26fbYCm': 'sports-news',
  '97fEvFR': 'strange-news',
  WJfPRCJ: 'technology',
  '3xfzzil': 'technology-news',
  x6fBXcR: 'top-news',
  NZf7cr: 'travel',
  w6fnIL: 'tv-and-film',
  v4fBVtL: 'us-news',
  BBfmJtJ: 'wellness',
  VPf7tE: 'womens-style',
};
