import { FC } from 'react';

import CategoryCardSkeleton from './components/CategoryCardSkeleton/CategoryCardSkeleton';

import './index.scss';

const CategorySkeleton: FC = () => {
  return (
    <div className="category-skeleton">
      <div className="category-skeleton__header"></div>
      <div className="category-skeleton__subheader"></div>

      <div className="category-skeleton__card-list">
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
        <CategoryCardSkeleton />
      </div>
    </div>
  );
};

export default CategorySkeleton;
