import { FC } from 'react';

import { ITextParser } from './interface';
import { getMarkdown } from './utils.tsx';

const TextParser: FC<ITextParser> = ({ text, option }) => {
  return <>{getMarkdown(text, option)}</>;
};

export default TextParser;
