import { FC, MouseEvent, forwardRef, useCallback, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { getQuery } from '../../utils/navigation';
import { IFotoscapeLinkProps } from './interfaces';
import gtm from '../../utils/gtm';
import { getIsLinkExternal } from './utils';
import { eventTypes } from '../../utils/constants';

const FotoscapeLink: FC<IFotoscapeLinkProps> = forwardRef((props, ref) => {
  const [search] = useSearchParams();

  const to = useMemo(() => {
    if (typeof props.to === 'string') {
      const origin = props.to.split('?')[0];
      const searchString = props.to.split('?')[1];

      return `${origin}?${getQuery(searchString, search)}`;
    }

    return {
      ...props.to,
      search: getQuery(props.to.search || '', search),
    };
  }, [props.to, search]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      const nextPath = typeof to === 'string' ? to : to?.pathname || '';

      const eventData = {
        from: window.location.href,
        to: nextPath,
      };

      if (getIsLinkExternal(nextPath)) {
        e.preventDefault();
        gtm.emit({
          ...eventData,
          event: eventTypes.clickExternal,
        });

        setTimeout(() => {
          window.location.href = nextPath;
        }, 300);
      } else {
        gtm.emit({
          ...eventData,
          event: eventTypes.clickInternal,
        });
      }

      if (props.onClick) {
        props.onClick(e);
      }
    },
    [to, props]
  );

  return <Link ref={ref} {...props} to={to} onClick={onClick} />;
});

export default FotoscapeLink;
