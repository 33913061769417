export const INTEREST_UID_QUERY = 'interestId';

export const INTEREST_SCHEDULE: Record<string, string> = {
  x6fpXFR: 'standard-adventure',
  '3xfBzCl': 'standard-architecture',
  '97fvsR': 'standard-cars',
  '26fgYum': 'standard-celebrityhomes',
  e1frGSP: 'standard-celebritystyle',
  Rqf2Tg: 'standard-fashion',
  v4fYViL: 'standard-gear',
  v4fVCL: 'standard-home',
  g6fYliB: 'standard-lifestyle',
  dofEhL: 'standard-outdoorphotography',
  '01fPho': 'standard-photography',
  ANfkmhe: 'standard-shopping',
  OnfyOho: 'standard-sneakers',
  w6fnIL: 'standard-tvandfilm',
  NZf7cr: 'standard-travel',
};

export const POPULAR_SCHEDULE = 'standard-popular';
export const HERO_SCHEDULE = 'standard-hero';
export const LATEST_TITLE = 'latest';
export const LATEST_SCHEDULE = `standard-${LATEST_TITLE}`;
export const GAMES_SCHEDULE = 'html5games';
export const STANDART_STORIES_SCHEDULE = 'standard-stories';

export const DEFAULT_BRAND_GROUP_ID = 'OnfysO';
