export interface IKenBurnsAnimation {
  transitionProperty: string;
  transform: string;
  transitionDuration: number;
}

export enum LastAnimationUsed {
  start = 'start',
  end = 'end',
}

export enum AnimationDirection {
  left = 'left',
  right = 'right',
}

export interface ITextOptions {
  paragraphClass?: string;
  subHeaderFirstLevelClass?: string;
  isIgnoreParagraph?: boolean;
}
