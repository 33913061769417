import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';

import { ILazyProps } from './interfaces';

import './index.scss';

const Lazy: FC<ILazyProps> = ({
  children,
  styleList,
  className,
  onRender,
  fallback,
}) => {
  const [wasRendered, setWasRendered] = useState(false);
  const myRef = useRef(null);
  const { inViewport } = useInViewport(myRef, {}, { disconnectOnLeave: true });

  useEffect(() => {
    if (inViewport) {
      setWasRendered(true);
      myRef.current = null;
    }
  }, [inViewport]);

  useEffect(() => {
    if (wasRendered && onRender) {
      onRender();
    }
  }, [wasRendered, onRender]);

  const classList = useMemo(() => {
    const list = ['lazy'];

    if (className) {
      list.push(className);
    }

    return list.join(' ');
  }, [className]);

  return (
    <div className={classList} style={styleList} ref={myRef}>
      {wasRendered ? children : fallback}
    </div>
  );
};

export default Lazy;
