import { IIabCategory } from '../services/api/lookBook/interfaces';
import { IAdTag } from '../services/api/ads/interfaces';

export const AD_SIZE = {
  small: { width: 320, height: 50 },
  middle: { width: 300, height: 250 },
};

export const loadAdScripts = async (adTag: IAdTag) => {
  const list = [adTag.pubwisePreScript, adTag.pubwiseScript];

  const scriptList = list.map((item) => {
    return new Promise((resolve, reject) => {
      const scriptEl = document.createElement('script');
      if (item) {
        scriptEl.src = item;
        scriptEl.onerror = reject;
      }
      scriptEl.onload = resolve;
      scriptEl.onerror = reject;

      document.head.appendChild(scriptEl);
    });
  });

  return Promise.all(scriptList);
};

export const refreshAdd = (adUnitId: string) => {
  window.googletag.cmd.push(async function () {
    const slot = window.gptadslots[`ad_unit_${adUnitId}`];
    window.googletag.display(`ad_unit_${adUnitId}`);

    if (slot) {
      if (
        typeof window.pubwise !== 'undefined' &&
        window.pubwise.enabled === true
      ) {
        window.pbjs.que.push(function () {
          slot.pwUseAdunitName = 1;
          window.pwRegisterLazyLoad(slot, 2, [50, 0, 50, 0], 0, 768, 2);
        });
      } else {
        window.googletag.pubads().refresh([slot]);
      }
    }
  });
};

interface ISetAdBlock {
  pageIndex: number;
  adUnitPath: string;
  adUnitSize: { width: number; height: number };
  adUnitId: string;
  iabCategoryList?: IIabCategory[];
}

export const setAdToBlock = async ({
  pageIndex,
  adUnitPath,
  adUnitSize,
  adUnitId = '0',
  iabCategoryList = [],
}: ISetAdBlock) => {
  window.googletag = window.googletag || { cmd: [] };

  if (!window.gptadslots[`ad_unit_${adUnitId}`]) {
    window.googletag.cmd.push(function () {
      window.gptadslots[`ad_unit_${adUnitId}`] = window.googletag.defineSlot(
        adUnitPath,
        [adUnitSize.width, adUnitSize.height],
        `ad_unit_${adUnitId}`
      );

      const pubads = window.googletag.pubads();
      const slot = window.gptadslots[`ad_unit_${adUnitId}`];

      if (slot) {
        slot
          .setForceSafeFrame(true)
          .addService(pubads)
          .setTargeting('page_number', `${pageIndex}`);

        if (iabCategoryList.length) {
          slot.setTargeting(
            'iab_category_codes',
            iabCategoryList.map((item) => {
              return item.code;
            })
          );
          slot.setTargeting(
            'iab_category_names',
            iabCategoryList.map((item) => {
              return item.label;
            })
          );
        }
      }
    });
  }

  refreshAdd(adUnitId);
};
