import { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import fotoscapeIcon from '../../assets/images/LogoFotoscape.svg';
import Dialog from '../../pages/Root/components/Dialog';
import Label from '../Form/Label/Label';
import Input from '../Form/Input/Input';
import Checkbox from '../Form/Checkbox/Checkbox';
import { FORM_DATA_LIST } from './constant';
import { ISubscribeProps, signups } from './interface';
import FotoscapeLink from '../FotoscapeLink/FotoscapeLink';
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from '../../pages/Root/utils/constants';

import './index.scss';

const Subscribe: FC<ISubscribeProps> = ({ isVisible, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [signups, setSignups] = useState<signups[]>([]);

  const onFormSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit({
        name: name,
        signups: signups,
      });
    },
    [onSubmit, name, signups]
  );

  const isFormValid = useMemo(() => {
    return isNameValid && !!signups.length;
  }, [isNameValid, signups]);

  const onEmailChange = useCallback((value: string, isValid: boolean) => {
    setIsNameValid(isValid);
    setName(value);
  }, []);

  const onSignupsChange = useCallback((isChecked: boolean, value: string) => {
    setSignups((prevState) => {
      if (isChecked) {
        return [...prevState, value as signups];
      }
      return prevState.filter((item) => {
        return item !== value;
      });
    });
  }, []);

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <main className="subscribe">
        <div className="subscribe__logo">
          <img src={fotoscapeIcon} alt="logo" />
        </div>
        <div className="subscribe__description paragraph--m">
          {t('readyToLevelUp')} {t('signUpForOur')} {t('chooseYourFavorite')}
        </div>

        <form className="subscribe__form" onSubmit={onFormSubmit}>
          <div className="subscribe__checkbox-list">
            {FORM_DATA_LIST.map(({ title, value }) => {
              return (
                <label
                  className="subscribe__checkbox-item f-headline--xm"
                  key={value}
                >
                  <Checkbox
                    value={value}
                    isChecked={false}
                    onChange={onSignupsChange}
                  />
                  <div className="subscribe__checkbox-label-text">
                    {t(title)}
                  </div>
                </label>
              );
            })}
          </div>

          <Label text="Email Address">
            <Input
              type="email"
              placeholder="Enter your email"
              onChange={onEmailChange}
            />
          </Label>

          <div className="subscribe__ts-text label--m">
            <Trans i18nKey="bySubscriber">
              By subscribing, you agree to our &nbsp;
              <FotoscapeLink className="link" to={TERMS_OF_SERVICE_LINK}>
                Terms of Use
              </FotoscapeLink>
              &nbsp;and &nbsp;
              <FotoscapeLink className="link" to={PRIVACY_POLICY_LINK}>
                Privacy Policy
              </FotoscapeLink>
            </Trans>
          </div>

          <div className="subscribe__actions">
            <button
              type="submit"
              className="button button--large"
              disabled={!isFormValid}
            >
              {t('submit')}
            </button>

            <button
              type="button"
              className="button button__not-now"
              onClick={onClose}
            >
              {t('notNow')}
            </button>
          </div>
        </form>
      </main>
    </Dialog>
  );
};

export default Subscribe;
