// src/layouts/UserLayout/UserLayout.tsx
import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchFeedQuery } from '../net/feed-fetch-query.ts';
import { FeedQueryResult, LightboxItem } from '../net/softbox-types.ts';
import StoryCarousel from '../components/StoryCarousel/StoryCarousel.tsx';
import StoryMedium from '../components/StoryMedium/StoryMedium.tsx';
import StorySmall from '../components/StorySmall/StorySmall.tsx';
import TouchableOpacityLinker from '../../components/TouchableOpacityLinker/TouchableOpacityLinker.tsx';
import FotoscapeLink from '../../components/FotoscapeLink/FotoscapeLink.tsx';

import './FeedLayout.scss'
import { IInterestResponse } from '../../pages/Root/services/api/interest/interfaces.ts';

import layout from './FeedLayoutConfiguration.json';
import { getTranslation as _t } from '../../i18n.ts';
import SectionHeader from '../components/SectionHeader/SectionHeader.tsx';

export const FeedLayout = (props) => {
  const { lang, hour } = useParams<{lang: string, hour: string}>();

  //XXX NOTE - buildMomentRoute is mutating this element's props to add qeuryFn XXX
  const feedQueryConstruct = {
    queryKey: [window && window.location ? window.location.pathname : `feed/${lang}/${hour}`],
    queryFn: props.queryFn ? props.queryFn : fetchFeedQuery
  }

  const { data, isLoading } = useQuery<FeedQueryResult>(feedQueryConstruct);

  return (<div>
    <header>Feed!</header>
    <main>

      {data && <div className="moment-container">
        <div className="feed-container">
          <SectionHeader text="Latest and greatest"/>
          <Feed newsItems={data.news.items} interests={data.interests} />
        </div>
      </div>}
    </main>
  </div>
)};

interface FeedProps {
  newsItems: LightboxItem[];
  interests: IInterestResponse;
}


const getElByComponentType = (
  componentType: string,
  item: LightboxItem,
  interests: IInterestResponse) => {

  let component: ReactNode;

  switch (componentType) {
    case "StoryCarousel":
      component = (
        <StoryCarousel
          key={item.uid}
          title={_t(item.title)}
          description={_t(item.summary)}
          imageList={item.images}
        />
      )
      break;
    case "StoryMedium":
      component = (
        <StoryMedium
          key={item.uid}
          title={_t(item.title)}
          description={_t(item.summary)}
          imageList={item.images}
          tags={translateInterests(item.interests, interests)}
        />
      )
      break;
    case "StorySmall":
      component = (
        <StorySmall
          key={item.uid}
          title={_t(item.title)}
          imageList={item.images}
          category={translateInterests(item.interests, interests).join(" ")}
        />
      )
      break;
    default:
      component = <></>
  }

  return component;
}

const translateInterests = (target: string[], interests: IInterestResponse) => {
  const output = []
  for (let i = 0; i < target.length; i++) {
    const interest = interests.interests.find((interest) => {
      return interest.uid === target[i];
    })
    if (interest) {
      output.push(_t(interest.name))
    }
  }
  return output
};
const Feed: React.FC<FeedProps> = ({ newsItems, interests }) => {
  if (newsItems.length === 0) return <p>No news available.</p>;
  const renderFeed = () => {
    let newsIndex = 0; // Counter for news items
    const feedComponents = layout.map((slot, index) => {
      if (slot.type === 'ad') {
        return <div key={`ad-${index}`} className="ad-placeholder">Ad Here</div>;
      } else if (slot.type === 'news' && newsIndex < newsItems.length) {
        const item = newsItems[newsIndex];
        newsIndex++; // Move to the next news item

        return (
          <TouchableOpacityLinker
            ClickerEl={FotoscapeLink}
            clickerElProps={{ to: item.link, className: "moment-link" }}>
            {getElByComponentType(slot?.component || '', item, interests)}
          </TouchableOpacityLinker>
        )
      }
      return null; // Skip if no news item is available
    }).filter(component => component !== null); // Remove null entries

    return feedComponents;
  };

  return <div className="news-feed">{renderFeed()}</div>;
};
