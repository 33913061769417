import { FC } from 'react';

import SkeletonImage from '../../../../../../components/skeletons/SkeletonImage/SkeletonImage';
import SkeletonText from '../../../../../../components/skeletons/SkeletonText/SkeletonText';

import './index.scss';

const StoriesSkeletonCard: FC = () => {
  return (
    <div className="stories-skeleton-card">
      <div className="stories-skeleton-card__image"></div>
      <SkeletonImage />
      <SkeletonText />
      <SkeletonText isWide isLarge />
      <SkeletonText isWide />
      <SkeletonText isWide />
    </div>
  );
};

export default StoriesSkeletonCard;
