import { FC } from 'react';

import './index.scss';

const AboutUs: FC = () => {
  return (
    <div className="legal-page about-us">
      <div className="about-us__container">
        <h1 className="about-us__title headline--xl">About Us</h1>
        <h2 className="about-us__subtitle headline--s">
          Everyday lifestyle for curious people
        </h2>
        <main>
          <div className="about-us__description paragraph--m">
            <p className="about-us__paragraph">
              Welcome to Fotoscapes, where we're dedicated to creating and
              showcasing captivating stories and galleries that celebrate
              everyday lifestyle for curious minds worldwide. Since our
              inception in 2017, we've been on a mission to inspire and engage
              our audience through our diverse array of content.
            </p>
            <p className="about-us__paragraph">
              We believe in the power of storytelling to connect, educate, and
              entertain. Specializing in short stories and galleries designed
              for maximum engagement and ease of consumption, we're proud to
              reach over 3 million users each month.{' '}
            </p>

            <p className="about-us__paragraph">
              Central to our publishing process is a dynamic pipeline that
              leverages technology and automation to streamline delivery, while
              also recognizing the importance of human judgment where it matters
              most. This approach ensures that we maintain a high standard of
              quality across all touchpoints and platforms.
            </p>

            <p className="about-us__paragraph">
              In addition to our editorial prowess, we're experts in
              monetization, working hand in hand with our direct sales team to
              maximize revenue per user and leveraging best in class
              programmatic toolsets. We collaborate closely with content
              partners and brands to generate exposure across our user base.
              Through strategic partnerships, we amplify brand messaging and
              deliver targeted content that resonates with our audience,
              fostering authentic connections between consumers and companies.
            </p>

            <p className="about-us__paragraph">
              Furthermore, we prioritize cultivating relationships with
              publishers to access exclusive content, giving us the freedom to
              control how and where third-party content is displayed. This,
              coupled with our extensive catalog of original content, provides
              us with the speed and flexibility needed to craft innovative
              experiences that captivate and inspire.
            </p>

            <p className="about-us__paragraph">
              Welcome to Fotoscapes, where every story is a masterpiece waiting
              to be discovered.
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AboutUs;
