import { MOBILE_BREAK_POINT } from '../../../../utils/constants';

export const CAROUSEL_MOBILE = {
  breakpoint: MOBILE_BREAK_POINT,
  settings: {
    centerPadding: '0',
    variableWidth: false,
  },
};

const CAROUSEL_RESPONSIVE = [CAROUSEL_MOBILE];

export const SETTINGS = {
  infinite: false,
  lazyLoad: 'ondemand',
  dots: true,
  slidesToShow: 1,
  centerMode: true,
  slidesToScroll: 1,
  centerPadding: '80px',
  variableWidth: true,
  responsive: CAROUSEL_RESPONSIVE,
};

export const AUTOPLAY_TIMEOUT = 10000;
