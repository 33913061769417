import React from "react";
import "./SectionHeader.scss";

interface SectionHeaderProps {
  text: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ text }) => {
  return <h2 className="section-header">{text}</h2>;
};

export default SectionHeader;
