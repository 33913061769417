import { FeedQueryResult, LightboxRootObject } from './softbox-types.ts';
import { resolvedLanguage } from '../../i18n.ts';

const DEFAULT_ASPECT = '9:4';

const DEFAULT_CKEY = 'fb529d256155b9c6';

export const generateFetchFeedQuery = (schedule: string) => {
  return async ({lang = "en", hour = "1000"}) => {
    const language = resolvedLanguage(lang);
    const res = await fetch(
      `https://fotoscapes.com/wp/v1/daily?ckey=${DEFAULT_CKEY}&mp_lang=${language}&sched=${schedule}&previewAspect=${DEFAULT_ASPECT}`
    );
    const data: LightboxRootObject = await res.json();

    const resInterests = await fetch(
      `https://fotoscapes.com/wp/v1/interests?ckey=${DEFAULT_CKEY}&previewAspect=${DEFAULT_ASPECT}`
    );
    const interests = await resInterests.json();

    return {
      news: data,
      interests,
    } satisfies FeedQueryResult;
  };
};

export const fetchFeedQuery = async (params: {
  lang: string;
  hour: string;
}) => {
  //todo refactor to include consts from classic fotoscapes
  const res = await fetch(
    'https://fotoscapes.com/wp/v1/daily?ckey=fb529d256155b9c6&mp_lang=en&sched=dynamic-news&previewAspect=9:4'
  );
  const data: LightboxRootObject = await res.json();

  const resInterests = await fetch(
    'https://fotoscapes.com/wp/v1/interests?ckey=fb529d256155b9c6&previewAspect=9:4'
  );
  const interests = await resInterests.json();

  return {
    news: data,
    interests,
  } satisfies FeedQueryResult;
};
