import { AxiosResponse } from 'axios';

export const PRODUCTION_API_URL = 'https://fotoscapes.com';
export const SANDBOX_API_URL = 'https://staging.fotoscapes.com';
export const ORIGIN_SANDBOX_API_URL = 'https://origin.staging.fotoscapes.com';

export const getBaseUrl = () => {

  //client side
  const API_BASE_URL = window && window.__RUNTIME_CONFIG__ ? (window as any).__RUNTIME_CONFIG__.API_BASE_URL : undefined;
  if (API_BASE_URL) {
    return API_BASE_URL;
  }
  //server side
  if (process.env.API_BASE_URL) {
    return process.env.API_BASE_URL
  }

  // see comment in .env
  // if (import.meta.env.VITE_API_BASE_URL) {
  //   return import.meta.env.VITE_API_BASE_URL;
  // }

  // @ts-expect-error we can use globalThis only on server
  const host = globalThis.hostName || window?.location?.hostname || '';

  if (host === 'fotoscapes.com') {
    return PRODUCTION_API_URL;
  }
  if (host === 'staging.fotoscapes.com') {
    return SANDBOX_API_URL;
  }
  if (host.startsWith('fotoscapes-player-')) {
    return ORIGIN_SANDBOX_API_URL;
  }
  return PRODUCTION_API_URL;
};

export const getFullURL = (response: AxiosResponse) => {
  let url = response.config.baseURL || '';

  if (response?.config?.url) {
    url += response?.config?.url?.startsWith('/')
      ? response?.config?.url
      : `/${response?.config?.url}`;
  }

  return url;
};

export const getApiMetrics = (response: AxiosResponse, requestTime: number) => {
  const responseTime = new Date().getTime() - requestTime;

  return {
    responseTime: responseTime,
    url: getFullURL(response),
    responseCode: `${response.status}`,
  };
};
