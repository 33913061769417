interface ITimerConfig {
  onTrack: (msToEnd: number, presentsToEnd: number) => void;
  onPause: (isPaused: boolean) => void;
}

class Timer {
  private startTime: number = 0;
  private endTime: number = 0;
  private isPaused: boolean = false;
  private timerPeriod: number = 0;
  private interval: NodeJS.Timer | null = null;
  readonly onTrack;
  readonly onPause;

  public isReady = false;

  constructor({ onTrack, onPause }: ITimerConfig) {
    this.onTrack = onTrack;
    this.onPause = onPause;
  }

  private deleteInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  start(ms: number) {
    this.timerPeriod = ms;
    this.startTime = new Date().getTime();
    this.endTime = new Date().getTime() + ms;
    let endIn = 0;
    this.deleteInterval();
    this.isReady = true;

    this.interval = setInterval(() => {
      if (!this.isPaused) {
        endIn += 1000;
        const msToEnd = this.endTime - this.startTime - endIn;

        if (this.endTime - this.startTime - endIn <= 0) {
          if (this.interval) {
            endIn = 0;
            this.deleteInterval();
          }
        }

        if (this.onTrack) {
          this.onTrack(msToEnd, 100 - (msToEnd / ms) * 100);
        }
      }
    }, 1000);
  }

  reset() {
    this.stop();
    this.isPaused = false;
    this.start(this.timerPeriod);
  }

  stop() {
    this.isPaused = false;
    this.isReady = false;
    this.deleteInterval();
  }

  pause() {
    this.isPaused = true;

    if (this.onPause) {
      this.onPause(this.isPaused);
    }
  }

  unpauseTimer() {
    this.isPaused = false;

    if (this.onPause) {
      this.onPause(this.isPaused);
    }
  }
}

export default Timer;
