import { FC } from 'react';

import Image from '../../../../../../../../components/Image/Image';
import { ICoverCardProps } from './interfaces';
import FotoscapeLink from '../../../../../../../../components/FotoscapeLink/FotoscapeLink';
import { LOOKBOOK_ROUTE } from '../../../../../../router/router';
import { getWithOutSpecialChars } from '../../../../../../utils';
import TextParser from '../../../../../../components/TextParser/TextParser';

import './index.scss';

const CoverCard: FC<ICoverCardProps> = ({
  imageList,
  interestName,
  title,
  lookbookUID,
  description,
}) => {
  return (
    <FotoscapeLink
      className="home-cover-card card-animation"
      to={LOOKBOOK_ROUTE.path
        ?.replace(':id', lookbookUID)
        .replace(':title?', getWithOutSpecialChars(title))}
    >
      <div className="home-cover-card__image-wrapper card-animation__image-wapper">
        <Image
          alt="lookbook cover card"
          className="home-cover-card__image card-animation__image"
          imageList={imageList}
        />
      </div>

      <div className="home-cover-card__main">
        <div className="home-cover-card__interest-name">{interestName}</div>
        <div className="home-cover-card__title card-animation__text">
          <TextParser
            text={title}
            option={{
              paragraphClass: 'home-cover-card__title card-animation__text',
            }}
          />
        </div>
        <div className="home-cover-card__description card-animation__text">
          <TextParser text={description} />
        </div>
      </div>
    </FotoscapeLink>
  );
};

export default CoverCard;
