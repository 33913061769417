import { createContext } from 'react';
import { IInterest } from '../pages/Root/services/api/interest/interfaces.ts';
import {
  IDailyLookBook,
  IDailyLookBookResponce,
  ILookBook,
  IRecircLookBook,
} from '../pages/Root/services/api/lookBook/interfaces.ts';
import { IAdTag } from '../pages/Root/services/api/ads/interfaces.ts';

export interface IHomePageData {
  heroSchedule: IDailyLookBookResponce;
  interestSchedule: IDailyLookBookResponce;
  latestSchedule: IDailyLookBookResponce;
  popularSchedule: IDailyLookBookResponce;
  adScriptList: IAdTag[];
}

export interface ICommonData {
  interests?: Map<string, IInterest>;
  defaultAdData?: IAdTag;
}

export interface IStoriesData {
  storyList: IDailyLookBook[];
}

export interface IStoryData {
  popularList: IDailyLookBook[];
  travelList: IDailyLookBook[];
  interests: Map<string, IInterest>;
  lookBook: ILookBook | null;
}

interface IGamesData {
  gameList: IDailyLookBook[];
}

interface ILookbookPageData {
  lookbook?: ILookBook | null;
  recirc: IRecircLookBook[];
  lookbookRecurcList: IDailyLookBook[];
  adScriptList?: IAdTag[];
}

interface ICategoryData {
  categoryList: Record<string, IDailyLookBook[]>;
}

interface INotFoundData {
  latestSchedule?: IDailyLookBook[];
}

export interface IServerDataContext {
  homeData?: IHomePageData;
  lookbookData?: ILookbookPageData;
  storiesData?: IStoriesData;
  storyData?: IStoryData;
  gamesData?: IGamesData;
  categoryData?: ICategoryData;
  notFoundData?: INotFoundData;
  commonData?: ICommonData;
}

export const ServerDataContext = createContext<IServerDataContext>({});
