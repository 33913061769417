export const getRecircUrl = () => {
  if (
    typeof window !== 'undefined' &&
    window &&
    window?.PlayerConfiguration?.recirc
  ) {
    const { apiURL } = window.PlayerConfiguration.recirc;

    if (apiURL) {
      return apiURL;
    }
  }
  return 'wp/v1/recirc';
};
