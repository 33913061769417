import { IAdMapData } from '../../interfaces';

export const INTEREST_HEIGHT = 830;

export const MOBILE_INTEREST_HEIGHT = 630;

export const CATEGORY_DESKTOP_AD_MAP_DATA: IAdMapData = {
  screenSize: [1193, 0],
  adSizeList: [[300, 250]],
};

export const CATEGORY_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [730, 0],
  adSizeList: [
    [728, 90],
    [300, 50],
    [320, 50],
  ],
};

export const CATEGORY_SMALL_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [684, 0],
  adSizeList: [
    [300, 50],
    [320, 50],
  ],
};

export const CATEGORY_MOBILE_AD_MAP_DATA: IAdMapData = {
  screenSize: [320, 0],
  adSizeList: [
    [300, 250],
    [300, 50],
    [320, 50],
  ],
};
