import { ChangeEvent, FC, useCallback, useState } from 'react';

import { IInputProps } from './interface';

import './index.scss';

const Input: FC<IInputProps> = ({
  defaultValue = '',
  type = 'text',
  placeholder,
  required,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const onInputChange = useCallback(
    ({
      target: {
        value,
        validity: { valid },
      },
    }: ChangeEvent<HTMLInputElement>) => {
      setValue(value);

      if (onChange) {
        onChange(value, valid);
      }
    },
    [onChange]
  );

  return (
    <input
      data-testid="input-el"
      className="input paragraph--s"
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
      onChange={onInputChange}
    />
  );
};

export default Input;
