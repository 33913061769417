import { FC } from 'react';

import FotoscapeLink from '../../../../components/FotoscapeLink/FotoscapeLink';

import './index.scss';

const ContactUs: FC = () => {
  return (
    <div className="legal-page contact-us">
      <div className="contact-us__container">
        <h1 className="contact-us__title headline--xl">Contact Us</h1>
        <h2 className="contact-us__subtitle headline--s">
          Questions? We are here to help.
        </h2>
        <main>
          <div className="contact-us__description paragraph--m">
            If you need to get in touch, email us at{' '}
            <FotoscapeLink
              className="contact-us__link"
              to="mailto:support@fotoscapes.com"
            >
              support@fotoscapes.com
            </FotoscapeLink>
            . We’re open 8am - 5pm EST Monday-Friday.
          </div>
        </main>
      </div>
    </div>
  );
};

export default ContactUs;
