import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { IFeedbackProps } from './interfaces';
import Input from '../../../../../../../../components/Form/Input/Input';
import Label from '../../../../../../../../components/Form/Label/Label';

import './index.scss';

const FeedbackForm: FC<IFeedbackProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFeedbackValid, setIsFeedbackValid] = useState(false);

  const onFormSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit({
        email,
        feedback,
      });
    },
    [email, feedback, onSubmit]
  );

  const onEmailChange = useCallback(
    (value: string, isValid: boolean) => {
      setIsEmailValid(isValid);
      setEmail(value);
    },
    [setEmail]
  );

  const onFeedbackChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setIsFeedbackValid(e.target.checkValidity());
      setFeedback(e.target.value);
    },
    []
  );

  const isValid = useMemo(() => {
    return isEmailValid && isFeedbackValid;
  }, [isEmailValid, isFeedbackValid]);

  return (
    <form onSubmit={onFormSubmit} className="feedback-form">
      <Label text={t('emailAddress')}>
        <Input
          type="email"
          placeholder={t('enterYourEmail')}
          required={true}
          defaultValue={email}
          onChange={onEmailChange}
        />
      </Label>

      <Label text={t('feedback')}>
        <textarea
          className="feedback-form__element feedback-form__element-area"
          rows={5}
          required={true}
          value={feedback}
          onChange={onFeedbackChange}
        />
      </Label>

      <button className="button button--large" disabled={!isValid}>
        {t('submit')}
      </button>
    </form>
  );
};

export default FeedbackForm;
