import { IAdMapData } from '../../interfaces';

export const LATEST_DESKTOP_AD_MAP_DATA: IAdMapData = {
  screenSize: [1193, 0],
  adSizeList: [[300, 250]],
};

export const LATEST_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [730, 0],
  adSizeList: [
    [728, 90],
    [300, 50],
    [320, 50],
  ],
};

export const LATEST_SMALL_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [684, 0],
  adSizeList: [
    [300, 50],
    [320, 50],
  ],
};

export const LATEST_MOBILE_AD_MAP_DATA: IAdMapData = {
  screenSize: [320, 0],
  adSizeList: [
    [300, 250],
    [300, 50],
    [320, 50],
  ],
};

export const TRAVEL_DESKTOP_AD_MAP_DATA: IAdMapData = {
  screenSize: [1193, 0],
  adSizeList: [
    [970, 250],
    [970, 90],
    [728, 90],
  ],
};

export const TRAVEL_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [730, 0],
  adSizeList: [
    [728, 90],
    [300, 50],
    [320, 50],
  ],
};

export const TRAVEL_SMALL_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [684, 0],
  adSizeList: [
    [300, 50],
    [320, 50],
  ],
};

export const TRAVEL_MOBILE_AD_MAP_DATA: IAdMapData = {
  screenSize: [320, 0],
  adSizeList: [
    [300, 250],
    [300, 50],
    [320, 50],
  ],
};
