import { createContext } from 'react';

import { IInterest } from '../../services/api/interest/interfaces';

interface IHomeContext {
  interestMap: Map<string, IInterest>;
  setInterestMap: (list: Map<string, IInterest>) => void;
}
export const InterestContext = createContext<IHomeContext>({
  interestMap: new Map<string, IInterest>(),
  setInterestMap: () => {},
});
