import { Component, PropsWithChildren } from 'react';

import FotoscapeLink from '../FotoscapeLink/FotoscapeLink';
import { IErrorBoundaryState } from './interfaces';
import { HOMEPAGE_ROUTE } from '../../pages/Root/router/router';

import './index.scss';

class ErrorBoundary extends Component<PropsWithChildren, IErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  onClick() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div className="error-boundary__container">
            <h1 className="error-boundary__title headline--xl">
              Oops! Something went wrong
            </h1>

            <FotoscapeLink
              to={HOMEPAGE_ROUTE.path}
              onClick={this.onClick}
              className="button"
            >
              Back to Home
            </FotoscapeLink>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
