import { FC } from 'react';

import Image from '../../../../../../../../components/Image/Image';
import { RecircCardProps } from './interfaces';

import './index.scss';

const RecircCard: FC<RecircCardProps> = ({
  logo,
  imgList,
  title,
  interestName,
  onClick,
}) => {
  return (
    <div className="recirc-card" onClick={onClick}>
      <Image
        className="recirc-card__image"
        alt="lookbook"
        imageList={imgList}
      />
      <div className="recirc-card__content">
        <Image className="recirc-card__logo" alt="logo" src={logo} />
        <div className="recirc-card__title headline--xxs">{title}</div>
        <div className="recirc-card__owner label--s">{interestName}</div>
      </div>
    </div>
  );
};

export default RecircCard;
