export const STORE_LAST_MONTH_USER_VISITS = 'lastMonthUserVisits';
export const IS_APP_DOWNLOADED = 'isAppDownloaded';
export const STORE_IS_REGISTRED = 'isRegistred';
export const FACEBOOK_LINK = 'https://www.facebook.com/discoverfotoscapes/';
export const VISITS_DURING_DECLINE_SUBSCRIPTION =
  'visitsDuringDeclineSubscription';

export enum eventTypes {
  clickInternal = 'click_internal',
  clickExternal = 'click_external',
  doNotSellView = 'do-not-sell-view',
  ccpaClick = 'ccpa-click',
  swipe = 'swipe',
  dtPageview = 'dt_pageview',
  manageMyDataView = 'manage-my-data-view',
  play = 'play',
  pause = 'pause',
  dtTab = 'dt_tap',
  dtScroll = 'dt_scroll',
}
