import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ICoverLookProps } from './interfaces';
import SingleLook from '../SingleLook/SingleLook';
import Icon from '../../../../components/Icon/Icon.tsx';
import { IconTypes } from '../../../../components/Icon/constants.ts';

import './index.scss';

const CoverLook: FC<ICoverLookProps> = ({
  title,
  description,
  ownedBy,
  credit,
  interestName,
  logoURL,
  classList,
  averageColor = '#000',
  images,
  kenBurnsParams,
  hasReadMe,
  animationDirection,
  isActive,
  isIgnoreParagraphInDescription,
  isStopAnimation,
  ad,
  doNotTrack,
  language,
  onReadmeClick,
  onClick,
  onScroll,
}) => {
  const { t } = useTranslation();

  const coverClassList = useMemo(() => {
    const list = ['cover-look'];

    if (classList) {
      list.push(classList);
    }

    return list.join(' ');
  }, [classList]);

  const isShowReadmeButton = useMemo(() => {
    return hasReadMe && isActive;
  }, [isActive, hasReadMe]);

  return (
    <div className={coverClassList} onClick={onClick}>
      <SingleLook
        pageIndex={0}
        ad={ad}
        doNotTrack={doNotTrack}
        title={title}
        description={description}
        interestName={interestName}
        ownedBy={ownedBy}
        credit={credit}
        logoURL={logoURL}
        averageColor={averageColor}
        images={images}
        animationDirection={animationDirection}
        isActive={isActive}
        kenBurnsParams={kenBurnsParams}
        isIgnoreParagraphInDescription={isIgnoreParagraphInDescription}
        isStopAnimation={isStopAnimation}
        onScroll={onScroll}
      >
        {isShowReadmeButton ? (
          <button
            className="cover-look__button label--l"
            onClick={onReadmeClick}
          >
            {t('readTheStory', {
              lng: language,
              defaultValue: '',
            })}
            <Icon type={IconTypes.arrowRight} />
          </button>
        ) : null}
      </SingleLook>
    </div>
  );
};

export default CoverLook;
