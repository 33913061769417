import { useEffect, useRef, useState } from "react";
import { getBestImage } from "../utils/image-util.ts";
import { IImageData } from '../../pages/Root/services/api/interfaces.ts';

export const useBestImage = (images: IImageData[], sizeHint: "large" | "small") => {
  const imageRef = useRef<HTMLImageElement>(null);
  let bestSrc = ""
  if (images.length) {
    const src = getBestImage(images, sizeHint);
    bestSrc = src;
  }
  return { bestSrc, imageRef };
};
