import { getCookieByName } from '../../../../utils/cookie';
import { IEmailLinkData } from './interfaces';

export const getEmailLink = (linkData: IEmailLinkData) => {
  const deveiceId = getCookieByName('mp_mpid');
  const { emailTo, subject, body } = linkData;

  return `mailto:${emailTo}?subject=${subject}&body=${body
    .replace(':deviceId', deveiceId)
    .replace(':origin', window.location.origin)}`;
};
