import { FC } from 'react';

import InterestCardListSkeleton from './components/InterestCardListSkeleton/InterestCardListSkeleton';
import TitleSkeleton from '../../../../../../components/skeletons/TitleSkeleton/TitleSkeleton';
import SkeletonText from '../../../../../../components/skeletons/SkeletonText/SkeletonText';

import './index.scss';

const InterestsSkeleton: FC = () => {
  return (
    <div className="interest-skeleton">
      <TitleSkeleton />
      <SkeletonText isLarge />
      <SkeletonText isLarge />

      <InterestCardListSkeleton />
    </div>
  );
};

export default InterestsSkeleton;
