import { FC } from 'react';

import StoriesSkeletonCard from '../StoriesSkeletonCard/StoriesSkeletonCard';

import './index.scss';

const StoriesSkeletonList: FC = () => {
  return (
    <div className="stories-skeleton-list">
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
      <StoriesSkeletonCard />
    </div>
  );
};

export default StoriesSkeletonList;
