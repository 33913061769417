import {
  AnimationDirection,
  IKenBurnsAnimation,
  LastAnimationUsed,
} from './interfaces';
import { IImageData, IKenBurnsParam } from '../services/api/interfaces';

export const DEFAULT_ANIMATION_TIME = 10000;

export class ElAnimation {
  imageElement: HTMLImageElement;
  image: IImageData;
  container: HTMLDivElement;
  kenBurnsParams: Record<string, IKenBurnsParam>;
  lastAnimationUsed: LastAnimationUsed;
  kenBurnsAnimationStart?: IKenBurnsAnimation;
  kenBurnsAnimationEnd?: IKenBurnsAnimation;
  frameHeight: number;
  frameWidth: number;
  imageElementAnimation?: Animation;
  isAnimationDone = false;

  constructor(
    imageElement: HTMLImageElement,
    image: IImageData,
    container: HTMLDivElement,
    kenBurnsParams: Record<string, IKenBurnsParam>
  ) {
    this.imageElement = imageElement;
    this.image = image;
    this.kenBurnsParams = kenBurnsParams;
    this.lastAnimationUsed = LastAnimationUsed.start;
    this.container = container;
    this.frameHeight = 0;
    this.frameWidth = 0;
  }

  calculateKbRatio(key: string) {
    const parts = key.split(':');
    if (parts.length === 2) {
      const denom = parseFloat(parts[1]);
      if (denom === 0.0) {
        return 0.0;
      }
      return parseFloat(parts[0]) / denom;
    }
    return parseFloat(key);
  }

  calculateBackgroundImageAnimation(
    image: IImageData,
    kenBurnsParams: Record<string, IKenBurnsParam>,
    atStart: boolean,
    frameWidth: number,
    frameHeight: number
  ): IKenBurnsAnimation {
    const aspectRatio = frameWidth / frameHeight;
    const DEFAULT_CHOSEN = 100000.0;

    let chosenRation: string = '';
    let chosenRationValue = DEFAULT_CHOSEN;

    for (const key in kenBurnsParams) {
      const ratio = this.calculateKbRatio(key);
      if (ratio !== 0.0) {
        const newValue = Math.abs(1.0 - aspectRatio / ratio);
        if (newValue < chosenRationValue) {
          chosenRationValue = newValue;
          chosenRation = key;
        }
      }
    }

    const chosenParams = kenBurnsParams[chosenRation];

    const imageWidth = image.width;
    const imageHeight = image.height;

    let cx, cy, initialBreadth;
    if (atStart) {
      cx = chosenParams.startX * imageWidth;
      cy = chosenParams.startY * imageHeight;
      initialBreadth = chosenParams.startB * imageWidth;
    } else {
      cx = chosenParams.endX * imageWidth;
      cy = chosenParams.endY * imageHeight;
      initialBreadth = chosenParams.endB * imageWidth;
    }

    // This is the natural scale that scales the frame to size of the image.
    const frameScale = Math.min(
      imageHeight / frameHeight,
      imageWidth / frameWidth
    );

    // Make sure the breadth can stay within the view. We start with
    // initialBreadth which is what we want. Then we make sure we are
    // not larger than the image's width or height. Finally, we make
    // sure we are not greater than the dimension of the frame scaled up
    // to fit just inside the image.
    const breadth = Math.min(
      initialBreadth,
      imageWidth,
      imageHeight,
      frameScale * frameWidth,
      frameScale * frameHeight
    );

    // With the breadth we can now calculate the scale we will use.
    const scale = Math.min(frameHeight / breadth, frameWidth / breadth);

    // These are the sizes of the frame scaled to the full image.
    const mappedWidth = frameWidth / scale;
    const mappedHeight = frameHeight / scale;

    // Get us to upper left again.
    let left = -(((1 - scale) * imageWidth) / 2 / scale);
    let top = -(((1 - scale) * imageHeight) / 2 / scale);

    // Now factor in where we want to be.
    left -= Math.max(
      0,
      Math.min(cx - mappedWidth / 2, imageWidth - mappedWidth)
    );
    top -= Math.max(
      0,
      Math.min(cy - mappedHeight / 2, imageHeight - mappedHeight)
    );

    const result: IKenBurnsAnimation = {
      transitionProperty: 'transform',
      transform:
        'scale(' + scale + ') translate(' + left + 'px, ' + top + 'px)',
      transitionDuration: 0,
    };
    if (atStart) {
      result.transitionDuration = 0;
    } else {
      result.transitionDuration = DEFAULT_ANIMATION_TIME;
    }

    return result;
  }

  pauseAnimation() {
    this.imageElementAnimation?.pause();
  }

  unpauseAnimation() {
    if (!this.isAnimationDone) {
      this.imageElementAnimation?.play();
    }
  }

  updateImageTransition({
    transitionProperty,
    transform,
    transitionDuration,
  }: IKenBurnsAnimation) {
    this.imageElementAnimation = this.imageElement.animate(
      [{ [transitionProperty]: transform }],
      {
        duration: transitionDuration,
        iterations: 1,
        fill: 'forwards',
      }
    );
    this.isAnimationDone = false;

    this.imageElementAnimation.finished.then((status) => {
      this.isAnimationDone = status.playState === 'finished';
    });
  }

  setLastAnimationUsed(animationUsed: LastAnimationUsed) {
    this.lastAnimationUsed = animationUsed;
  }

  initContainerSizes() {
    this.frameHeight = this.container.offsetHeight;
    this.frameWidth = this.container.offsetWidth;
  }

  setInitConfig() {
    this.initContainerSizes();

    const { image, kenBurnsParams, frameWidth, frameHeight } = this;
    this.kenBurnsAnimationStart = this.calculateBackgroundImageAnimation(
      image,
      kenBurnsParams,
      true,
      frameWidth,
      frameHeight
    );
    this.kenBurnsAnimationEnd = this.calculateBackgroundImageAnimation(
      image,
      kenBurnsParams,
      false,
      frameWidth,
      frameHeight
    );

    this.updateImageTransition(this.kenBurnsAnimationStart);
  }

  runAnimation(
    isAnimationActive: boolean,
    animationDirection: AnimationDirection
  ) {
    const { kenBurnsAnimationStart, kenBurnsAnimationEnd, lastAnimationUsed } =
      this;

    if (kenBurnsAnimationStart && kenBurnsAnimationEnd && isAnimationActive) {
      if (animationDirection === AnimationDirection.left) {
        if (lastAnimationUsed === LastAnimationUsed.start) {
          this.updateImageTransition(kenBurnsAnimationEnd);
          this.setLastAnimationUsed(LastAnimationUsed.end);
        } else {
          this.updateImageTransition({
            ...kenBurnsAnimationStart,
            transitionDuration: DEFAULT_ANIMATION_TIME,
          });
          this.setLastAnimationUsed(LastAnimationUsed.start);
        }
      } else if (lastAnimationUsed === LastAnimationUsed.start) {
        this.updateImageTransition(kenBurnsAnimationEnd);
        this.setLastAnimationUsed(LastAnimationUsed.end);
      } else {
        this.updateImageTransition({
          ...kenBurnsAnimationStart,
          transitionDuration: DEFAULT_ANIMATION_TIME,
        });
        this.setLastAnimationUsed(LastAnimationUsed.start);
      }
    }
  }
}
