import { FC } from 'react';

import SkeletonText from '../../../../../../components/skeletons/SkeletonText/SkeletonText';
import SkeletonImage from '../../../../../../components/skeletons/SkeletonImage/SkeletonImage';

import './index.scss';

const StorySkeletonMainLook: FC = () => {
  return (
    <div className="story-skeleton-main-look">
      <SkeletonText isWide isLarge />
      <SkeletonImage />
      <SkeletonText isWide isLarge />
      <SkeletonText isWide />
      <SkeletonText isWide />
      <SkeletonText isWide />
      <SkeletonText isWide />
    </div>
  );
};

export default StorySkeletonMainLook;
