// src/hooks/useViewportWidth.ts
import { useEffect, useState } from "react";

export const useViewportWidth = (): number | undefined => {
  const [width, setWidth] = useState<number | null>(
    import.meta.env.SSR ? null : window.innerWidth
  );

  useEffect(() => {
    if (import.meta.env.SSR) {return;}

    const handleResize = () => {return setWidth(window.innerWidth)};
    window.addEventListener("resize", handleResize);
    return () => {return window.removeEventListener("resize", handleResize)};
  }, []);

  return width;
};
