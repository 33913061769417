import { FC } from 'react';

import StorySkeletonMayLikeList from '../StorySkeletonMayLikeList/StorySkeletonMayLikeList';
import SkeletonText from '../../../../../../components/skeletons/SkeletonText/SkeletonText';

const StorySkeletonMayLike: FC = () => {
  return (
    <div className="story-skeleton-may-like">
      <SkeletonText isWide isLarge />
      <StorySkeletonMayLikeList />
    </div>
  );
};

export default StorySkeletonMayLike;
