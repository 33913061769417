import { FC, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getAvailableInterestList } from '../../utils';
import Footer from '../../../../../../components/Footer/Footer';
import InterestGallery from '../../../../components/InterestGallery/InterestGallery';
import InterestsSkeleton from './components/InterestsSkeleton/InterestsSkeleton';
import { InterestContext } from '../../../../ContextWrapper/WithInterest/InterestContext.ts';

import './index.scss';

const Interests: FC = () => {
  const { t } = useTranslation();
  const { interestMap } = useContext(InterestContext);

  const interestList = useMemo(() => {
    return getAvailableInterestList(interestMap);
  }, [interestMap]);

  const renderHeader = () => {
    return (
      <>
        <h1 className="interests__title f-headline--xxxxxl">
          {t('interestTitle')}
        </h1>
        <h2 className="interests__subtitle paragraph--l">
          <Trans i18nKey="interestspageSubTitle">
            From top stories to stay informed,
            <div>to beautiful photo galleries to inspire you.</div>
          </Trans>
        </h2>
      </>
    );
  };

  return (
    <div className="interests">
      {interestList.length ? (
        <InterestGallery
          renderHeader={renderHeader}
          interestList={interestList}
        />
      ) : (
        <InterestsSkeleton />
      )}
      <Footer />
    </div>
  );
};

export default Interests;
