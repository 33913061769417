import { FC, useEffect, useRef, useState } from 'react';
import Mailchimp from 'react-mailchimp-form';
import { Trans, useTranslation } from 'react-i18next';

import FotoscapeLink from '../FotoscapeLink/FotoscapeLink';
import Image from '../Image/Image';
import {
  FOMR_SUCCESS_TEXT,
  SECTION_LINK_LIST_ABOUT,
  SECTION_LINK_LIST_LEGAL,
  SECTION_LINK_LIST_RECOMENDATION,
  SECTION_LINK_LIST_SOCIAL,
} from './constants';
import fotoSCAPEImg from '../../assets/images/FotoSCAPE.svg';
import { PRIVACY_POLICY_LINK } from '../../pages/Root/utils/constants';
import Icon from '../Icon/Icon.tsx';

import './index.scss';

const Footer: FC = () => {
  const formWrapperRef = useRef<any>(null);
  const { t } = useTranslation();
  const [isFormSubmitedSuccessfully, setIsFormSubmitedSuccessfully] =
    useState(false);

  const onClick = () => {
    window.scroll({ top: 0 });
  };

  useEffect(() => {
    const formDomEl = formWrapperRef.current?.querySelector('.footer__form');
    const observer = new MutationObserver(function (e: any) {
      const alertEl = e[0].target.querySelector('.msg-alert');

      if (alertEl.textContent === FOMR_SUCCESS_TEXT) {
        setIsFormSubmitedSuccessfully(true);
      }
    });
    observer.observe(formDomEl, { childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="footer" id="footer">
      <div className="footer__header">
        <div className="footer__header-content">
          {isFormSubmitedSuccessfully ? (
            <div className="footer__form-succeded">
              <div className="form-succeded--title headline--l">
                {t('thanksForSigningUp')}
              </div>
              <div className="form-succeded--subtitle paragraph--m">
                {t('moreInspiringPhotos')}
              </div>
            </div>
          ) : (
            <>
              <div className="footer__header-title headline--l">
                {t('curatedCollectionsDeliveredtoYourInbox')}
              </div>
              <div className="footer__header-description paragraph--m">
                {t('discoverMoreInspiringPhotos')}
              </div>
              <div className="footer__form-wrapper" ref={formWrapperRef}>
                {/* No way add the label thew component API */}
                <div className="footer__label">{t('emailAddress')}</div>
                <Mailchimp
                  action="https://fotoscapes.us11.list-manage.com/subscribe/post?u=bd5eb2f5f37456beacbcddaa4&amp;id=ae347ad917&amp;f_id=002aa4e0f0"
                  className="footer__form"
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: t('enterYourEmail'),
                      type: 'email',
                    },
                  ]}
                  messages={{
                    button: t('signUp'),
                    success: FOMR_SUCCESS_TEXT,
                  }}
                />
              </div>
            </>
          )}

          {!isFormSubmitedSuccessfully ? (
            <div className="footer__terms-text paragraph--xs">
              <Trans i18nKey="footerTerms">
                Yes! I’d like to receive occasional communications from
                Fotoscapes. By submitting this form, I agree to this
                <FotoscapeLink className="link" to={PRIVACY_POLICY_LINK}>
                  privacy policy
                </FotoscapeLink>
              </Trans>
            </div>
          ) : null}
        </div>
      </div>

      <div className="footer__main">
        <div className="footer__main-container">
          <div className="footer__section">
            <Image className="footer__logo" src={fotoSCAPEImg} alt="logo" />
            <div className="footer__description label--s">
              {t('everydayLifestyle')}
            </div>
            <div className="footer__follow headline--xs">{t('followUs')}</div>

            <div className="footer__social">
              {SECTION_LINK_LIST_SOCIAL.map(({ icon, link, text }) => {
                return (
                  <FotoscapeLink
                    className="footer__social-link"
                    key={link}
                    to={link}
                    aria-label={text}
                  >
                    <Icon type={icon} />
                  </FotoscapeLink>
                );
              })}
            </div>
            <div className="footer__text footer__text--tabled footer__text--gray label--s">
              © 2024
            </div>
          </div>

          <div className="footer__section">
            {SECTION_LINK_LIST_RECOMENDATION.map(({ text, link }) => {
              return (
                <FotoscapeLink
                  key={link}
                  className="footer__text footer__text--uppercase footer__text--gray label--m"
                  to={link}
                  onClick={onClick}
                >
                  {t(text)}
                </FotoscapeLink>
              );
            })}
          </div>

          <div className="footer__section">
            {SECTION_LINK_LIST_ABOUT.map(({ text, link }) => {
              return (
                <FotoscapeLink
                  key={link}
                  className="footer__text label--s"
                  to={link}
                >
                  {t(text)}
                </FotoscapeLink>
              );
            })}
          </div>

          <div className="footer__section">
            {SECTION_LINK_LIST_ABOUT.map(({ text, link }) => {
              return (
                <FotoscapeLink
                  key={link}
                  className="footer__text footer__text--mobile label--s"
                  to={link}
                  onClick={onClick}
                >
                  {t(text)}
                </FotoscapeLink>
              );
            })}

            {SECTION_LINK_LIST_LEGAL.map(({ text, link }) => {
              return (
                <FotoscapeLink
                  key={link}
                  className="footer__text label--s"
                  to={link}
                  onClick={onClick}
                >
                  {t(text)}
                </FotoscapeLink>
              );
            })}
          </div>
          <div className="footer__text footer__text--copyright footer__text--mobile footer__text--gray label--s">
            © 2024
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
