import { FC } from 'react';

import SkeletonImage from '../../../../../../components/skeletons/SkeletonImage/SkeletonImage';
import SkeletonText from '../../../../../../components/skeletons/SkeletonText/SkeletonText';

import './index.scss';

const LookbookSkeletonCard: FC = () => {
  return (
    <div className="lookbook-skeleton-card">
      <SkeletonImage />
      <SkeletonText isLarge />
      <SkeletonText isLarge isWide />
      <SkeletonText isWide />
      <SkeletonText isWide />
      <SkeletonText isWide />
      <SkeletonText isWide />
    </div>
  );
};

export default LookbookSkeletonCard;
