import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Image from '../../../../components/Image/Image';
import { SingleLookProps } from './interfaces';
import { IImageData } from '../../services/api/interfaces';
import { findImage } from '../../utils';
import { ElAnimation } from '../../utils/elAnimation';
import { setAdToBlock } from '../../utils/ads';
import TextParser from '../TextParser/TextParser';
import Icon from '../../../../components/Icon/Icon.tsx';
import { IconTypes } from '../../../../components/Icon/constants.ts';

import './index.scss';

const SingleLook: FC<SingleLookProps> = ({
  pageIndex,
  title,
  description,
  interestName,
  ownedBy,
  logoURL,
  credit,
  images,
  averageColor,
  children,
  kenBurnsParams,
  isActive,
  animationDirection,
  onScroll,
  isStopAnimation,
  ad,
  doNotTrack,
  isIgnoreParagraphInDescription,
}: SingleLookProps) => {
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source');
  const [imageData, setImageData] = useState<IImageData>();
  const [animationInstance, setAnimationInstance] =
    useState<ElAnimation | null>();
  const [isScrollIndicatorActive, setIsScrollIndicatorActive] = useState(false);
  const [isScrollIndicatorVisible, setIsScrollIndicatorVisible] =
    useState(true);
  const [lookItemContainerDisplayFlex, setLookItemContainerDisplayFlex] =
    useState(false);

  const imageLookWrapperRef = useRef<HTMLDivElement>(null);
  const lookItemContainerRef = useRef<HTMLDivElement>(null);
  const [imageLookEl, setImageLookEl] = useState<HTMLImageElement>();

  const lookItemContainerList = useMemo(() => {
    const baseClassList = 'single-look-item';
    if (lookItemContainerDisplayFlex) {
      return baseClassList + ' display-flex';
    }
    return baseClassList;
  }, [lookItemContainerDisplayFlex]);

  const logoClassList = useMemo(() => {
    const baseClassList = 'single-look-item__logo';
    if (isActive) {
      return baseClassList + ' fade-in';
    }
    return baseClassList;
  }, [isActive]);

  const imgURL = useMemo(() => {
    return imageData?.url || imageData?.link || '';
  }, [imageData]);

  const imageContainerClassList = useMemo(() => {
    const baseClassList = ['single-look-item__image-container'];

    if (isActive && title) {
      baseClassList.push('from-black-to-transparent');
    }

    return baseClassList.join(' ');
  }, [isActive, title]);

  const titleClassList = useMemo(() => {
    const baseClassList = 'single-look-item__title headline--s';
    if (isActive) {
      return baseClassList + ' appear-from-bottom';
    }
    return baseClassList;
  }, [isActive]);

  const descriptionClassList = useMemo(() => {
    const baseClassList = 'single-look-item__description paragraph--s';
    if (isActive) {
      return baseClassList + ' appear-from-bottom';
    }
    return baseClassList;
  }, [isActive]);

  const creditClassList = useMemo(() => {
    const baseClassList = 'single-look-item__credit paragraph--xs';
    if (isActive) {
      return baseClassList + ' appear-from-bottom';
    }
    return baseClassList;
  }, [isActive]);

  const scrollIndicatorContainerClassList = useMemo(() => {
    let baseClassList = 'single-look-item__scroll-indicator-container';
    if (isScrollIndicatorActive) {
      baseClassList += ' single-look-item__scroll-indicator-container--flex';
    }
    if (isActive) {
      baseClassList += ' appear-from-bottom';
    }
    return baseClassList;
  }, [isActive, isScrollIndicatorActive]);

  const scrollIndicatorClassList = useMemo(() => {
    let baseClassList = 'single-look-item__scroll-indicator';
    if (!isScrollIndicatorVisible) {
      baseClassList += ' hidden';
    }
    return baseClassList;
  }, [isScrollIndicatorVisible]);

  const isShouldRenderTextContainer = useMemo(() => {
    return !!title || !!description || !!credit;
  }, [title, description, credit]);

  useEffect(() => {
    if (imageLookWrapperRef.current) {
      const dimensions = imageLookWrapperRef.current.getBoundingClientRect();
      setImageData(findImage(images, dimensions.width, dimensions.height));
    }
  }, [imageLookWrapperRef, images]);

  useEffect(() => {
    if (imageLookEl && imageLookWrapperRef.current && imageData) {
      const dimensions = imageLookWrapperRef.current.getBoundingClientRect();
      if (!(dimensions.width && dimensions.height)) {
        // This should not happen but it breaks rendering.
        return;
      }

      if (kenBurnsParams && !animationInstance) {
        const animation = new ElAnimation(
          imageLookEl,
          imageData,
          imageLookWrapperRef.current,
          kenBurnsParams
        );

        animation.setInitConfig();

        setAnimationInstance(animation);
      }
    }
  }, [imageData, images, kenBurnsParams, imageLookEl, animationInstance]);

  useEffect(() => {
    if (isStopAnimation) {
      animationInstance?.pauseAnimation();
    } else {
      animationInstance?.unpauseAnimation();
    }
  }, [isStopAnimation, animationInstance]);

  useEffect(() => {
    return () => {
      setAnimationInstance(null);
    };
  }, []);

  useEffect(() => {
    if (lookItemContainerRef.current && !lookItemContainerDisplayFlex) {
      const lookItemContainerElement = lookItemContainerRef.current;

      if (
        lookItemContainerElement.scrollHeight >
        lookItemContainerElement.offsetHeight
      ) {
        setIsScrollIndicatorActive(true);
      } else {
        setIsScrollIndicatorActive(false);
      }

      // TODO: Remove listener on destroy
      lookItemContainerElement.addEventListener('scroll', () => {
        const newY = lookItemContainerElement.scrollTop;

        if (newY !== 0 && isScrollIndicatorVisible) {
          setIsScrollIndicatorVisible(false);

          if (onScroll) {
            onScroll(true);
          }
        } else if (newY === 0) {
          setIsScrollIndicatorVisible(true);
          if (onScroll) {
            onScroll(false);
          }
        }
      });
      setLookItemContainerDisplayFlex(true);
    }
  }, [
    lookItemContainerRef,
    isScrollIndicatorVisible,
    lookItemContainerDisplayFlex,
    onScroll,
  ]);

  useEffect(() => {
    if (animationInstance && animationDirection) {
      animationInstance.runAnimation(isActive, animationDirection);
    }
  }, [animationDirection, animationInstance, isActive]);

  useEffect(() => {
    if (
      window.googletag &&
      window.gptadslots &&
      isActive &&
      !doNotTrack &&
      ad?.adUnitId &&
      ad?.adUnitPath &&
      ad?.isInitAdConfigSetUp
    ) {
      setAdToBlock({
        pageIndex: pageIndex + 1,
        adUnitPath: ad.adUnitPath,
        adUnitSize: ad.adUnitSize,
        adUnitId: ad.adUnitId,
        iabCategoryList: ad.iabCategoryList,
      });
    }
  }, [
    isActive,
    ad?.adUnitId,
    ad?.adUnitSize,
    ad?.adUnitPath,
    ad?.isInitAdConfigSetUp,
    ad?.iabCategoryList,
    ownedBy,
    utmSource,
    doNotTrack,
    pageIndex,
  ]);

  const imageStyleList = useMemo(() => {
    if (animationDirection) {
      return {
        height: imageData?.height,
        width: imageData?.width,
      };
    }

    return {};
  }, [imageData, animationDirection]);

  return (
    <div
      className="single-look"
      style={{ '--average-color': averageColor } as React.CSSProperties}
    >
      <div className={lookItemContainerList} ref={lookItemContainerRef}>
        <div className={imageContainerClassList} ref={imageLookWrapperRef}>
          {logoURL ? (
            <Image className={logoClassList} alt="logo" src={logoURL} />
          ) : null}

          <div className="single-look-item__image-wrapper">
            <Image
              className="single-look-item__image"
              alt="look-img"
              src={imgURL}
              style={imageStyleList}
              setRef={setImageLookEl}
            />
          </div>
        </div>

        {isShouldRenderTextContainer ? (
          <div className="single-look-item__text-container">
            {interestName ? (
              <div className="single-look-item__interest-name label--m">
                {interestName}
              </div>
            ) : null}
            {title ? <h1 className={titleClassList}>{title}</h1> : null}{' '}
            {description?.length ? (
              <div className={descriptionClassList}>
                {/* We added isIgnoreParagraph option because TalkBack ignores overflow: hidden and line-clamp properties. CM-7867 */}
                <TextParser
                  text={description}
                  option={{ isIgnoreParagraph: isIgnoreParagraphInDescription }}
                />
              </div>
            ) : (
              ''
            )}
            {credit ? (
              <div className={creditClassList}>
                <TextParser
                  text={credit}
                  option={{ paragraphClass: 'paragraph--xs' }}
                />
              </div>
            ) : null}
            {children}
          </div>
        ) : null}

        <div className={scrollIndicatorContainerClassList}>
          <div className={scrollIndicatorClassList}>
            <Icon type={IconTypes.doubleArrowUp} />
          </div>
        </div>
      </div>
      {ad?.adUnitId ? (
        <div className="single-look-ad-unit">
          <div
            className="single-look-item__ad"
            style={ad.adUnitSize}
            id={`ad_unit_${ad.adUnitId}`}
          ></div>
        </div>
      ) : null}
    </div>
  );
};

export default SingleLook;
