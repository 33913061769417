import { FC } from 'react';

import LookbookSkeletonCard from './components/LookbookSkeletonCard/LookbookSkeletonCard';

import './index.scss';

const LookbookSkeleton: FC = () => {
  return (
    <div className="lookbook-skeleton">
      <LookbookSkeletonCard />
      <LookbookSkeletonCard />
    </div>
  );
};

export default LookbookSkeleton;
