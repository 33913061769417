import { AxiosInstance, AxiosRequestConfig, AxiosStatic } from 'axios';
import { getApiMetrics, getFullURL } from './utils.ts';
import {
  logFotoscapeApiStatusMetric,
  logFotoscapesApiResponseTimeMetric,
} from '../../../../tracing/events.ts';

export class API {
  private instance: AxiosInstance;

  // ADD interface based on fotoscape API
  constructor(axios: AxiosStatic, config: any) {
    this.instance = axios.create({ baseURL: config.baseURL });
    this.initInterceptors();
  }

  public get(url: string, config?: AxiosRequestConfig): Promise<any> {
    return this.instance.get(url, config);
  }

  public put(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<any> {
    return this.instance.put(url, data, config);
  }

  private initInterceptors() {
    this.instance.interceptors.request.use((request) => {
      return {
        ...request,
        meta: {
          requestStartedAt: new Date().getTime(),
        },
      };
    });

    this.instance.interceptors.response.use(
      async (response) => {
        console.debug(
          // `Got response from ${getFullURL(response)} status ${response.status}`
        );

        const { url, responseCode, responseTime } = getApiMetrics(
          response,
          (response.config as any).meta.requestStartedAt
        );
        logFotoscapeApiStatusMetric({ url, responseCode });
        logFotoscapesApiResponseTimeMetric(responseTime, { url });
        return response.data;
      },
      (error) => {
        console.debug(
          `Got response from ${getFullURL(error.response)} status ${
            error.response.status
          }`
        );

        const { url, responseCode, responseTime } = getApiMetrics(
          error.response,
          (error.response.config as any).meta.requestStartedAt
        );
        logFotoscapeApiStatusMetric({ url, responseCode });
        logFotoscapesApiResponseTimeMetric(responseTime, { url });
        return error;
      }
    );
  }
}
