import { FC, useMemo } from 'react';

import { ISkeletonTextProps } from './interfaces';

import './index.scss';

const SkeletonText: FC<ISkeletonTextProps> = ({
  isWide,
  isLarge,
  className,
}) => {
  const classList = useMemo(() => {
    const list = ['skeleton-text'];

    if (isWide) {
      list.push('skeleton-text--wide');
    }

    if (isLarge) {
      list.push('skeleton-text--large');
    }

    if (className) {
      list.push(className);
    }

    return list.join(' ');
  }, [isWide, isLarge, className]);

  return <div className={classList}></div>;
};

export default SkeletonText;
