import axios, { AxiosInstance } from 'axios';

export interface IFotoscapeApiMetric extends Record<string, string | number> {
  url: string;
}

export class Metrics {
  private metricsGateway = 'https://m-w.dev.mobileposse.com';
  private instance: AxiosInstance;
  private commonLabelAndValues: any;

  constructor() {
    this.instance = axios.create({ baseURL: this.metricsGateway });

  }

  initialize() {
    if (!this.commonLabelAndValues) {
      this.commonLabelAndValues = {
        content_domain: globalThis.hostName || window?.location?.host,
      };
    }
  }


  public histogram(
    metricName: string,
    value: number,
    labelsAndValues: Partial<Record<string, string>>
  ) {
    setTimeout(async () => {
      const payload = JSON.stringify({
        name: `fotoscapes_player_${metricName}`,
        value,
        labels: { ...this.commonLabelAndValues, ...labelsAndValues },
      });
      // console.debug('histogram: ', payload);

      try {
        await this.instance.post('/m/h', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        console.log(`Failed to log histogram metric`, payload, e);
      }
    });
  }

  public counter(metricName: string, labelsAndValues: Record<string, string>) {
    setTimeout(async () => {
      const payload = JSON.stringify({
        name: `fotoscapes_player_${metricName}`,
        labels: { ...this.commonLabelAndValues, ...labelsAndValues },
      });
      // console.debug('counter: ', payload);

      try {
        await this.instance.post('/m/c-inc', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        console.log(`Failed to log counter metric`, payload, e);
      }
    });
  }
}

// Create a singleton instance of the Metrics class
const metricsInstance = new Metrics();
metricsInstance.initialize();

// Export the instance
export default metricsInstance;
