import { FC } from 'react';

import SkeletonImage from '../../../../../../../../components/skeletons/SkeletonImage/SkeletonImage';

import './index.scss';

const InterestCardSkeleton: FC = () => {
  return (
    <div className="interest-card-skeleton">
      <SkeletonImage />
      <div className="interest-card-skeleton__header"></div>
    </div>
  );
};

export default InterestCardSkeleton;
