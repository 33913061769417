import React from "react";
import "./StorySmall.scss";
import Image from '../../containers/BestImageContainer/BestImageContainer.tsx';
import { useViewportWidth } from '../../hooks/useViewportWidth.ts';
import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';

interface StorySmallProps {
  imageList: IImageData[];
  category: string;
  title: string;
}

const StorySmall: React.FC<StorySmallProps> = ({ imageList, category, title }) => {

  return (
    <div className="story-small">
      <div className="small-story-image-container">
        <Image
          targetWidth={114}
          targetHeight={90}
          alt={title}
          className="story-image-sm"
          sizeHint={"small"}
          images={imageList}
        />
      </div>
      <div className="story-body">
        <span className="story-category">{category}</span>
        <h2 className="story-title">{title}</h2>
      </div>
    </div>
  );
};

export default StorySmall;
