import {
  ILookBook,
  IRecircLookBook,
} from '../../services/api/lookBook/interfaces';

export enum actionList {
  setLookBook = 'SET_LOOK_BOOK',
  restLookBook = 'RESET_LOOK_BOOK',
  setRecircLookBookList = 'SET_RECIRC_LOOK_BOOK_LIST',
  setRecircIndex = 'SET_RECIRC_INDEX',
  setLookBookId = 'SET_LOOK_BOOK_ID',
  reset = 'RESET',
}

interface ILimit {
  min: number;
  max: number;
}

export interface ILookBookStateData {
  lookBookId: string;
  itemList: Record<string, ILookBook>;
  limit: ILimit;
}

export interface IRecircStateData {
  recircList: IRecircLookBook[];
  recircIndex: number;
}

export interface ILookBookState {
  lookBook: ILookBookStateData;
  recirc: IRecircStateData;
}

export const lookBookInitialArg: ILookBookState = {
  lookBook: {
    lookBookId: '',
    itemList: {},
    limit: {
      min: 0,
      max: 0,
    },
  },
  recirc: {
    recircList: [],
    recircIndex: 0,
  },
};

interface ISetLookBook {
  type: actionList.setLookBook;
  payload: ILookBook;
}

interface IRestLookBook {
  type: actionList.restLookBook;
  payload: ILookBook;
}

interface ISetLookBookId {
  type: actionList.setLookBookId;
  payload: string;
}

interface ISetRecircLookBookList {
  type: actionList.setRecircLookBookList;
  payload: IRecircLookBook[];
}

interface ISetRecircIndex {
  type: actionList.setRecircIndex;
  payload: number;
}

interface IReset {
  type: actionList.reset;
}

export type IActionTypes =
  | ISetLookBook
  | IRestLookBook
  | IReset
  | ISetLookBookId
  | ISetRecircLookBookList
  | ISetRecircIndex;

export function lookBookReducer(state: ILookBookState, action: IActionTypes) {
  switch (action.type) {
    case actionList.setLookBook: {
      const { payload } = action;

      return {
        ...state,
        lookBook: {
          ...state.lookBook,
          itemList: { ...state.lookBook.itemList, [payload.uid]: payload },
          limit: {
            min: state.lookBook.limit.max ? state.lookBook.limit.max + 1 : 0,
            max: state.lookBook.limit.max
              ? state.lookBook.limit.max + payload.plan.views.length + 1
              : state.lookBook.limit.max + payload.plan.views.length,
          },
        },
      };
    }
    case actionList.restLookBook: {
      const { payload } = action;

      return {
        ...state,
        lookBook: {
          ...state.lookBook,
          itemList: { [payload.uid]: payload },
          limit: {
            min: 0,
            max: payload.plan.views.length,
          },
        },
      };
    }
    case actionList.reset: {
      return lookBookInitialArg;
    }
    case actionList.setLookBookId: {
      const { payload } = action;

      return {
        ...state,
        lookBook: {
          ...state.lookBook,
          lookBookId: payload,
        },
      };
    }
    case actionList.setRecircLookBookList: {
      const { payload } = action;

      return {
        ...state,
        recirc: {
          ...state.recirc,
          recircList: payload,
          recircIndex: state.recirc.recircIndex + 1,
        },
      };
    }
    case actionList.setRecircIndex: {
      const { payload } = action;

      return {
        ...state,
        recirc: {
          ...state.recirc,
          recircIndex: payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
