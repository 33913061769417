import { IRouteObject } from './interfaces';

import Root from '../pages/Root/Root';
import LegalLayout from '../pages/Legal/LegalLayout/LegalLayout';
import ManagePrivacy from '../pages/Legal/pages/ManagePrivacy/ManagePrivacy';
import ContactUs from '../pages/Legal/pages/ContactUs/ContactUs';
import DoNotSellMyPersonalInformation from '../pages/Legal/pages/DoNotSellMyPersonalInformation/DoNotSellMyPersonalInformation';
import AboutUs from '../pages/Legal/pages/AboutUs/AboutUs';
import {
  CATEGORY_ROUTE,
  GAMES_ROUTE,
  HOMEPAGE_ROUTE,
  INTERESTS_ROUTE,
  LOOKBOOK_ROUTE,
  STORIES_ROUTE,
  STORY_ROUTE,
} from '../pages/Root/router/router.tsx';
import {
  ABOUT_US_ROUTE_PATH,
  CONTACT_US_ROUTE_PATH,
  DO_NOT_SELL_ROUTE_PATH,
  MANAGE_PRIVACY_ROUTE_PATH,
} from '../pages/Root/router/constants.ts';

export const MANAGE_PRIVACY_ROUTE: IRouteObject = {
  path: MANAGE_PRIVACY_ROUTE_PATH,
  title: 'Manage Privacy | Fotoscapes',
  description:
    'Take control of your privacy settings with Fotoscapes. Explore our tools and options for managing your personal data preferences.',
  element: (
    <LegalLayout>
      <ManagePrivacy />
    </LegalLayout>
  ),
};

export const DO_NOT_SELL_ROUTE: IRouteObject = {
  path: DO_NOT_SELL_ROUTE_PATH,
  title: 'Do Not Sell My Personal Information | Fotoscapes',
  description:
    'Manage your data privacy effortlessly with Fotoscapes. Explore our options for controlling the use of your personal information.',
  element: (
    <LegalLayout>
      <DoNotSellMyPersonalInformation />
    </LegalLayout>
  ),
};

export const CONTACT_US_ROUTE: IRouteObject = {
  path: CONTACT_US_ROUTE_PATH,
  title: 'Contact Us | Fotoscapes',
  description:
    "Get in touch with Fotoscapes easily. Contact us for inquiries, feedback, advertising requests, or any assistance you may need. We're here to help.",
  element: (
    <LegalLayout>
      <ContactUs />
    </LegalLayout>
  ),
};

export const ABOUT_US_ROUTE: IRouteObject = {
  path: ABOUT_US_ROUTE_PATH,
  title: 'About Us | Fotoscapes',
  description:
    "Welcome to Fotoscapes, where we're dedicated to creating and showcasing captivating stories and galleries that celebrate everyday lifestyle for curious minds worldwide",
  element: (
    <LegalLayout>
      <AboutUs />
    </LegalLayout>
  ),
};

export const ROOT_PAGE = {
  internalChildren: [
    LOOKBOOK_ROUTE,
    CATEGORY_ROUTE,
    GAMES_ROUTE,
    INTERESTS_ROUTE,
    STORIES_ROUTE,
    STORY_ROUTE,
    HOMEPAGE_ROUTE,
  ],
  path: '/*',
  title: '',
  description: '',
  element: <Root />,
};

export const routes: IRouteObject[] = [
  ROOT_PAGE,
  MANAGE_PRIVACY_ROUTE,
  DO_NOT_SELL_ROUTE,
  CONTACT_US_ROUTE,
  ABOUT_US_ROUTE,
];

