import { FC, PropsWithChildren, useState } from 'react';
import './index.scss';

interface ITouchableOpacityLinkerProps extends PropsWithChildren {
  ClickerEl: FC<any>;
  clickerElProps: any;
}

const TouchableOpacityLinker: FC<ITouchableOpacityLinkerProps> = ({
                                                                    ClickerEl,
                                                                    clickerElProps,
                                                                    children,
                                                                  }) => {
  const [isActive, setIsActive] = useState(false);

  const handlePointerDown = () => {
    setIsActive(true);
  };

  const handlePointerUp = () => {
    setIsActive(false);
  };

  const handlePointerLeave = () => {
    setIsActive(false);
  };

  return (
    <div
      className={`touchable-linker ${isActive ? 'touchable-linker--animated' : ''}`}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerLeave}
    >
      <ClickerEl {...clickerElProps}>{children}</ClickerEl>
    </div>
  );
};

export default TouchableOpacityLinker;
