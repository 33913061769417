import { FC, MouseEvent, useEffect, useState } from 'react';

import { getEmailLink } from './utils';
import { DELETE_MY_DATA, GIVE_ME_MY_DATA } from './constants';
import Title from '../../components/Title/Title';
import Main from '../../components/Main/Main';
import FotoscapeLink from '../../../../components/FotoscapeLink/FotoscapeLink';
import { eventTypes } from '../../../../utils/constants';

import './index.scss';

const ManagePrivacy: FC = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventTypes.manageMyDataView });
  }, []);

  const [giveMeMyDataLink, setGiveMeMyDataLink] = useState('');
  const [deleteMeMyDataLink, setDeleteMeMyDataLink] = useState('');

  useEffect(() => {
    setGiveMeMyDataLink(getEmailLink(GIVE_ME_MY_DATA));
    setDeleteMeMyDataLink(getEmailLink(DELETE_MY_DATA));
  }, []);

  const onClickDeleteMyData = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const location =
      e.currentTarget.attributes.getNamedItem('href')?.value || '';

    window.dataLayer.push({
      event: eventTypes.ccpaClick,
      eventCategory: 'CCPA',
      eventAction: 'delete_link',
      eventCallback: function () {
        const localWindow: Window = window;
        localWindow.location = location;
      },
      eventTimeout: 2000,
    });
    return false;
  };

  const onClickGiveMeMyData = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const location =
      e.currentTarget.attributes.getNamedItem('href')?.value || '';

    window.dataLayer.push({
      event: eventTypes.ccpaClick,
      eventCategory: 'CCPA',
      eventAction: 'get_data_link',
      eventCallback: function () {
        const localWindow: Window = window;
        localWindow.location = location;
      },
      eventTimeout: 2000,
    });
  };

  return (
    <div className="legal-page">
      <Title>Privacy statement - California</Title>

      <Main>
        <p>
          This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the
          information contained in the Privacy Policies that apply to your use
          of the websites and applications made available by Mobile Posse, Inc.
          (collectively, “we,” “us,” or “our”) and applies solely to visitors,
          users, and others who reside in the State of California (“consumers”
          or “you”). We adopt this notice to comply with the California Consumer
          Privacy Act of 2018 (“CCPA”) and other California privacy laws. Any
          terms defined in the CCPA have the same meaning when used in this
          notice.
        </p>
        <p>
          This privacy statement only applies to our products and information
          practices. We are not responsible for the actions and privacy policies
          of third party websites, partners, or their services.
        </p>
        <p>
          This Privacy Statement further provides you with instructions on how
          you can request to get your data under the CCPA, how you can instruct
          us to delete your data, and how to stop the “sale” of your
          information.
        </p>
        <p>
          Here are instructions to obtain an accessible and printable version of
          this policy: Right click on this page and in the menu select “Print.”
        </p>
        <p>
          <strong>
            <u>Information We Collect</u>
          </strong>
        </p>
        <p>
          We offer two types of services: Content that we deliver through our
          software application loaded on your device (the “Applications”) and
          content we deliver through the web browsers on your device (“Web
          Services”)(we will refer to Applications and Web Services collectively
          as the “Services”).
        </p>
        <p>
          <strong>
            <u>Categories of Information Collected by Our Applications</u>
          </strong>
        </p>
        <p>
          We collect and use from the Applications the following categories of
          information. We also state whether this information is shared or
          “sold” to third parties.
        </p>
        <p>
          <u>
            1. Categories of Personal Information Collected or Used by the
            Applications: Identifying information about you.
          </u>
        </p>
        <p>
          Examples: Android Advertising ID and our own product ID, which we call
          the “MPID” (each, a “User ID”).
        </p>
        <p>
          <u>Primary Purpose of Collection:</u>
        </p>
        <ul>
          <li>To authenticate your with access to the Services.</li>
          <li>
            To provide you with information, content products or services
            customized to your interests delivered from us and our advertisers.
          </li>
          <li>
            To improve the type of services and content that may be of interest
            to you.
          </li>
          <li>
            For our internal testing, research, analysis and product
            development.
          </li>
        </ul>
        <p>
          <u>Do we share or sell this data?:</u> We store our data on
          third-party hosting services under agreement. We use third-party
          analytics services such as Google Analytics to analyze data on our
          behalf. This data cannot be reused by these services. We sell User ID
          to advertising networks to deliver advertising on our Applications.
        </p>
        <p>
          <u>
            2. Categories of Personal Information Collected or Used by the
            Applications: Internet or other electronic network activity
            information
          </u>
        </p>
        <p>
          Examples: We collect information on how a user interacts with our
          Applications, including IP address, user page views, device platform,
          the device language (e.g., English or Spanish), device model, views of
          first screen deliveries, date and time when a user interacts with our
          Applications, operating system of the user’s device, Application
          version, the type of browser, the type of content delivered to the
          user (e.g., entertainment news), and the number of clicks per session.
        </p>
        <p>
          <u>Primary Purpose of Collection:</u>This information helps us assess
          what features and content are of interest to its users and to measure
          the Services’ efficiency in delivering its services.
        </p>
        <p>
          <u>Do we share or sell this data?:</u> We store our data on
          third-party hosting services under agreement. We use third-party
          analytics services such as Google Analytics to analyze data on our
          behalf. This data cannot be reused by these services. We do not sell
          this data or share it for marketing purposes.
        </p>
        <p>
          <u>
            3. Categories of Personal Information Collected or Used by the
            Applications: Geolocation
          </u>
        </p>
        <p>
          Examples: The Services collect your location from your device when you
          access the Services. This data is not shared with third parties.
        </p>
        <p>
          <u>Primary Purpose of Collection:</u>Location information helps us
          provide you with content relevant to your region, such as local
          weather forecast and regional news.
        </p>
        <p>
          <u>Do we share or sell this data?:</u> We store our data on
          third-party hosting services under agreement. We use third-party
          analytics services such as Google Analytics to analyze data on our
          behalf. This data cannot be reused by these services. We sell
          geolocation to advertising networks to deliver localized advertising
          on our Applications.
        </p>
        <p>
          <strong>
            <u>Categories of Information Collected by our Web Services.</u>
          </strong>
        </p>
        <p>
          The information we collect from you related to the use of the Web
          Services may include the following:
        </p>
        <p>
          <u>
            1. Categories of Personal Information Collected or Used by the Web
            Servides: Identifying information about you.
          </u>
        </p>
        <p>Examples: Our own Application ID (we call it the “MPID”).</p>
        <p>
          We also collect the make and model of your devices (but not its
          specific device ID) and information about your version of the
          Application.
        </p>
        <p>
          <u>Primary Purpose of Collection:</u>
        </p>
        <ul>
          <li>To identify our users of the Web Services.</li>
          <li>
            To provide you with information, content products or services
            customized to your interests delivered from us and our advertisers.
          </li>
          <li>
            To improve the type of services and content that may be of interest
            to you.
          </li>
          <li>
            For our internal testing, research, analysis and product
            development.
          </li>
        </ul>
        <p>
          <u>Do We Share or Sell this Data?: </u> We store our data on
          third-party cloud services and use third-party analytics services
          (e.g., Google Analytics) solely to analyze data on our behalf. We do
          not sell the MPID from Web Services.
        </p>
        <p>
          <u>
            2. Categories of Personal Information Collected or Used by the Web
            Services: Internet or other electronic network activity information
          </u>
        </p>
        <p>
          Examples: We collect information on how a user interacts with our Web
          Services, including IP address, user page views, device platform, the
          device language (e.g., English or Spanish), device model, views of
          first screen deliveries, date and time when a user interacts with our
          Applications, operating system of the user’s device, the type of
          browser, the type of content delivered to the user (e.g.,
          entertainment news), and the number of clicks per session.
        </p>
        <p>
          <u>Primary Purpose of Collection:</u>This information helps us assess
          what features and content are of interest to its users and to measure
          the Services’ efficiency in delivering its services./p&gt;
        </p>
        <p>
          <u>Do We Share or Sell this Data?:</u> We store our data on
          third-party cloud services and use third-party analytics services
          (e.g., Google Analytics) solely to analyze data on our behalf. We do
          not sell this data from our Web Services.
        </p>
        <p>
          <u>
            3. Categories of Personal Information Collected or Used by the Web
            Services: Geolocation
          </u>
        </p>
        <p>
          Example: We collect latitude and longitude information from your
          device when you are using the Web Services.
        </p>
        <p>
          <u>Primary Purpose:</u> Location information helps us provide you with
          content relevant to your region, such as local weather forecast and
          regional news.
        </p>
        <p>
          <u>Do We Share or Sell this Data?:</u> This information is stored on
          third-party services on our behalf and shared with analytics services
          such as Google Analytics for our internal business purposes. This
          information is not sold.
        </p>
        <p>
          <strong>
            <u>Categories of Information that We Do Not Collect</u>
          </strong>
        </p>
        <p>
          We do not collect or share the following categories of personal
          information:
        </p>
        <ul>
          <li>
            Other Identifiers such as name, postal address, email address,
            account name, social security number, driver’s license number,
            passport number, employment history, or financial information.
          </li>
          <li>
            Characteristics of protected classifications under California or
            federal law.
          </li>
          <li>Commercial information.</li>
          <li>Biometric information.</li>
          <li>
            Audio, electronic, visual, thermal, olfactory, or similar
            information.
          </li>
          <li>Professional or employment-related information.</li>
          <li>Education information.</li>
          <li>Inferences drawn from information to create a profile.</li>
        </ul>
        <p>
          We will retain as permitted under the CCPA certain data analyzed and
          processed by us, such as when a user has accepted our terms for the
          Application and such other data reasonably anticipated within the
          context of our relationship with you or solely for internal business
          purposes.
        </p>
        <p>
          <strong>
            <u>How we Use Your Information</u>
          </strong>
        </p>
        <p>
          In addition to the primary purposes of collection noted above, we may
          generally use the categories of information noted above as follows:
        </p>
        <ul>
          <li>Provide you with access to the Services.</li>
          <li>Customize your experience with the Services.</li>
          <li>
            Contact you through the service to provide you with important
            information, notices, and promotions.
          </li>
          <li>
            Protect our rights and property and those of our other customers
            against unauthorized use.
          </li>
          <li>
            Aggregate information for business purposes that include market
            analysis or traffic flow.
          </li>
        </ul>
        <p>
          <strong>
            <u>Retaining Information</u>
          </strong>
        </p>

        <p>
          We keep your information only as long as necessary to provide you with
          our services and for legitimate and essential business purposes, such
          as maintaining the performance of the services, making data-driven
          business decisions about new features and offerings, complying with
          our legal obligations, and resolving disputes.
        </p>
        <p>
          <strong>
            <u>Sharing Personal Information</u>
          </strong>
        </p>
        <p>
          We may share your Information from our Services with data hosting
          services and analytical services to provide us with business,
          professional or technical support. Subject to your right to opt-out,
          we also share User IDs (but not location or activity information) from
          our Applications with advertising networks to provide you with offers
          that may be of more interest to you. Please note: We do not share User
          IDs, location or activity information with Advertisers from our Web
          Services. When we disclose personal information for a business
          purpose, we enter a contract that describes the purpose and requires
          the recipient to both keep that personal information confidential and
          not use it for any purpose except performing the contract.
        </p>
        <p>
          We share your personal information with the following categories of
          third parties: Service Providers, which include data hosting services
          and analytics services that process information on our behalf under a
          written contract and Advertising Networks.
        </p>
        <p>
          <strong>
            <u>Your Rights and Choices</u>
          </strong>
        </p>
        <p>
          The CCPA provides consumers (California residents) with specific
          rights regarding their personal information. This section describes
          your CCPA rights and explains how to exercise those rights.
        </p>
        <p>
          Under the CCPA, we have a right to verify your request. Important:
          Since we do not collect email addresses, numbers or names, the only
          way we can verify your request is if you provide us with us with your
          User IDs (Android Ad ID and MPID). The easiest way for us to verify
          your request is (a) if you click the “Do Not Sell My Information” on
          the Application on your device, or (b) for other requests, click on
          the “Give Me My Data” or “Delete My Data” links below. These links
          automate the process. We also provide instructions below if you desire
          to submit your requests via toll free number or through an authorized
          agent.
        </p>
        <p>
          <strong>
            <u>
              Right to Know: Access to Specific Information and Data Portability
              Rights
            </u>
          </strong>
        </p>
        <p>
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information over the
          past 12 months. Please note we delete user data that is older than 3
          months.
        </p>
        <p>
          Once we receive and confirm your verifiable consumer request, we will
          disclose to you:
        </p>
        <ul>
          <li>
            The categories of personal information that we have collected.
          </li>
          <li>
            The categories of sources from which the personal information is
            collected.
          </li>
          <li>
            The business or commercial purpose for collecting or selling
            personal information.
          </li>
          <li>
            The categories of third parties with whom the business shares
            personal information.
          </li>
          <li>
            The categories of personal information that the business sold about
            the consumer and the categories of third parties to whom the
            personal information was sold, by category or categories of personal
            information for each third party to whom the personal information
            was sold.
          </li>
        </ul>
        <p>You can submit your request by:</p>
        <p>
          Clicking on the “Give Me My Data” link at the bottom of this page.
        </p>
        <p>Call 1-866-304-3097.</p>
        <p>
          <strong>
            <u>Right To Delete Data</u>
          </strong>
        </p>
        <p>
          You have the right to request that we delete any of your Information
          collected and retained by us subject to certain exceptions. Once we
          receive and confirm your verifiable consumer request, we will delete
          (and direct our service providers to delete) your personal information
          from our records, unless an exception applies.
        </p>
        <p>
          We may deny your deletion request if retaining the information is
          necessary for us or our service providers to:
        </p>
        <ul>
          <li>
            Complete the transaction for which we collected the personal
            information, provide a good or service that you requested, take
            actions reasonably anticipated within the context of our ongoing
            business relationship with you, or otherwise perform our contract
            with you.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity, or prosecute those responsible for
            such activities.
          </li>
          <li>
            Debug products to identify and repair errors that impair existing
            intended functionality./li&gt;
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act
            (Cal. Penal Code § 1546 seq.).
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with
            consumer expectations based on your relationship with us.
          </li>
          <li>Comply with a legal obligation.</li>
        </ul>
        <p>
          <strong>
            <u>Exercising Deletion Rights</u>
          </strong>
        </p>
        <p>
          You have a right to ask us to delete your data. You can submit your
          request by:
        </p>
        <p>Clicking on the “Delete My Data” link below.</p>
        <p>Calling us at 1-866-304-3097</p>
        <p>
          Only you or a person registered with the California Secretary of State
          that you authorize to act on your behalf, may make a verifiable
          consumer request related to your personal information. You may also
          make a verifiable consumer request on behalf of your minor child.
        </p>
        <p>
          You may stop all information collection by uninstalling the
          Application. You may use the standard uninstall processes available as
          part of your mobile device or uninstall the App through your device’s
          Google Play store.
        </p>
        <p>
          There are some instances in which you may not be able to uninstall
          some older versions of the Applications preloaded on your device. In
          that case, the Application can be force stopped by clicking on the
          “Settings” icon, then click on Apps &amp; Notifications, then “See All
          apps”, and then click on the Application link. You will then have the
          option to “Force stop” the application, and clear information stored
          in memory by clicking on “Storage &amp; Cache” then “Clear Storage”
          and “Clear Cache”.
        </p>
        <p>
          <strong>
            <u>Right to Opt-Out of the Sale of Personal Information</u>
          </strong>
        </p>
        <p>
          Users of Applications have the right to opt-out of the sale of
          Personal Information collected as part of the Applications. Users can
          submit their requests by:
        </p>
        <p>
          Launch your version of the Application on your device, Click on the
          “Settings” icon, and then click “Do Not Sell My Info.”
        </p>
        <p>Calling us at 1-866-304-3097</p>
        <p>
          <strong>
            <u>Right to Designate an Authorized Agent</u>
          </strong>
        </p>
        <p>
          Users of the Services can designate an authorized agent to make a
          request under the CCPA if they provide that agent with their User ID
          information. Please note that this action would allow such agent
          access to all information contained in the Application. If a user
          chooses to take this action, they do so entirely at their own risk and
          we disclaim all responsibility and liability for how such agent may
          access, use or disclose the information they obtain.
        </p>
        <p>
          The agent will need to have your User ID to make a request. We are not
          able to identify a user based on name or email address since we do not
          collect this information. Agents must submit requests to
          privacyact.fotoscapes@mobileposse.com. Users can provide their User ID
          information using the process described below.
        </p>
        <p>
          <strong>
            <u>Finding the Android Advertising ID and Mobile Posse ID.</u>
          </strong>
        </p>
        <p>
          In order to verify your request under the CCPA, we will need your User
          ID (again, that is the Android Advertising ID and the MPID). The
          easiest way to verify your request is by (a) using the “Do Not Sell My
          Information” in the Application’s Settings area on your device, or (b)
          for other requests, go to the “Get My Data,” or “Delete My Data” links
          below. These links automate the process. If you contact us toll free
          number or an agent, our representative may ask you or your agent to
          locate your User IDs. You can find the Google Android Advertising ID
          by clicking on the “Settings” icon on your device, click on “Google”
          and then click on “Ads.” There you will see a string of letters and
          numbers that make up your Google Android Advertising ID. In the event
          you are asked to provide an MPID, please follow this process: To find
          the MPID, launch the Application on your device. Click on the
          “Settings” icon (the gear icon). Click on “Send Feedback.” When you
          click on Feedback, you will see a pre-populated email page. You will
          see on this email page a string of numbers and characters that make up
          your MPID. Copy that ID and to share with us so that we can act on
          your request.
        </p>
        <p>
          <strong>
            <u>Cookies</u>
          </strong>
        </p>
        <p>
          Some of our third party partners may collect and share certain pieces
          of information from our visitors, such as cookies, advertising IDs,
          location and similar technologies so that our third party partners can
          deliver ads to you and conduct analysis about our services. These
          technologies are summarized as follows:
        </p>
        <table>
          <thead>
            <tr>
              <th>Type of Technology</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cookies</td>
              <td>
                A cookie is a small file placed onto your device that enables
                our services’ features and functionality. There are two types of
                cookies: “persistent”, which stays on your browser or our
                application (such as, to help verify your login) and “session”
                cookies, which last only as long as you are currently using our
                app or browser.
              </td>
            </tr>
            <tr>
              <td>Pixels</td>
              <td>
                A pixel is a tiny image that may be found within web pages and
                emails. They’re used to learn more about your use of content on
                our service or whether you interact with an advertisement.
              </td>
            </tr>
            <tr>
              <td>Other technologies</td>
              <td>
                We also use other tracking technologies, such as the User ID we
                describe above.
              </td>
            </tr>
          </tbody>
        </table>
        <p>We and our third-party providers use Cookies for the following:</p>
        <table>
          <thead>
            <tr>
              <th>Purpose</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Authentication</td>
              <td>
                Cookies enable us to identify you and verify your account when
                you visit our services.
              </td>
            </tr>
            <tr>
              <td>Security</td>
              <td>
                Cookies to enable and support our security features, such as by
                helping us detect malicious activity and violations of our Terms
                of Service.
              </td>
            </tr>
            <tr>
              <td>Preferences</td>
              <td>
                These cookies can help us customize content for you, and
                remember the preferences you selected with use of our service.
              </td>
            </tr>
            <tr>
              <td>Advertising</td>
              <td>
                Cookies and similar technologies help us show relevant
                advertising to you more effectively and to measure the
                performance of such ads. We use these technologies to learn
                whether content has been shown to you or whether someone who was
                presented with an ad. Similarly, our third-party partners or
                service providers may use these technologies to determine
                whether we've shown an ad or a post and how it performed or
                provide us with information about how you interact with ads.
                Some of this type of information sharing may also be considered
                a "sale" under the CCPA.
              </td>
            </tr>
            <tr>
              <td>Performance, analytics and research</td>
              <td>
                We or our service providers use these technologies to
                understand, improve, and research products, features and
                services, including as you navigate through our sites or when
                you access our services. We, or our service providers, use these
                technologies to determine and measure the performance of ads or
                posts on the service and to learn whether you have interacted
                with our service and content and provide analytics based on
                those interactions. We also use these technologies to provide
                aggregated information to our customers and partners as part of
                our services.
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          <strong>
            <u>Advertisers</u>
          </strong>
        </p>
        <p>
          Our third-party advertisers may use cookies and collect information
          from our users so that they can deliver ads based on your interests
          and to conduct analysis about our services. If you wish to opt out of
          this advertising activity by advertisers who offer CCPA opt outs,
          please visit our Advertising and Cookie Policy and follow the
          instructions. The Advertising and Cookie Policy page also lists our
          third-party providers.
        </p>
        <p>
          <strong>
            <u>Service Providers</u>
          </strong>
        </p>
        <p>
          Our third-party service providers may use cookies and collect
          information on our behalf for analytics, auditing, security,
          debugging, delivering content and games to users, quality assurance,
          to assess our performance, research, and performance. Unlike
          advertising, these cookies are used for the functioning of our
          services.
        </p>
        <p>
          <strong>
            <u>Options on Cookies</u>
          </strong>
        </p>
        <p>
          The browsers on your device allow you to control cookies through their
          settings and to erase cookies stored on your browser. Please note that
          you may worsen your overall user experience if you limit the ability
          for our services to set cookies. You also can go to our Advertising
          and Cookie Policy to exercise your options with our third-party
          advertising
        </p>
        <p>Please keep in mind:</p>
        <ul>
          <li>
            You must opt out on your device in which you access our services.
          </li>
          <li>
            If you clear cookies, your opt-out may also be cleared and you must
            opt out again where you want your choices to apply.
          </li>
          <li>
            We do not have control over the opt-out mechanisms of our
            third-party providers.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act
            (Cal. Penal Code § 1546 seq.).
          </li>
          <li>
            Your Exercising these choices does not prevent us from delivering
            contextual advertising, advertising to you based on data we have
            about you, conducting cross-device tracking for us to serve you
            first-party ads directly, or using information for non-targeted
            advertising purposes, such as measuring ad effectiveness,
            attribution and analytics.
          </li>
        </ul>
        <p>
          <strong>
            <u>Limitations on the Number of Requests</u>
          </strong>
        </p>
        <p>
          You may only make a verifiable consumer request for access or data
          portability twice within a 12-month period. The verifiable consumer
          request must:
        </p>
        <p>
          Provide sufficient information that allows us to reasonably verify you
          are the person about whom we collected personal information or an
          authorized representative.
        </p>
        <p>
          Describe your request with sufficient detail that allows us to
          properly understand, evaluate, and respond to it.
        </p>
        <p>
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you. If we are
          unable to link your request with your User ID, we will provide you
          instructions on how you can locate your User ID on your device. Making
          a verifiable consumer request does not require you to create an
          account with us. We will only use personal information provided in a
          verifiable consumer request to verify the requestor's identity or
          authority to make the request. We will retain this information in
          accordance with the CCPA.
        </p>
        <p>
          <strong>
            <u>Response Timing and Format</u>
          </strong>
        </p>
        <p>
          We endeavor to respond to a verifiable consumer request within 45 days
          of its receipt. If we require more time (up to 90 days), we will
          inform you of the reason and extension period in writing. We will
          deliver our written response by mail or electronically, at your
          option. Any disclosures we provide will only cover the 12-month period
          preceding the verifiable consumer request's receipt. The response we
          provide will also explain the reasons we cannot comply with a request,
          if applicable. For data portability requests, we will select a format
          to provide your personal information that is readily useable and
          should allow you to transmit the information from one entity to
          another entity without hindrance.
        </p>
        <p>
          We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.
        </p>
        <p>
          <strong>
            <u>Children Under the Age of 16</u>
          </strong>
        </p>
        <p>
          Our Services are not intended for children under 16 years of age. No
          one under age 16 may provide any personal information on our Services.
          We do not knowingly collect personal information from children under
          16. If you are under 16, do not use or provide any information on our
          Services. If we learn we have collected or received personal
          information from a child under 16 without verification of parental
          consent, we will delete that information. If you believe we might have
          any information from or about a child under 16, please contact us at
          the email address below.
        </p>
        <p>
          <strong>
            <u>Non-Discrimination</u>
          </strong>
        </p>
        <p>
          We provide our services at no cost to you. Unless permitted by the
          CCPA, we will not discriminate against you for exercising any of your
          CCPA rights.
        </p>
        <p>
          <strong>
            <u>Changes to Our Privacy Notice</u>
          </strong>
        </p>
        <p>
          We reserve the right to amend this privacy notice at our discretion
          and at any time. When we make changes to this privacy notice, we will
          notify you by email or through a notice on our website homepage.
        </p>
        <p>
          <strong>
            <u>Contact Information</u>
          </strong>
        </p>
        <p>
          If you have any questions or comments about this notice, our Privacy
          Statement, the ways in which we collect and use your personal
          information, your choices and rights regarding such use, or wish to
          exercise your rights under California law, please do not hesitate to
          contact us at:
        </p>
        <dl>
          <dt>Phone: 1-866-304-3097</dt>
          <dt>Website: www.fotoscapes.com</dt>
          <dt>Email: privacy@digitalturbine.com</dt>
          <dt>Postal Address: Digital Turbine</dt>
          <dt>Attn: CCPA</dt>
          <dt>4040 Fairfax Drive Ste 888</dt>
          <dt>Arlington, VA 22203</dt>
        </dl>
        <p>Updated: 20 April 2024</p>

        <div>
          <FotoscapeLink
            className="legal-page__link"
            to={deleteMeMyDataLink}
            onClick={onClickDeleteMyData}
          >
            Delete My Data
          </FotoscapeLink>
        </div>
        <div>
          <FotoscapeLink
            className="legal-page__link"
            to={giveMeMyDataLink}
            onClick={onClickGiveMeMyData}
          >
            Give Me My Data
          </FotoscapeLink>
        </div>
      </Main>
    </div>
  );
};

export default ManagePrivacy;
