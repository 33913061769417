export const LARGE_AD_TEXT_LENGTH = 40;
export const ONE_DAY_TIME = 86400000; // equal to 24 hours

// URL parameters to copy into other parts of our system.
export const LOOKBOOK_PARAMETERS_TO_COPY = [
  'mp_aaid', // Google Advertising ID.
  'mp_dist', // Distribution Partner.
  'mp_lang', // Human language for page.
  'mp_layout', // UI layout type.
  'mp_mpid', // Mobile Posse ID.
  'mp_page', // Page identifier.
  'mp_pos', // Block position on page.
  'mp_provider', // Content partner/provider.
  'mp_pub', // Content publisher.
  'mp_test', // Test and cohort.
  'mp_time-slot', // FSD delivery time.
  'utm_campaign', // Product.
  'utm_content', // Content monetization type.
  'utm_medium', // Session origin.
  'utm_source', // Traffic from MP audience.
  'recirculation', // Whether page is recirculation
];
