import { FeatureFlags } from '../pages/Root/ContextWrapper/WithFeatureConfig/hooks/constants';

const QUERY_TO_SAVE = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
  FeatureFlags.videoBlock,
  FeatureFlags.downloadOverlay,
];

const getNewQueryValue = (query: string, key: string, value: string) => {
  if (!query.length) {
    return `${key}=${value}`;
  }

  return `&${key}=${value}`;
};

export const getQuery = (currentQuery: string, search: URLSearchParams) => {
  let query = '';

  const queryString = currentQuery?.startsWith('?')
    ? currentQuery.slice(1)
    : currentQuery;

  const linkQuery: Record<string, string>[] =
    queryString?.split('&').map((item) => {
      const entries = item.split('=');
      return {
        key: entries[0],
        value: entries[1],
      };
    }) || [];

  Array.from(QUERY_TO_SAVE).forEach((key) => {
    let value = '';

    const currentQueryIndex = linkQuery.findIndex((item) => {
      return item.key === key;
    });

    if (currentQueryIndex >= 0) {
      value = linkQuery[currentQueryIndex].value;
    } else {
      const searchValue = search.get(key);

      if (searchValue) {
        value = searchValue;
      }
    }

    if (value) {
      query += getNewQueryValue(query, key, value);
    }
  });

  linkQuery?.forEach(({ key, value }) => {
    if (!query.includes(key) && value) {
      query += getNewQueryValue(query, key, value);
    }
  });

  return query;
};
