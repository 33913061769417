import { StorageService } from './storage';

const getLocalStorage = () => {
  try {
    return new StorageService(localStorage);
  } catch (e: any) {
    console.warn(e);
    return null;
  }
};
export default getLocalStorage();
