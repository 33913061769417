import { Settings } from 'react-slick';

export const SLIDE_WIDTH = 340;
export const SMALL_SLIDE_WIDTH = 282;
export const MOBILE_SMALL_SLIDE_WIDTH = 330;

export const SETTINGS: Settings = {
  infinite: false,
  lazyLoad: 'ondemand',
  variableWidth: true,
};
