import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { ICheckboxProps } from './interface';

import './index.scss';

const Checkbox: FC<ICheckboxProps> = ({ isChecked, onChange, value }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(isChecked);

  const containerClassList = useMemo(() => {
    const list = ['checkbox__container'];

    if (isCheckboxChecked) {
      list.push('checkbox__container--checked');
    }

    return list.join(' ');
  }, [isCheckboxChecked]);

  const onCheckboxChange = useCallback(
    ({ target: { value, checked } }: ChangeEvent<HTMLInputElement>) => {
      setIsCheckboxChecked(checked);

      if (onChange) {
        onChange(checked, value);
      }
    },
    [onChange]
  );

  return (
    <label data-testid="checkbox-el" className={containerClassList}>
      <input
        className="checkbox"
        type="checkbox"
        value={value}
        onChange={onCheckboxChange}
        checked={isCheckboxChecked}
      />
    </label>
  );
};

export default Checkbox;
