import { FC } from 'react';

import './index.scss';

const CategoryCardSkeleton: FC = () => {
  return (
    <div className="category-card-skeleton">
      <div className="category-card-skeleton__image"></div>

      <div className="category-card-skeleton__text"></div>

      <div className="category-card-skeleton__text category-card-skeleton__text--wide category-card-skeleton__text--large"></div>
      <div className="category-card-skeleton__text category-card-skeleton__text--wide category-card-skeleton__text--large"></div>

      <div className="category-card-skeleton__text category-card-skeleton__text--wide"></div>
      <div className="category-card-skeleton__text category-card-skeleton__text--wide"></div>
      <div className="category-card-skeleton__text category-card-skeleton__text--wide"></div>

      <div className="category-card-skeleton__text"></div>
    </div>
  );
};

export default CategoryCardSkeleton;
