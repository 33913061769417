import React, { FC, useCallback } from 'react';

import Portal from '../../../../components/Portal';
import { IDialogProps } from './interfaces';
import Icon from '../../../../components/Icon/Icon.tsx';
import { IconSize, IconTypes } from '../../../../components/Icon/constants.ts';

import './index.scss';

const Dialog: FC<IDialogProps> = ({ children, isOpen, onClose }) => {
  const onModalClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  return isOpen ? (
    <Portal idSelector="dialog">
      <div className="dialog">
        <div className="dialog__content">
          <button className="dialog__close" onClick={onModalClose}>
            <Icon type={IconTypes.close} size={IconSize.s}></Icon>
          </button>
          {children}
        </div>
      </div>
    </Portal>
  ) : null;
};

export default Dialog;
