import { FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  EXTRA_AD_CONFIG,
  EXTRA_AD_SIZE_LIST,
  EXTRA_PARAMETER_TO_COPY,
} from './constants';
import { IAdSlideProps } from './interfaces';
import { setUpAds } from '../../../../HomeRoot/utils';
import { IAdParameter } from '../../../../HomeRoot/interfaces';

import './index.scss';

let counter = 0;

const AdSlide: FC<IAdSlideProps> = ({ id, lookbookUid, isActive }) => {
  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    const parameterList: IAdParameter[] = [];
    for (const parameter of EXTRA_PARAMETER_TO_COPY) {
      const parameterValue = searchParams.get(parameter);
      if (parameterValue) {
        parameterList.push({ parameter, parameterValue });
      }
    }

    return parameterList;
  }, [searchParams]);

  useEffect(() => {
    const parameterList = [...params];

    if (params && isActive) {
      if (lookbookUid) {
        parameterList.push({
          parameter: 'mpcontentid',
          parameterValue: lookbookUid,
        });
      }
      parameterList.push({
        parameter: 'count',
        parameterValue: `${++counter}`,
      });

      setUpAds(
        EXTRA_AD_CONFIG,
        `interstial_${id}`,
        EXTRA_AD_SIZE_LIST,
        parameterList,
        []
      );
    }
  }, [id, params, isActive, lookbookUid]);

  return (
    <div className="ad-slide">
      <div id={`ad_unit_interstial_${id}`} />
    </div>
  );
};

export default AdSlide;
