export enum signups {
  styletips = 'styletips',
  lifehacks = 'lifehacks',
  travelGuides = 'travelGuides',
}

export interface ISubscribeFormData {
  name: string;
  signups: signups[];
}

export interface ISubscribeProps {
  isVisible: boolean;
  onSubmit: (data: ISubscribeFormData) => void;
  onClose: () => void;
}
