export class StorageService {
  constructor(private storage: Storage) {}

  public get(key: string): any {
    const item = this.storage.getItem(key);

    if (item === null) {
      return item;
    }

    try {
      return JSON.parse(item);
    } catch (error) {
      console.warn(error);
      return item;
    }
  }

  public set(key: string, value: any): void {
    const item = typeof value === 'string' ? value : JSON.stringify(value);
    this.storage.setItem(key, item);
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }
}
