import React, { FC, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import FotoscapeLink from '../FotoscapeLink/FotoscapeLink';
import fotoSCAPEImg from '../../assets/images/FotoSCAPE.svg';
import Image from '../Image/Image';
import NavMenu from '../NavMenu/NavMenu';
import { SUB_NAV_LINK_LIST } from './constants';
import { FACEBOOK_LINK } from '../../utils/constants';
import { useFeature } from '../../pages/Root/ContextWrapper/WithFeatureConfig/hooks';
import { ROUTER_ANIMATION_TYPE } from '../../pages/Root/router/constants.ts';
import Icon from '../Icon/Icon.tsx';
import { IconSize, IconTypes } from '../Icon/constants.ts';

import './index.scss';

const Header: FC = () => {
  const location = useLocation();
  const animationNodeRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { isHeaderSubMenuAvailable } = useFeature();

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const headerLogoClassList = useMemo(() => {
    const list = ['header__navigation-logo'];

    if (isMenuOpened) {
      list.push('header__navigation-logo--hide');
    }

    return list.join(' ');
  }, [isMenuOpened]);

  const onMenuClose = () => {
    setIsMenuOpened(false);
  };

  const onMenuClick = () => {
    setIsMenuOpened((prevState) => {
      return !prevState;
    });
  };

  const getSubNavLink = (link: string) => {
    const list = ['header__nav-item'];

    if (link === location.pathname) {
      list.push('header__nav-item--active');
    }

    return list.join(' ');
  };

  return (
    <header className="header">
      <div className="header__main">
        <div className="header__navigation">
          <div className="header__social">
            <FotoscapeLink to={FACEBOOK_LINK}>
              <Icon type={IconTypes.facebook}></Icon>
            </FotoscapeLink>
          </div>
          <FotoscapeLink
            className={headerLogoClassList}
            to={'/'}
            state={{
              animation: {
                in: ROUTER_ANIMATION_TYPE.routerAnimationFadeIn,
                out: ROUTER_ANIMATION_TYPE.routerAnimationFadeOut,
              },
            }}
          >
            <Image className="header__logo" alt="logo" src={fotoSCAPEImg} />
          </FotoscapeLink>
        </div>

        <div className="header__action-list">
          <button className="header__menu-button" onClick={onMenuClick}>
            <Icon type={IconTypes.hamburger} size={IconSize.xxl}></Icon>
          </button>

          <CSSTransition
            unmountOnExit={true}
            classNames="nav-menu__animation"
            nodeRef={animationNodeRef}
            in={isMenuOpened}
            timeout={100000}
          >
            <div ref={animationNodeRef}>
              <NavMenu
                isOpen={isMenuOpened}
                onClose={onMenuClose}
                locationPathname={location.pathname}
              />
            </div>
          </CSSTransition>
        </div>
      </div>

      {isHeaderSubMenuAvailable ? (
        <div className="header__sub-navigation-menu">
          {SUB_NAV_LINK_LIST.map(({ link, text }) => {
            return (
              <FotoscapeLink
                key={link}
                className={getSubNavLink(link)}
                to={link}
              >
                {t(text)}
              </FotoscapeLink>
            );
          })}
        </div>
      ) : null}
    </header>
  );
};

export default Header;
