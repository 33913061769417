import { FC, PropsWithChildren } from 'react';

import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

import './index.scss';

const LegalLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="legal-layout">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default LegalLayout;
