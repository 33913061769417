import { FC, useMemo, useRef } from 'react';

import Image from '../../../../../../../../../../components/Image/Image';
import { IStoryLookProps } from './interfaces';
import { useResize } from '../../../../../../../../hooks';
import TextParser from '../../../../../../../../components/TextParser/TextParser';

import './index.scss';

const StoryLook: FC<IStoryLookProps> = ({ imageList, title, description }) => {
  const imageLookWrapperRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useResize();

  const subHeaderFirstLevelClass = useMemo(() => {
    return isMobile ? 'f-headline--m' : 'f-headline--xxl';
  }, [isMobile]);

  const paragraphClassList = useMemo(() => {
    return isMobile ? 'paragraph--xs' : 'paragraph--m';
  }, [isMobile]);

  return (
    <div className="story-look">
      <div className="story-look__title">
        {title ? (
          <TextParser
            text={title}
            option={{
              subHeaderFirstLevelClass: subHeaderFirstLevelClass,
            }}
          />
        ) : null}
      </div>

      <div className="story-look__main">
        <div className="story-look__image-wrapper" ref={imageLookWrapperRef}>
          <Image
            className="story-look__image"
            imageList={imageList}
            alt="lookbook"
          />
        </div>

        <div className="story-look__description">
          <TextParser
            text={description}
            option={{ paragraphClass: paragraphClassList }}
          />
        </div>
      </div>
    </div>
  );
};

export default StoryLook;
