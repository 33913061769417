import { ChangeEvent, FC, useEffect, useState } from 'react';

import Title from '../../components/Title/Title';
import Main from '../../components/Main/Main';
import { getDNSMI, setDNSMI } from '../../../../utils/gtm';
import FotoscapeLink from '../../../../components/FotoscapeLink/FotoscapeLink';
import { eventTypes } from '../../../../utils/constants';

import './index.scss';

const DoNotSellMyPersonalInformation: FC = () => {
  const [defaultDNSMI] = useState(getDNSMI());

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventTypes.doNotSellView });
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDNSMI(e.currentTarget.checked);
  };

  return (
    <div className="legal-page legal-page__do-not-sell">
      <Title>
        DO NOT SHARE OR SELL MY PERSONAL INFORMATION & LIMIT THE USE OF MY
        SENSITIVE PERSONAL INFORMATION
      </Title>
      <Main>
        <p>
          As detailed in our{' '}
          <FotoscapeLink
            className="legal-page__link"
            to="https://www.digitalturbine.com/notice-at-collection-and-privacy-policy-cm/"
          >
            privacy policy
          </FotoscapeLink>
          , we allow our Ad monetization partners to collect personal
          information related to you on our Services. We do not receive monetary
          consideration for allowing these third parties to collect personal
          information on our Services, but such collection may be considered as
          the sale or sharing of personal information. Such personal information
          may include sensitive personal information (within the meaning of US
          Privacy Laws), such as geo-location (GPS) data.{' '}
        </p>

        <p>
          For further details on what personal information related to you is
          collected by such third parties, please see our{' '}
          <FotoscapeLink
            className="legal-page__link"
            to="https://www.digitalturbine.com/notice-at-collection-and-privacy-policy-cm/"
          >
            privacy policy
          </FotoscapeLink>
          .{' '}
        </p>

        <p>
          Accordingly, we provide these Notices of Right to Opt-out of
          Sale/Sharing and Right Limit (collectively: "<strong>Notices</strong>
          ") that describe your rights under applicable federal and state laws
          and regulations of the United States governing the protection of
          privacy and personal data ("<strong>US Privacy Laws</strong>") to:
        </p>

        <p>
          opt-out of the sale or sharing (within the meaning of US Privacy Laws)
          of personal information related to you by us and our third-party Ad
          monetization partners; and,
        </p>

        <p>
          limit our use of Sensitive Personal Information (within the meaning of
          US Privacy Laws) only to specified purposes permitted under US Privacy
          Laws.
        </p>

        <p>
          These Notices also explain how you can exercise these rights and
          enable you to do so.
        </p>

        <p>
          <u>Description of the rights:</u>
        </p>

        <p>
          Under US State Privacy Laws, consumers have, among others, the
          following rights:
        </p>

        <p>
          "<strong>Right to Opt-out</strong>": You have the right, at any time,
          to direct us not to sell or share the consumer's personal information.
        </p>

        <p>
          "<strong>Right to Limit the Use</strong>":<em> </em>You have<em> </em>
          the right, at any time, to direct us to limit our use of sensitive
          personal information related to you, and direct our Ad monetization
          partners to do the same, only to the following purposes:{' '}
        </p>
        <ul>
          <p>To perform the Services that we provide you;</p>
          <ul>
            <li>
              <p>
                For the following business purposes (within the meaning of US
                Privacy Laws):
              </p>
            </li>

            <li>
              <p>
                Helping to ensure our or the Services' security an integrity, to
                the extent that the use of sensitive personal information
                related to you by us is reasonably necessary and proportionate
                to achieve this purpose.
              </p>
            </li>

            <p></p>
            <li>
              For short-term, transient use, including, but not limited to,
              non-personalized advertising shown as part of your current
              interaction with us or our Services, provided that we do not
              disclose such sensitive personal information related to another
              third party and we do not use such information to build a profile
              about you or otherwise alter your experience outside your current
              interaction with us.
              <p></p>
            </li>

            <p></p>
            <li>
              Performing services on our behalf, including maintaining or
              servicing accounts, providing customer service, processing or
              fulfilling orders and transactions, verifying customer
              information, processing payments, providing financing, providing
              analytic services, providing storage, or providing similar
              services on our behalf.<p></p>
            </li>

            <p></p>
            <li>
              Undertaking activities to verify or maintain the quality or safety
              of our Service, and to improve, upgrade, or enhance the Services.
              <p></p>
            </li>

            <p></p>
            <li>
              Any other purpose permitted, now or in the future, by US Privacy
              Laws or regulations issued pursuant thereto.<p></p>
            </li>
          </ul>

          <p>
            <strong>Exercising your rights</strong>
          </p>

          <p>
            To exercise your Right to Opt-out, please click the "Do Not Sell or
            Share" button below. Once you click on the button, you will see a
            "check mark" confirming that we have received your "Do Not Sell or
            Share" request and have acted accordingly.
          </p>

          <p>
            To exercise your Right to Limit the Use, please click the "Limit the
            use of sensitive personal information related to me" button below.
            Once you click on the button, you will see a "check mark" confirming
            that we have received your "Limit the use of sensitive personal
            information related to me" request and have acted accordingly.
          </p>

          <p>
            If you decide to exercise your Rights to Opt-out and Limit the Use,
            we may after twelve (12) months ask you to reauthorize the sale or
            sharing of personal information related to you, or the use of
            sensitive personal information related to you for purposes that go
            beyond those detailed above. For more details, please see our&nbsp;"
            <FotoscapeLink
              className="legal-page__link"
              to="https://www.digitalturbine.com/notice-at-collection-and-privacy-policy-cm/"
            >
              Notice at Collection and Privacy Policy
            </FotoscapeLink>
            ".
          </p>
        </ul>

        <div className="do-not-sell__form">
          <input
            className="do-not-sell__input"
            id="doNotSell"
            type="checkbox"
            defaultChecked={defaultDNSMI}
            onChange={onChange}
          />
          <label htmlFor="doNotSell">
            Do not sell or Share personal information related to me
          </label>
        </div>
      </Main>
    </div>
  );
};

export default DoNotSellMyPersonalInformation;
