import { IInterest } from '../../services/api/interest/interfaces';
import { INTEREST_SCHEDULE } from './constants';
import { IAdTag } from '../../services/api/ads/interfaces';
import { refreshAdd } from '../../utils/ads';
import { IAdMapData, IAdParameter, IGeneralSize } from './interfaces';
import { IDailyLookBook } from '../../services/api/lookBook/interfaces';
import { getTranslation } from '../../../../i18n';

export const getAvailableInterestList = (
  interestList: Map<string, IInterest>,
  extraItemUID?: string
) => {
  return Array.from(interestList.values()).filter((item) => {
    return INTEREST_SCHEDULE[item.uid] || item.uid === extraItemUID;
  });
};

export const setUpAds = (
  adDataResult: IAdTag,
  adUnitId: string,
  size: IGeneralSize,
  parameterList: IAdParameter[],
  responsiveSizeList?: IAdMapData[]
) => {
  window.googletag = window.googletag || { cmd: [] };
  window.googletag.cmd.push(function () {
    const pubads = window.googletag.pubads();

    parameterList.forEach(({ parameter, parameterValue }) => {
      pubads.setTargeting(parameter, parameterValue);
    });

    pubads.enableSingleRequest();
    pubads.disableInitialLoad();
    window.googletag.enableServices();
  });

  if (!window.gptadslots[`ad_unit_${adUnitId}`]) {
    window.googletag.cmd.push(function () {
      const newSlot = window.googletag.defineSlot(
        adDataResult?.unitPath,
        size,
        `ad_unit_${adUnitId}`
      );

      if (responsiveSizeList?.length) {
        const preMapping = googletag.sizeMapping();

        responsiveSizeList.forEach(({ adSizeList, screenSize }) => {
          preMapping.addSize(screenSize, adSizeList);
        });

        const mapping = preMapping.build();
        if (newSlot && mapping) {
          newSlot.defineSizeMapping(mapping);
        }
      }

      window.gptadslots[`ad_unit_${adUnitId}`] = newSlot;
      const pubads = window.googletag.pubads();

      if (newSlot) {
        newSlot.setForceSafeFrame(true).addService(pubads);
      }
    });
  }

  refreshAdd(adUnitId);
};

export const getInterestName = (
  interestUID: string,
  interestMap: Map<string, IInterest>
) => {
  const nameRecord = interestMap.get(interestUID)?.name;
  if (nameRecord) {
    return getTranslation(nameRecord);
  }

  return '';
};

export const getPressCardData = (
  { title, previews, uid, interests }: IDailyLookBook,
  interestMap: Map<string, IInterest>
) => {
  return {
    title: title,
    uid,
    subtitle: getInterestName(interests[0], interestMap) || '',
    imageList: previews,
  };
};
