export const VIDEO_SELECTOR = 'video-js';

export const DEFAULT_VIDEO_CONFIG = {
  preload: 'auto',
  autoplay: true,
  muted: true,
  html5: {
    vhs: {
      overrideNative: true,
    },
  },
};

export const TRACK_DEFAULT_CONFIG = {
  kind: 'captions',
  mode: 'showing',
  default: true,
};

const WATERFALL = {
  videoScr: {
    src: 'https://media.fotoscapes.com/movies/waterfall/waterfall.m3u8',
    type: 'application/x-mpegURL',
  },
  captions: [
    {
      lang: 'en',
      src: 'https://media.fotoscapes.com/movies/waterfall/waterfall.en.vtt',
    },
  ],
};

const EARTH = {
  videoScr: {
    src: 'https://media.fotoscapes.com/movies/earth/earth.m3u8',
    type: 'application/x-mpegURL',
  },
  captions: [
    {
      lang: 'en',
      src: 'https://media.fotoscapes.com/movies/earth/earth.en.vtt',
    },
  ],
};

const FERRARI = {
  videoScr: {
    src: 'https://media.fotoscapes.com/movies/ferrari/ferrari.m3u8',
    type: 'application/x-mpegURL',
  },
  captions: [
    {
      lang: 'en',
      src: 'https://media.fotoscapes.com/movies/ferrari/ferrari.en.vtt',
    },
  ],
};

export const SOURCE_LIST = [WATERFALL, EARTH, FERRARI];

export const IMA_OPTIONS = {
  id: 'video-js',
  adTagUrl:
    'https://pubads.g.doubleclick.net/gampad/ads?iu=/180049092/ROS_LB_LOOKBK_MWEB_EN_VIDEO&description_url=http%3A%2F%2Ffotoscapes.com&tfcd=0&npa=0&sz=400x300&min_ad_duration=5000&max_ad_duration=15000&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=',
};
