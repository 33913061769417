import { useCallback, useEffect, useState } from 'react';

import { MOBILE_BREAK_POINT, TABLED_BREAK_POINT } from '../utils/constants';

export const useResize: (onPageResize?: (windowWidth: number) => void) => {
  isMobile: boolean;
  isTabled: boolean;
  isDesktop: boolean;
} = (onPageResize = () => {}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTabled, setIsTabled] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const onResize = useCallback(() => {
    if (onPageResize) {
      onPageResize(window.innerWidth);
    }
    setIsMobile(window.innerWidth <= MOBILE_BREAK_POINT);
    setIsTabled(
      window.innerWidth > MOBILE_BREAK_POINT &&
        window.innerWidth <= TABLED_BREAK_POINT
    );
    setIsDesktop(window.innerWidth > TABLED_BREAK_POINT);
  }, [onPageResize]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return {
    isMobile,
    isTabled,
    isDesktop,
  };
};
