import { FC } from 'react';

import { ILabelProps } from './interface';

import './index.scss';

const Label: FC<ILabelProps> = ({ children, text }) => {
  return (
    <label className="label" data-testid="label-el">
      {text ? <div className="label__text f-headline--xs">{text}</div> : null}
      {children}
    </label>
  );
};

export default Label;
