import metrics, { IFotoscapeApiMetric } from './metrics';

const PAGEVIEW_METRIC_LABELS: string[] = [
  'page_number',
  'language',
  'utm_campaign',
  'utm_source',
  'recirculation',
  'page_path',
  'primary_interest',
];

const convertAndFilterRecord = (
  record: Record<string, string | boolean | number>,
  returnKeys: string[]
): Record<string, string> => {
  const retObj: Record<string, string> = {};
  for (const key of returnKeys) {
    if (Object.hasOwn(record, key)) {
      if (returnKeys.includes(key)) {
        retObj[key] = record[key]?.toString();
      }
    } else {
      // Cannot add new labels once been reported, so add blank labels so will create
      retObj[key] = '';
    }
  }
  // want to create label for metric in case we want to use in the future, but too high cardinality for now
  retObj.primary_interest = '';
  return retObj;
};

export const logPageViewMetric = (
  labelsAndValues: Record<string, string | boolean | number>
) => {
  const eventData: Record<string, string> = convertAndFilterRecord(
    labelsAndValues,
    PAGEVIEW_METRIC_LABELS
  );
  metrics.counter('page_views', eventData);
};

const filterFotoscapeApiUrl = (url: string): string | null => {
  try {
    const [baseUrl, queryString] = url.split('?');
    const searchParams = new URLSearchParams(queryString);
    // const utm = searchParams.has('utm_source') ? 'utm_source=' + searchParams.get('utm_source') : null;
    const param = searchParams.has('param')
      ? 'param=' + searchParams.get('param')
      : null;
    const value = searchParams.has('value')
      ? 'value=' + searchParams.get('value')
      : null;
    const sched = searchParams.has('sched')
      ? 'sched=' + searchParams.get('sched')
      : null;
    const utm = param === 'param=utm_source' ? `${param}&${value}` : '';
    return `${baseUrl}${utm || sched ? '?' : ''}${
      utm ? `${utm}${sched ? '&' : ''}` : ''
    }${sched ? sched : ''}`;
  } catch (e: any) {
    console.error(`Invalid Fotoscape API URl for metrics: ${url}`, e);
    return null;
  }
};

export const logFotoscapeApiStatusMetric = (
  labelsAndValues: IFotoscapeApiMetric
) => {
  const filteredUrl = filterFotoscapeApiUrl(labelsAndValues.url);
  // console.debug(`Sending Fotoscape API status metric`, labelsAndValues);
  if (filteredUrl) {
    metrics.counter('http_response_code', {
      ...labelsAndValues,
      url: filteredUrl,
    });
  }
};

export const logFotoscapesApiResponseTimeMetric = (
  responseTime: number,
  labelsAndValues: IFotoscapeApiMetric
) => {
  const filteredUrl = filterFotoscapeApiUrl(labelsAndValues.url);
  // console.debug(
  //   `Sending Fotoscape API response time metric`,
  //   responseTime,
  //   labelsAndValues
  // );

  if (filteredUrl) {
    metrics.histogram('http_response_time_ms', responseTime, {
      url: filteredUrl,
    });
  }
};
