import { IGeneralSize } from '../../../../HomeRoot/interfaces';

export const EXTRA_AD_SIZE_LIST: IGeneralSize = [
  [320, 480],
  [336, 280],
  [300, 250],
];

export const EXTRA_AD_CONFIG = {
  unitPath: '/180049092/ROS_LB_FASTNEWS_MWEB_EN_INTERSTITIAL',
  pubwisePreScript:
    'https://fdyn.pubwise.io/script/9cc058ba-055e-4270-8109-566264e5265a/v3/dyn/pre_pws.js?type=default',
  pubwiseScript:
    'https://fdyn.pubwise.io/script/9cc058ba-055e-4270-8109-566264e5265a/v3/dyn/pws.js?type=default',
};

export const EXTRA_PARAMETER_TO_COPY = ['utm_source'];
