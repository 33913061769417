import { FC } from 'react';

import FotoscapeLink from '../FotoscapeLink/FotoscapeLink';
import { HOMEPAGE_ROUTE_PATH } from '../../pages/Root/router/constants.ts';

import './index.scss';

const NotFoundTemplate: FC = () => {
  return (
    <div className="not-found-template">
      <div className="not-found-template__content">
        <h1 className="not-found-template__title">404</h1>
        <h2 className="not-found-template__subtitle">Oops! Page Not Found</h2>
        <FotoscapeLink
          className="fotoscape-app__button fotoscape-app__button--l label--l"
          to={HOMEPAGE_ROUTE_PATH}
        >
          Back to Home
        </FotoscapeLink>
      </div>
    </div>
  );
};

export default NotFoundTemplate;
