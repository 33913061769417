import { API } from '../api';
import {
  IDailyLookBookResponce,
  IGetLookBookOption,
  ILookBookData,
  IRecircData,
} from './interfaces';
import { CONFIG_CKEY, RECIRC_URL } from '../constants';

export class LookBookApiService {
  constructor(private api: API) {}

  getLookBook(id: string, option?: IGetLookBookOption): Promise<ILookBookData> {
    let query = `?ckey=${CONFIG_CKEY}`;

    if (option?.language) {
      query += `&mp_lang=${option?.language}`;
    }

    return this.api.get(`/wp/v1/lookbook/${id}${query}`);
  }

  getRecirc(id: string): Promise<IRecircData> {
    return this.api.get(`${RECIRC_URL}?ckey=${CONFIG_CKEY}&lookbook=${id}`);
  }

  getDailyLookbookList(
    category: string,
    previewAspect?: string
  ): Promise<IDailyLookBookResponce> {
    let query = `?ckey=${CONFIG_CKEY}`;

    if (category && category !== 'default') {
      query += `&sched=${category}`;
    }

    if (previewAspect) {
      query += `&previewAspect=${previewAspect}`;
    }

    return this.api.get(`/wp/v1/daily${query}`);
  }
}
