import { API } from '../api';
import { IInterestResponse } from './interfaces';
import { CONFIG_CKEY } from '../constants';

export class InterestAPIService {
  constructor(private api: API) {}

  getInterestList(): Promise<IInterestResponse> {
    const qs = [`ckey=${CONFIG_CKEY}`, 'previewAspect=9:4'];
    const url = `wp/v1/interests?${qs.join('&')};`;

    return this.api.get(url);
  }
}
