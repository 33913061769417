import { IAdMapData } from '../../interfaces';

export const STORIES_DESKTOP_AD_MAP_DATA: IAdMapData = {
  screenSize: [1193, 0],
  adSizeList: [
    [970, 250],
    [970, 90],
    [728, 90],
  ],
};

export const STORIES_TABLED_AD_MAP_DATA: IAdMapData = {
  screenSize: [970, 0],
  adSizeList: [
    [728, 90],
    [300, 50],
    [320, 50],
  ],
};

export const STORIES_TABLED_SMALL_AD_MAP_DATA: IAdMapData = {
  screenSize: [684, 0],
  adSizeList: [
    [300, 50],
    [320, 50],
  ],
};

export const STORIES_MOBILE_AD_MAP_DATA: IAdMapData = {
  screenSize: [320, 0],
  adSizeList: [
    [300, 250],
    [300, 50],
    [320, 50],
  ],
};

export const STORIES_AD_SLOT = 'stories';
