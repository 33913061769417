import { FC, useMemo } from 'react';

import { ITitleSkeletonProps } from './interfaces';

import './index.scss';

const TitleSkeleton: FC<ITitleSkeletonProps> = ({ isSmall }) => {
  const classList = useMemo(() => {
    const list = ['title-skeleton'];

    if (isSmall) {
      list.push('title-skeleton--small');
    }

    return list.join(' ');
  }, [isSmall]);

  return <div className={classList}></div>;
};

export default TitleSkeleton;
