import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Image from '../../../../../../../../components/Image/Image';
import PressCard from '../../../../components/PressCard/PressCard';
import { getInterestName, getPressCardData, setUpAds } from '../../../../utils';
import { StoryCoverCardProps } from './interfaces';
import {
  STORY_AD_SLOT,
  STORY_DESKTOP_AD_MAP_DATA,
  STORY_MOBILE_AD_MAP_DATA,
  STORY_TABLED_AD_MAP_DATA,
  STORY_TABLED_SMALL_AD_MAP_DATA,
} from './constants';
import { getCoverLook } from '../../../../../Lookbook/utils';
import StoryLook from './components/StoryLook/StoryLook';
import { LOOKBOOK_PARAMETERS_TO_COPY } from '../../../../../Lookbook/contants';
import { useResize } from '../../../../../../hooks';
import { getTranslation } from '../../../../../../../../i18n';
import { IAdParameter } from '../../../../interfaces';
import StorySkeletonMayLikeList from '../StorySkeletonMayLikeList/StorySkeletonMayLikeList';
import { LOOKBOOK_ROUTE } from '../../../../../../router/router';
import { getWithOutSpecialChars } from '../../../../../../utils';
import TextParser from '../../../../../../components/TextParser/TextParser';

import './index.scss';

const StoryCoverCard: FC<StoryCoverCardProps> = ({
  mayLikeList,
  interestMap,
  lookBook,
  adTag,
}) => {
  const [searchParams] = useSearchParams();
  const imageLookWrapperRef = useRef<HTMLDivElement>(null);
  const { isDesktop, isTabled, isMobile } = useResize();
  const { t } = useTranslation();

  const lookBookData = useMemo(() => {
    const look = getCoverLook(lookBook);
    let summery = getTranslation(lookBook?.summary);
    const summeryList = summery?.split('\n');

    if (isTabled || isMobile) {
      const updatedList = [
        summeryList.shift(),
        `@ad(${STORY_AD_SLOT})`,
        ...summeryList,
      ];

      summery = updatedList.join('\n');
    }

    return {
      uid: lookBook.uid,
      title: getTranslation(lookBook?.title),
      summery: summery,
      imageURLs: look.thumbnails || [],
      credit: getTranslation(lookBook?.credit) || '',
      interestName: getInterestName(lookBook.interests[0].uid, interestMap),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lookBook,
    imageLookWrapperRef,
    imageLookWrapperRef.current,
    interestMap,
    isTabled,
    isMobile,
  ]);

  const lookList = useMemo(() => {
    if (lookBook) {
      return lookBook.looks.filter(({ uid }) => {
        return uid !== lookBook.coverUID;
      });
    }

    return [];
  }, [lookBook]);

  const summeryParagraphClass = useMemo(() => {
    return isMobile ? 'paragraph--s' : 'paragraph--m';
  }, [isMobile]);

  const renderAd = useCallback(() => {
    const size = [
      ...STORY_DESKTOP_AD_MAP_DATA.adSizeList,
      ...STORY_TABLED_AD_MAP_DATA.adSizeList,
      ...STORY_TABLED_SMALL_AD_MAP_DATA.adSizeList,
      ...STORY_MOBILE_AD_MAP_DATA.adSizeList,
    ];

    const responsiveSize = [
      STORY_DESKTOP_AD_MAP_DATA,
      STORY_TABLED_AD_MAP_DATA,
      STORY_TABLED_SMALL_AD_MAP_DATA,
      STORY_MOBILE_AD_MAP_DATA,
    ];

    const parameterList: IAdParameter[] = [];

    for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
      const parameterValue = searchParams.get(parameter);
      if (parameterValue) {
        parameterList.push({ parameter, parameterValue });
      }
    }
    parameterList.push({
      parameter: 'pathname',
      parameterValue: 'stories',
    });

    if (adTag) {
      setUpAds(adTag, STORY_AD_SLOT, size, parameterList, responsiveSize);
    }
  }, [searchParams, adTag]);

  useEffect(() => {
    if (adTag && lookBookData) {
      renderAd();
    }
  }, [renderAd, adTag, lookBookData]);

  return (
    <div className="story-cover-card">
      <div
        className="story-cover-card__image-wrapper"
        ref={imageLookWrapperRef}
      >
        <Image
          className="story-cover-card__image"
          imageList={lookBookData.imageURLs}
          alt="lookbook"
        />
      </div>
      <div className="story-cover-card__main">
        <div className="story-cover-card__content">
          <div className="content__text">
            <div className="content__interest label--m">
              {lookBookData.interestName}
            </div>
            <h1 className="content__title">{lookBookData.title}</h1>
            {lookBookData.credit ? (
              <div className="content__credit paragraph--xs">
                <TextParser
                  text={lookBookData.credit}
                  option={{ paragraphClass: 'paragraph--xs' }}
                />
              </div>
            ) : null}
            <div className="content__summery">
              {lookBookData.summery ? (
                <TextParser
                  text={lookBookData.summery}
                  option={{ paragraphClass: summeryParagraphClass }}
                />
              ) : null}
            </div>
          </div>

          <div className="content__look-list">
            <div className="look-list">
              {lookList.map(({ thumbnails, uid }) => {
                const view = lookBook.plan.views.find((item) => {
                  return item.panes.some((item) => {
                    return item.lookUid === uid;
                  });
                });

                const textDate = view?.panes.find((item) => {
                  return item.translations;
                })?.translations;

                const text = textDate ? getTranslation(textDate) : '';
                const titleStart = text?.indexOf('##');
                const titleEnd = text?.indexOf('\n', titleStart);
                const sectionTitle =
                  titleStart >= 0
                    ? text?.slice(
                        titleStart,
                        titleEnd >= 0 ? titleEnd : text.length
                      )
                    : '';
                const description = text?.replace(sectionTitle, '') || '';

                return (
                  <StoryLook
                    key={uid}
                    title={sectionTitle}
                    description={description}
                    imageList={thumbnails}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="story-cover-card__recomendation">
          {isDesktop ? (
            <div className="story-cover-card__ad-wrapper">
              <div id={`ad_unit_${STORY_AD_SLOT}`}></div>
            </div>
          ) : null}

          <section className="recommendation__like-list">
            <div className="like-list">
              <h3 className="like-list__title">{t('youMayLike')}</h3>

              {mayLikeList.length ? (
                mayLikeList.slice(0, 6).map((item) => {
                  const { imageList, title, subtitle, uid } = getPressCardData(
                    item,
                    interestMap
                  );

                  const link = LOOKBOOK_ROUTE.path
                    .replace(':id', uid)
                    .replace(':title?', getWithOutSpecialChars(title?.en));

                  return (
                    <PressCard
                      key={uid}
                      link={link}
                      title={getTranslation(title)}
                      subtitle={subtitle}
                      imageList={imageList}
                    />
                  );
                })
              ) : (
                <StorySkeletonMayLikeList />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StoryCoverCard;
