import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RecircCard from './components/RecircCard/RecircCard';
import { IRecircProps } from './interfaces';
import { useResize } from '../../../../hooks';
import { getTranslation } from '../../../../../../i18n';

import './index.scss';

const Recirc: FC<IRecircProps> = ({
  recircList,
  interestMap,
  onRecircSelected,
}) => {
  const { isDesktop, isTabled } = useResize();
  const { t } = useTranslation();

  const recircAvalibleList = useMemo(() => {
    let rest = recircList.length;

    if (isDesktop) {
      rest = -recircList.length % 3 || recircList.length;
    }

    if (isTabled) {
      rest = -recircList.length % 2 || recircList.length;
    }

    return recircList.slice(0, rest);
  }, [recircList, isDesktop, isTabled]);

  const getInterestName = useCallback(
    (interestUID: string) => {
      const name = interestMap.get(interestUID)?.name;
      return name ? getTranslation(name) : '';
    },
    [interestMap]
  );

  return (
    <div className="recirc">
      <h1 className="recirc__title headline--l">{t('recommendedForYou')}</h1>

      <div className="recirc__list">
        {recircAvalibleList.map((lookbook) => {
          const { brandLogo, interests, title, uid, previews } = lookbook;

          return (
            <RecircCard
              key={uid}
              title={getTranslation(title)}
              imgList={previews}
              logo={brandLogo}
              interestName={getInterestName(interests[0]) || ''}
              onClick={() => {
                return onRecircSelected(lookbook);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Recirc;
