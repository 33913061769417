import { FC } from 'react';

import { ICloseButtonProps } from './interfaces';
import Icon from '../../../../../components/Icon/Icon.tsx';
import {
  IconSize,
  IconTypes,
} from '../../../../../components/Icon/constants.ts';

import './index.scss';

const CloseButton: FC<ICloseButtonProps> = ({ onClick }) => {
  return (
    <button
      data-testid="closeButton"
      className="close-button"
      onClick={onClick}
    >
      <Icon type={IconTypes.close} size={IconSize.s} />
    </button>
  );
};

export default CloseButton;
