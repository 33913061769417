import {
  CATEGORY_ROUTE_PATH,
  GAMES_ROUTE_PATH,
  INTEREST_ROUTE_PATH,
  INTEREST_URL,
  STORIES_ROUTE_PATH,
} from '../../pages/Root/router/constants';

export const FIRST_SECTION_LINK_LIST = [
  {
    text: 'home',
    link: '/',
  },
  {
    text: 'galleries',
    link: INTEREST_ROUTE_PATH,
  },
  {
    text: 'stories',
    link: STORIES_ROUTE_PATH,
  },
  {
    text: 'games',
    link: GAMES_ROUTE_PATH,
  },
];

export const SECOND_SECTION_LINK_LIST = [
  {
    text: 'celebrityHomes',
    link: CATEGORY_ROUTE_PATH.replace(
      ':interestTitle',
      INTEREST_URL['26fgYum']
    ),
  },
  {
    text: 'fashion&Sneakers',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', INTEREST_URL.OnfyOho),
  },
  {
    text: 'travelDestinations',
    link: CATEGORY_ROUTE_PATH.replace(':interestTitle', INTEREST_URL.NZf7cr),
  },
];
