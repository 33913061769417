import { AxiosRequestConfig } from 'axios';

import { API } from '../api';
import { CONFIG_CKEY } from '../constants';
import { ITalkbackResponse } from './interface';
import talkbackApiService from './index';
import { SET_TALKBACK_TIMEOUT } from './constants';

export class TalkbackApiService {
  constructor(private api: API) {}

  getTalkback(config?: AxiosRequestConfig): Promise<ITalkbackResponse> {
    const qs = [`ckey=${CONFIG_CKEY}`];
    const url = `wp/v1/talkback?${qs.join('&')}`;

    return this.api.get(url, config);
  }

  setTalkback(data: Record<string, any>, bearerToken: string): Promise<null> {
    const qs = [`ckey=${CONFIG_CKEY}`];
    const url = `wp/v1/talkback?${qs.join('&')}`;

    return this.api.put(url, JSON.stringify(data), {
      headers: {
        Authorization: `bearer ${bearerToken}`,
      },
    });
  }

  async updateTalkback(data: Record<string, any>): Promise<null> {
    try {
      const { bearer } = await this.getTalkback();

      setTimeout(async () => {
        try {
          await talkbackApiService.setTalkback(data, bearer);
        } catch (e: any) {
          console.log(e);
        }
      }, SET_TALKBACK_TIMEOUT);

      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
