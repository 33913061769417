import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Routes, useLocation } from 'react-router-dom';

import Header from '../../components/Header/Header';
import { getRouter } from '../../utils/router';
import { isRouterAnimation, rootRoutes } from './router/router';
import { useFeature } from './ContextWrapper/WithFeatureConfig/hooks';
import Dialog from './components/Dialog';
import Download from './components/Download';
import localstorage from './services/storage/localstorage';
import {
  IS_APP_DOWNLOADED,
  STORE_LAST_MONTH_USER_VISITS,
} from '../../utils/constants';
import { ONE_DAY_TIME } from './pages/Lookbook/contants';

import './index.scss';

const Root: FC = () => {
  const enum transitionState {
    in = 'in',
    out = 'out',
  }
  const location = useLocation();
  const { isLookBookPlayerHeaderAvailable, isDownloadAvailable } = useFeature();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState(transitionState.in);
  const [transitionClass, setTransitionClass] = useState('');
  const [isShowDownloadDialog, setIsShowDownloadDialog] = useState(false);

  const handleAnimationEnd = (event: SyntheticEvent) => {
    if (
      transitionStage === transitionState.out &&
      isRouterAnimation((event as any).animationName)
    ) {
      if (location.state?.animation?.in) {
        setTransitionClass(location.state.animation.in);
        window.history.replaceState(null, '');
      }

      setTransitionStage(transitionState.in);
      setDisplayLocation(location);
    }

    if (
      transitionStage === transitionState.out &&
      isRouterAnimation((event as any).animationName)
    ) {
      setTransitionClass('');
    }
  };

  const closeDownloadDialog = useCallback(() => {
    setIsShowDownloadDialog(false);
  }, []);

  const onSuccess = useCallback(() => {
    localstorage?.set(IS_APP_DOWNLOADED, true);
  }, []);

  useEffect(() => {
    if (location !== displayLocation) {
      window.scrollTo({ top: 0 });
      setTransitionClass('');

      if (location.state?.animation?.out) {
        setTransitionClass(location.state?.animation.out);
        setTransitionStage(transitionState.out);
        return;
      }

      setDisplayLocation(location);
    }

    setTransitionStage(transitionState.out);
  }, [location, displayLocation, transitionState.out]);

  useEffect(() => {
    if (isDownloadAvailable) {
      const visitList = localstorage?.get(STORE_LAST_MONTH_USER_VISITS) || [];
      const isAppDownloaded = localstorage?.get(IS_APP_DOWNLOADED);

      // SHOW THE BLOCK IF USER VISITED MORE THAN THREE TIMES FOR THE WEEK
      if (visitList.length >= 3 && !isAppDownloaded) {
        const diff =
          new Date().getTime() -
          new Date(visitList[visitList.length - 3]).getTime();
        setIsShowDownloadDialog(diff <= ONE_DAY_TIME * 7);
      }
    }
  }, [isDownloadAvailable]);

  return (
    <div
      id="jsx-root"
      className={`${transitionClass}`}
      onAnimationEnd={handleAnimationEnd}
    >
      {isLookBookPlayerHeaderAvailable ? <Header /> : null}

      <Dialog isOpen={isShowDownloadDialog} onClose={closeDownloadDialog}>
        <Download onClose={closeDownloadDialog} onSuccess={onSuccess} />
      </Dialog>

      <Routes location={displayLocation}>
        {rootRoutes.map((item) => {
          return getRouter(item);
        })}
      </Routes>
    </div>
  );
};

export default Root;
