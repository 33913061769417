import { CSSProperties, FC, useMemo, useRef, useState } from 'react';

import Image from '../../../../components/Image/Image';
import { IInterestCardProps } from './interfaces';
import { getBackgroundImageList } from '../../utils';
import { getTranslation } from '../../../../i18n';
import FotoscapeLink from '../../../../components/FotoscapeLink/FotoscapeLink';
import { CATEGORY_ROUTE } from '../../router/router';
import { INTEREST_URL, ROUTER_ANIMATION_TYPE } from '../../router/constants';

import './index.scss';

const InterestCard: FC<IInterestCardProps> = ({
  interest,
  className,
  styles,
}) => {
  const [isCardSelected, setIsCardSelected] = useState(false);
  const containerRef = useRef<any>(null);
  const [styleList, setStyleList] = useState({});

  const interestClassList = useMemo(() => {
    const classList = ['interest-card'];

    if (className) {
      classList.push(className);
    }

    if (isCardSelected) {
      classList.push('interest-card--full-screen');
    }

    return classList.join(' ');
  }, [isCardSelected, className]);

  const interestStyleList = useMemo<CSSProperties>(() => {
    let styleList = {};

    if (styles) {
      styleList = { ...styles };
    }

    return styleList as CSSProperties;
  }, [styles]);

  const interestContainerClassList = useMemo(() => {
    const classList = ['interest-card__container'];

    if (interest.uid) {
      classList.push(
        `interest-background interest-background--${interest.uid} interest-background--with-img`
      );
    }

    return classList.join(' ');
  }, [interest]);

  const onInterestClick = () => {
    setIsCardSelected(true);

    if (containerRef.current) {
      const data = containerRef.current.getBoundingClientRect();
      const { left, y, height, width } = data;

      setStyleList({
        '--left': `${left}px`,
        '--top': `${y}px`,
        '--height': `${height}px`,
        '--width': `${width}px`,
      });
    }
  };

  return (
    <FotoscapeLink
      id={interest.uid}
      ref={containerRef}
      className={interestClassList}
      style={interestStyleList}
      onClick={onInterestClick}
      to={{
        pathname: CATEGORY_ROUTE.path.replace(
          ':interestTitle',
          INTEREST_URL[interest.uid]
        ),
      }}
      state={{
        animation: {
          out: ROUTER_ANIMATION_TYPE.routerAnimationFullScreen,
          in: ROUTER_ANIMATION_TYPE.routerAnimationInterestIn,
        },
      }}
    >
      <div className={interestContainerClassList} style={styleList}>
        <Image
          className="interest-card__img"
          imageList={getBackgroundImageList(interest)}
          alt="interest"
        />
        <div className="interest-background__item"></div>
        <h3 className="interest-card__title headline--s">
          {getTranslation(interest?.name)}
        </h3>
      </div>
    </FotoscapeLink>
  );
};

export default InterestCard;
