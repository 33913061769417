import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IInterestGalleryProps } from './interfaces';
import InterestCard from '../InterestCard/InterestCard';
import { useResize } from '../../hooks';
import {
  ACTIVE_ITEM_HEIGHT,
  ITEM_HEIGHT,
  ITEM_SPACE_BOTTOM,
} from './constants';
import Lazy from '../Lazy/Lazy.tsx';

import './index.scss';

const InterestGallery: FC<IInterestGalleryProps> = ({
  interestList,
  renderHeader,
  offsetTop = 60,
}) => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useResize();

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [galleryTopPosition, setGallaryTopPosition] = useState(0);

  const titleStyle = useMemo(() => {
    if (!isMobile) {
      return {};
    }

    if (activeSlideIndex > 0) {
      return {
        opacity: 0,
      };
    }

    const opacity = (ITEM_HEIGHT - galleryTopPosition * 3) / 100;
    let scale = (ITEM_HEIGHT - galleryTopPosition * 2) / 100;

    if (scale >= 1) {
      scale = 1;
    } else if (scale <= 0) {
      scale = 0;
    }

    return {
      opacity: opacity,
      transform: `scale(${scale})`,
    } as CSSProperties;
  }, [galleryTopPosition, activeSlideIndex, isMobile]);

  const getItemClassList = (index: number) => {
    if (isMobile && index === activeSlideIndex) {
      return 'lazy--active';
    }

    return '';
  };

  const getItemStyleList = (index: number) => {
    if (!isMobile) {
      return {};
    }

    let height = ITEM_HEIGHT;

    if (index === activeSlideIndex) {
      height = ACTIVE_ITEM_HEIGHT;
    }

    return {
      height,
    } as CSSProperties;
  };

  const onScroll = useCallback(
    (e: any) => {
      setGallaryTopPosition(e.currentTarget.scrollY);
      const galleryTop = galleryRef.current?.getBoundingClientRect().top || 0;

      const index =
        galleryTop < 0
          ? Math.floor(
              (Math.abs(galleryTop) + offsetTop) /
                (ITEM_HEIGHT + ITEM_SPACE_BOTTOM)
            )
          : 0;

      setActiveSlideIndex(index);
    },
    [offsetTop]
  );

  useEffect(() => {
    if (interestList.length && isMobile) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [interestList, isMobile, onScroll]);

  return (
    <div className="interest-gallery" ref={galleryRef}>
      {renderHeader ? (
        <div className="interest-gallery__header" style={titleStyle}>
          {renderHeader()}
        </div>
      ) : null}

      <div className="interest-gallery__interest-list">
        {interestList.map((item, index) => {
          return (
            <Lazy
              key={item.uid}
              styleList={getItemStyleList(index)}
              className={getItemClassList(index)}
            >
              <InterestCard
                key={item.uid}
                styles={getItemStyleList(index)}
                interest={item}
              />
            </Lazy>
          );
        })}
      </div>
    </div>
  );
};

export default InterestGallery;
