import TagManager, { TagManagerArgs } from 'react-gtm-module';

import localStorage from '../pages/Root/services/storage/localstorage';
import { LOOKBOOK_PARAMETERS_TO_COPY } from '../pages/Root/pages/Lookbook/contants';
import { STORE_LAST_MONTH_USER_VISITS } from './constants';
import { eventTypes } from './constants';

export interface IEventData extends Record<string, any> {
  event: string;
}

class GTM {
  private tagManagerArgs: TagManagerArgs = {
    gtmId: 'GTM-P2VLN9R',
  };

  init() {
    TagManager.initialize(this.tagManagerArgs);
  }

  emit(eventData: IEventData) {
    window.dataLayer?.push({
      userVisits: localStorage?.get(STORE_LAST_MONTH_USER_VISITS)?.length || 1,
      ...eventData,
    });
  }

  stop() {
    window.dataLayer = [];
  }
}

const gtm = new GTM();

export default gtm;

export const setDNSMI = (value: boolean) => {
  localStorage?.set('local_mp_dnsmi', value);

  if (value) {
    gtm.stop();
  } else {
    gtm.init();
  }
};

export const getDNSMI = () => {
  return localStorage?.get('local_mp_dnsmi');
};

export const getRequiredLookEventParams = (searchParams: URLSearchParams) => {
  const eventData: Record<string, string> = {};
  for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
    const parameterValue = searchParams.get(parameter);
    if (parameterValue) {
      eventData[parameter] = parameterValue;
    }
  }

  return eventData;
};

export const emitPageViewEvent = (
  eventData: Record<string, string | boolean>,
  searchParams: URLSearchParams
) => {
  gtm.emit({
    event: eventTypes.dtPageview,
    source: window.location.origin,
    ...eventData,
    ...getRequiredLookEventParams(searchParams),
  });
};
