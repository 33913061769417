import {
  NavigateOptions,
  To,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useCallback } from 'react';

import { getQuery } from '../utils/navigation';

export const useFotoscapeNavigate = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  return useCallback(
    (to: To, option?: NavigateOptions) => {
      if (typeof to === 'string') {
        const origin = to.split('?')[0];
        const searchString = to.split('?')[1];

        navigate(
          { pathname: origin, search: getQuery(searchString, search) },
          option
        );
        return;
      }

      if (typeof to === 'object') {
        navigate(
          { ...to, search: getQuery(to.search as string, search) },
          option
        );
        return;
      }

      navigate(to, option);
    },
    [navigate, search]
  );
};
