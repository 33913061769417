import { FC } from 'react';

import InterestCardSkeleton from '../InterestCardSkeleton/InterestCardSkeleton';

import './index.scss';

const InterestCardListSkeleton: FC = () => {
  return (
    <div className="interest-card-list-skeleton">
      <InterestCardSkeleton />
      <InterestCardSkeleton />
      <InterestCardSkeleton />
      <InterestCardSkeleton />
      <InterestCardSkeleton />
      <InterestCardSkeleton />
      <InterestCardSkeleton />
      <InterestCardSkeleton />
      <InterestCardSkeleton />
    </div>
  );
};

export default InterestCardListSkeleton;
