import React from "react";
import "./StoryCarousel.scss";
import Image from '../../containers/BestImageContainer/BestImageContainer.tsx';
import { useViewportWidth } from '../../hooks/useViewportWidth.ts';
import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';

interface StoryCarouselProps {
  imageList: IImageData[];
  title: string;
  description: string;
}

const StoryCarousel: React.FC<StoryCarouselProps> = ({ imageList, title, description }) => {

  return (
    <div className="story-carousel">
      <div className="story-content">
        <Image
          targetWidth={'99%'}
          targetHeight={240}
          alt={title}
          sizeHint={"large"}
          className="story-image-cr"
          staticSized={true}
          images={imageList}
        />
        <div className="story-text">
          <h2 className="story-title">{title}</h2>
          <p className="story-description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default StoryCarousel;
